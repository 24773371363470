import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./sortingModal.module.scss";
import iconButtonCLose from "../../assets/images/x.png";
import checkIcon from "../../assets/images/check.png";

interface ISortingModal {
  onSelect: (option: string) => void;
  onClose: () => void;
  sortingOptions: string[];
  selectedSortingOption: string | null;
}

const SortingModal: FC<ISortingModal> = ({
  onSelect,
  onClose,
  sortingOptions,
  selectedSortingOption,
}) => {
  const { t, i18n } = useTranslation();
  const handleOptionClick = (option: string, event: React.MouseEvent) => {
    event.stopPropagation();
    onSelect(option);
  };
  const languageWidths = {
    ru: 330, // для русского языка
    en: 285, // для английского языка
  };

  const contentWidth =
    languageWidths[i18n.language as "ru" | "en"] || languageWidths.en;

  const modalRef = useRef<HTMLDivElement | null>(null);
  const isMobile = window.innerWidth <= 1020;
  const handleClickOutside = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      isMobile
    ) {
      onClose();
    }
  };

  return (
    <div className={styles.modal} onClick={handleClickOutside}>
      <div
        className={styles.content}
        style={{ minWidth: contentWidth }}
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.mobile}>
          <div className={styles.top}>
            <p className={styles.textTop}>{t("SortingModal.sortBy")}</p>
            <img
              src={iconButtonCLose}
              alt="icon button close"
              className={styles.buttonClose}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        {sortingOptions.map((option, index) => (
          <button
            key={index}
            className={styles.option}
            onClick={(e) => handleOptionClick(option, e)}
          >
            <p className={styles.textOption}>{t(`SortingModal.${option}`)}</p>
            {option === selectedSortingOption ? (
              <img
                src={checkIcon}
                alt="check icon"
                className={styles.checkIcon}
              />
            ) : (
              ""
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SortingModal;
