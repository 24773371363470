import { useEffect } from "react";

const checkIsIOS = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector("meta[name=viewport]");
  if (!el) return;

  let content = el.getAttribute("content");
  if (!content) return;

  const re = /maximum-scale=[0-9.]+/g;

  if (re.test(content)) {
    content = content.replace(re, "maximum-scale=1.0");
  } else {
    content = `${content}, maximum-scale=1.0`;
  }

  el.setAttribute("content", content);
};

const restoreViewportMeta = () => {
  const el = document.querySelector("meta[name=viewport]");
  if (!el) return;

  let content = el.getAttribute("content") || "";
  content = content.replace(/maximum-scale=[0-9.]+/g, "").trim();
  el.setAttribute("content", content);
};

export const useIOSViewportFix = () => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 1020;

    if (checkIsIOS() && isMobile) {
      addMaximumScaleToMetaViewport();
    }

    return () => {
      if (checkIsIOS() && isMobile) {
        restoreViewportMeta();
      }
    };
  }, []);
};
