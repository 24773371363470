import React from "react";
import LoginModal from "../loginPage/loginModal";
import RegistrationModal from "../registrationModal/registrationModal";
import FilterModal from "../filterModal/filterModal";
import BurgerMenu from "../burgerMenu/burgerMenu";
import SearchFilterMobileModal from "../searchFilterMobileModal/searchFilterMobileModal";
import { ModalNames } from "../../utils/const";

const ModalManager: React.FC<{
  openModalName: string | null;
  openModal: (modalName: ModalNames) => void;
  closeModal: () => void;
  props: any;
}> = ({ openModalName, openModal, closeModal, props }) => {
  switch (openModalName) {
    case ModalNames.Filter:
      return (
        <FilterModal
          isOpen={openModalName === ModalNames.Filter}
          onClose={closeModal}
          setShouldSearch={props.setShouldSearch}
        />
      );

    case ModalNames.Login:
      return (
        <LoginModal
          isOpen={openModalName === ModalNames.Login}
          onClose={closeModal}
          onSwitchToRegister={() => {
            closeModal();
            openModal(ModalNames.Register);
          }}
        />
      );

    case ModalNames.Register:
      return (
        <RegistrationModal
          isOpen={openModalName === ModalNames.Register}
          onClose={closeModal}
          onSwitchToLogin={() => {
            closeModal();
            openModal(ModalNames.Login);
          }}
        />
      );

    case ModalNames.BurgerMenu:
      return <BurgerMenu onClose={closeModal} />;

    case ModalNames.FilterMobile:
      return (
        <SearchFilterMobileModal
          onClose={closeModal}
          location={props.location}
          onLocationChange={props.onLocationChange}
          onSelectSuggestion={props.onSelectSuggestion}
          locationSuggestions={props.locationSuggestions}
          availableDates={props.availableDates}
          allowAllDates={props.allowAllDates}
          isDisableSearchButton={props.isDisableSearchButton}
          handleSearch={props.handleSearch}
        />
      );

    default:
      return null;
  }
};

export default ModalManager;
