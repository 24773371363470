import { Dispatch, FC, SetStateAction } from "react";
import styles from "./bookingSidebar.module.scss";
import { getImageUrl } from "../../utils/helpers";
import { Booking } from "../../types/booking";
import logoIcon from "../../assets/images/logo/logo-grey.png";
import { useTranslation } from "react-i18next";
import { CURRENCYSYMBOL } from "../../utils/const";
import ratingIcon from "../../assets/images/star.png";

interface IBookingSidebar {
  booking: Booking;
  discount: number;
  setIsGalleryOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  nights: number;
  pricePerNight: number;
}

const BookingSidebar: FC<IBookingSidebar> = ({
  booking,
  discount,
  setIsGalleryOpen,
  isDesktop,
  nights,
  pricePerNight,
}) => {
  const { t } = useTranslation();
  const widthImage = 140;
  const heightIamge = 140;

  return (
    <div className={styles.rightContent}>
      <div className={styles.topRightContent}>
        {booking.objectPhotos[0]?.storageUrl ? (
          <img
            className={styles.imageBooking}
            src={getImageUrl(
              booking.objectPhotos[0].storageUrl,
              widthImage,
              heightIamge
            )}
            alt="booking"
            onClick={() => setIsGalleryOpen(true)}
          />
        ) : (
          <div className={styles.noImage}>
            <img src={logoIcon} alt="logo" className={styles.iconLogo} />
            <p className={styles.textNoPhoto}>{t("card.noPhotos")}</p>
          </div>
        )}
        <div className={styles.bookingBlock}>
          <p className={styles.textEntire}>
            {t("bookingObjectPage.textEntire")}
          </p>
          <p className={styles.nameApartament}>{booking.name}</p>
          <div className={styles.ratingBlock}>
            <img
              className={styles.ratingIcon}
              src={ratingIcon}
              alt="rating icon"
            />
            <p className={styles.ratingNumberText}>4.5</p>
            <p className={styles.reviewsText}>
              {t("bookingObjectPage.reviews", { count: 2 })}
            </p>
          </div>
        </div>
      </div>
      {isDesktop && (
        <>
          <p className={styles.priceDetails}>
            {t("bookingObjectPage.priceDetails")}
          </p>
          <div className={styles.blockSpace}>
            <p className={styles.textLeftNights}>
              {`${CURRENCYSYMBOL}${pricePerNight} x `}
              {t("bookingObjectPage.nights", { count: nights })}
            </p>
            <p className={styles.textRightNights}>
              {CURRENCYSYMBOL}
              {booking.rentalCost ? booking.rentalCost : ""}
            </p>
          </div>
          <div className={styles.blockSpace}>
            <p className={styles.textLeftNights}>
              {t("bookingObjectPage.longStayDiscount")}
            </p>
            <p className={styles.textRightNights}>
              -{CURRENCYSYMBOL}
              {discount}
            </p>
          </div>
          <div className={styles.totalBlock}>
            <p className={styles.totalText}>
              {t("bookingObjectPage.totalText")}
            </p>
            <p className={styles.priceTotalText}>
              {CURRENCYSYMBOL}
              {booking.rentalCost ? booking.rentalCost - discount : ""}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
export default BookingSidebar;
