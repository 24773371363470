import React from "react";
import styles from "./shareButtons.module.scss";

import vkIcon from "../../assets/images/social/vk.png";
import fbIcon from "../../assets/images/social/facebook.png";
import twitterIcon from "../../assets/images/social/twitter.png";
import telegramIcon from "../../assets/images/social/telegram.png";
import pinterestIcon from "../../assets/images/social/pinterest.png";
import { useTranslation } from "react-i18next";

const ShareButtons: React.FC = () => {
  const shareUrl = encodeURIComponent(window.location.href);
  const { t } = useTranslation();

  return (
    <div className={styles.shareBlock}>
      <span className={styles.label}>{t("ShareButtons.share")}</span>
      <div className={styles.icons}>
        <a
          href={`https://vk.com/share.php?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={vkIcon} alt="VK" />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={fbIcon} alt="Facebook" />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterIcon} alt="Twitter" />
        </a>
        <a
          href={`https://t.me/share/url?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={telegramIcon} alt="Telegram" />
        </a>
        <a
          href={`https://pinterest.com/pin/create/button/?url=${shareUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pinterestIcon} alt="Pinterest" />
        </a>
      </div>
    </div>
  );
};

export default ShareButtons;
