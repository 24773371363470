import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ALLOWEDTYPES } from "../utils/const";

export interface QueryState {
  guestCount: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  radius: number;
  lat: number | null;
  lon: number | null;
  checkIn: string | undefined;
  checkOut: string | undefined;
  filters: {
    minPrice: number;
    maxPrice: number;
    selectedType: string[]; // массив допустимых типов
    rooms: number;
    beds: number;
    bathrooms: number;
  };
  rentalCost: string | undefined;
  rating: string | undefined;
}

const initialState: QueryState = {
  guestCount: {
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  },
  radius: 5,
  lat: null,
  lon: null,
  checkIn: undefined,
  checkOut: undefined,
  filters: {
    minPrice: 1,
    maxPrice: 35000,
    selectedType: ["hotel"],
    rooms: 1,
    beds: 1,
    bathrooms: 1,
  },
  rentalCost: undefined,
  rating: undefined,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    setQueryParams: (state, action: PayloadAction<QueryState>) => {
      return { ...state, ...action.payload };
    },
    updateGuestCount: (
      state,
      action: PayloadAction<{
        type: "adults" | "children" | "infants" | "pets";
        value: number;
      }>
    ) => {
      state.guestCount[action.payload.type] = action.payload.value;
    },
    updateRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload;
    },
    updateRentalCost: (state, action: PayloadAction<string | undefined>) => {
      state.rentalCost = action.payload;
    },
    updateRating: (state, action: PayloadAction<string | undefined>) => {
      state.rating = action.payload;
    },
    updateCoordinates: (
      state,
      action: PayloadAction<{ lat: number | null; lon: number | null }>
    ) => {
      state.lat = action.payload.lat;
      state.lon = action.payload.lon;
    },
    updateDates: (
      state,
      action: PayloadAction<{
        checkIn: string | undefined;
        checkOut: string | undefined;
      }>
    ) => {
      state.checkIn = action.payload.checkIn;
      state.checkOut = action.payload.checkOut;
    },
    updateFilters: (
      state,
      action: PayloadAction<{
        minPrice?: number;
        maxPrice?: number;
        selectedType?: string[];
        rooms?: number;
        beds?: number;
        bathrooms?: number;
      }>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
        // Валидация типов (selectedType)
        selectedType: action.payload.selectedType
          ? action.payload.selectedType.filter((type) =>
              ALLOWEDTYPES.includes(type)
            )
          : state.filters.selectedType,
        // Валидация комнат, кроватей и ванных
        rooms:
          action.payload.rooms && action.payload.rooms >= 1
            ? action.payload.rooms
            : 1,
        beds:
          action.payload.beds && action.payload.beds >= 1
            ? action.payload.beds
            : 1,
        bathrooms:
          action.payload.bathrooms && action.payload.bathrooms >= 1
            ? action.payload.bathrooms
            : 1,
      };
    },
  },
});

export const {
  setQueryParams,
  updateGuestCount,
  updateRadius,
  updateCoordinates,
  updateDates,
  updateFilters,
  updateRentalCost,
  updateRating,
} = querySlice.actions;
export default querySlice.reducer;
