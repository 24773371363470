import React, { useState } from "react";
import styles from "./cardForMap.module.scss";
import { useTranslation } from "react-i18next";
import { Booking } from "../../types/booking";
import heartIcon from "../../assets/images/icon-for-search/heart.png";
import starIcon from "../../assets/images/icon-for-search/star.png";
import { useNavigate } from "react-router-dom";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import buttonCloseIcon from "../../assets/images/close_54.png";
import {
  calculateNights,
  formatPrice,
  getImageUrl,
  parseDate,
} from "../../utils/helpers";
import { CURRENCYSYMBOL } from "../../utils/const";
import { useQueryParams } from "../../hooks/useQueryParams";
import logoIcon from "../../assets/images/logo/logo-grey.png";

interface CardProps {
  booking: Booking;
  onClose: () => void;
}

const CardForMap: React.FC<CardProps> = ({ booking, onClose }) => {
  const isMobile = window.innerWidth <= 768;
  const getUrlWithParams = usePreserveQueryParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const photos = booking.objectPhotos ? booking.objectPhotos.slice(0, 6) : [];
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const { queryParams } = useQueryParams();
  const checkIn = parseDate(queryParams["book[from]"]);
  const checkOut = parseDate(queryParams["book[to]"]);
  const nights =
    checkIn && checkOut ? calculateNights(checkIn, checkOut) : undefined;

  const handlePhotoChange = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentPhotoIndex(index);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Если клик произошел на индикаторе фото, прерываем переход
    if ((e.target as HTMLElement).classList.contains(styles.photoIndicator)) {
      return;
    }

    // Переход на страницу с деталями бронирования
    navigate(getUrlWithParams(`/booking/${booking.id}`));
  };
  const widthImage = 310;
  const heightImage = 213;

  return (
    <div className={styles.card} onClick={handleCardClick}>
      <div className={styles.imageContainer}>
        {photos.length > 0 ? (
          <>
            <img
              src={getImageUrl(
                photos[currentPhotoIndex].storageUrl,
                widthImage,
                heightImage
              )}
              key={photos[currentPhotoIndex].storageUrl}
              alt={booking.name}
              className={styles.image}
            />
            <div className={styles.photoSlider}>
              {photos.map((_, index) => (
                <div
                  className={styles.photoIndicatorBlock}
                  onClick={(e) => handlePhotoChange(index, e)}
                >
                  <div
                    key={index}
                    className={`${styles.photoIndicator} ${
                      index === currentPhotoIndex ? styles.active : ""
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles.noImage}>
            <img src={logoIcon} alt="logo" className={styles.iconLogo} />
            <p className={styles.textNoPhoto}>{t("card.noPhotos")}</p>
          </div>
        )}
        <div className={styles.rating}>
          <img src={starIcon} alt="icon star" className={styles.starIcon} />
          <span>4.5</span>
        </div>
        {!isMobile ? (
          <button className={styles.likeButton}>
            <img
              src={heartIcon}
              alt="icon heart"
              className={styles.heartIcon}
            />
          </button>
        ) : (
          <div className={styles.buttonsBlock}>
            <button className={styles.buttonLike}>
              <img
                src={heartIcon}
                alt="icon heart"
                className={styles.heartIcon}
              />
            </button>
            <button className={styles.buttonClose} onClick={handleClose}>
              <img
                src={buttonCloseIcon}
                alt="icon button close"
                className={styles.iconButtonClose}
              />
            </button>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>{booking.name}</h3>
          <p className={styles["parameters-block"]}>{booking.description}</p>
        </div>
        <div className={styles.footer}>
          <span className={styles.price}>
            {booking.rentalCost
              ? `${CURRENCYSYMBOL} ${formatPrice(booking.rentalCost)}`
              : ""}
          </span>
          <span className={styles.night}>
            {nights && booking.rentalCost
              ? `${CURRENCYSYMBOL} ${formatPrice(
                  booking.rentalCost / nights
                )}/${t("card.night")}`
              : booking.pricePerNight
              ? `${CURRENCYSYMBOL} ${formatPrice(booking.pricePerNight)}/${t(
                  "card.night"
                )}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardForMap;
