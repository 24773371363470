import { FC, useEffect, useState } from "react";
import Header from "../../components/header/header";
import FooterBottom from "../../components/footerBottom/footerBottom";
import UserDashboard from "../../components/userDashboard/userDashboard";
import { useNavigate } from "react-router-dom";
import { buildSearchQueryString } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const ProfilePage: FC = () => {
  const { queryParams } = useQueryParams();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const userName = "Maria Ivanova";
  const userEmail = "lvanova@gmail.com"; // TODO надо будет заменить на информацию с бэкенда
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [shouldSearch, setShouldSearch] = useState(false);

  const navigate = useNavigate();

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const dispatch = useDispatch();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };

  return (
    <div>
      <Header
        location={location}
        onLocationChange={handleLocationChange}
        availableDates={availableDates}
        setShouldSearch={setShouldSearch}
        handleSearch={handleSearch}
        locationSuggestions={locationSuggestions}
        onSelectSuggestion={handleSelectSuggestion}
        allowAllDates={true}
      />
      <UserDashboard username={userName} email={userEmail} />
      <FooterBottom />
    </div>
  );
};
export default ProfilePage;
