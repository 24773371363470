import React, { useState, useEffect, useRef } from "react";
import styles from "./phoneCodeSelector.module.scss";
import { useTranslation } from "react-i18next";
import arrowDownIcon from "../../assets/images/arrow_down.png";
import locationMarkerGrey from "../../assets/images/location-marker-grey.png";
import { Country } from "../../types/country";
import { COUNTRIES } from "../../utils/const";
import checkIcon from "../../assets/images/check-circle.png";
import iconButtonCLose from "../../assets/images/x.png";
import searchIcon from "../../assets/images/search.png";

interface IPhoneCodeSelector {
  selectedCountry: Country | null;
  setSelectedCountry: (country: Country | null) => void;
}

const PhoneCodeSelector: React.FC<IPhoneCodeSelector> = ({
  selectedCountry,
  setSelectedCountry,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(COUNTRIES);
  const telephoneModalRef = useRef<HTMLDivElement>(null);
  const inputForCountry = useRef<HTMLInputElement>(null);
  const isMobile = window.innerWidth <= 768;

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    setIsOpen(false);
    setSearchTerm("");
    setFilteredCountries(COUNTRIES);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Фильтрация по названию и коду страны
    const filtered = COUNTRIES.filter((country) => {
      const countryName = t(`phoneCodeModal.${country.value}`).toLowerCase();
      const countryCode = country.telephoneCode.toLowerCase();
      return countryName.includes(value) || countryCode.includes(value);
    });

    setFilteredCountries(filtered);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      telephoneModalRef.current &&
      !telephoneModalRef.current.contains(event.target as Node) &&
      inputForCountry.current &&
      !inputForCountry.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className={styles.inputBlock}>
        <img
          src={locationMarkerGrey}
          alt="location marker icon"
          className={styles.icon}
        />
        <div className={styles.blockCountry}>
          <p className={styles.countryText}>{t("phoneCodeModal.country")}</p>
          {isOpen && !isMobile ? (
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={t("phoneCodeModal.selectCountry")}
              className={styles.searchInput}
              ref={inputForCountry}
            />
          ) : (
            <p className={styles.countryCode}>
              {selectedCountry
                ? t(`phoneCodeModal.${selectedCountry.value}`)
                : t("phoneCodeModal.selectCountry")}
              {selectedCountry ? ` (${selectedCountry.telephoneCode})` : ""}
            </p>
          )}
        </div>
        <img
          src={arrowDownIcon}
          alt="arrow down icon"
          className={styles.arrowDownIcon}
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen && (
          <div className={styles.modal} ref={telephoneModalRef}>
            <div className={styles.modalContent}>
              <div className={styles.mobile}>
                <div className={styles.top}>
                  <p className={styles.textTop}>
                    {t("phoneCodeModal.chooseCode")}
                  </p>
                  <img
                    src={iconButtonCLose}
                    alt="icon button close"
                    className={styles.buttonClose}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className={styles.mobile}>
                <div className={styles.searchBlock}>
                  <img src={searchIcon} alt="search icon" />
                  <input
                    type="text"
                    className={styles.textSearch}
                    placeholder={t("phoneCodeModal.searchText")}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <ul className={styles.countryList}>
                {filteredCountries.map((country) => (
                  <li
                    key={country.value}
                    className={styles.countryItem}
                    onClick={() => handleSelect(country)}
                  >
                    <span className={styles.countryCodeModal}>
                      {country.telephoneCode}
                    </span>
                    <span className={styles.countryName}>
                      {t(`phoneCodeModal.${country.value}`)}
                    </span>
                    {selectedCountry?.value === country.value ? (
                      <img
                        src={checkIcon}
                        alt="check icon"
                        className={styles.checkIcon}
                      />
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneCodeSelector;
