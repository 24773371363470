import { NavigateFunction } from "react-router-dom";
import { QueryState } from "../store/querySlice";
import { ALLOWEDTYPES } from "./const";

export const formatDate = (date?: Date): string | undefined => {
  if (!date) {
    return undefined;
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const calculateNights = (checkIn?: Date, checkOut?: Date): number =>
  checkIn && checkOut
    ? Math.max(
        Math.ceil(
          (checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24)
        ),
        1
      )
    : 0;

export const getRoomFilterValue = (value: string | null) => {
  if (value === "Any" || !value) return undefined;
  return value === "5+" ? "5" : value;
};

export const applyFilterValue = (
  value: string | undefined,
  allowedValues: string[],
  setState: React.Dispatch<React.SetStateAction<string>>
) => {
  if (value && allowedValues.includes(value)) {
    setState(value);
  }
};

export const applyFilterValueArray = (
  value: string | undefined,
  allowedValues: string[],
  setState: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (value && allowedValues.includes(value)) {
    setState((prevState) => [...prevState, value]);
  }
};

export const getQueryParams = (
  search: string
): Record<string, string | undefined> => {
  const params = new URLSearchParams(search);
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value || undefined;
    return acc;
  }, {} as Record<string, string | undefined>);
};

// Функция для проверки и преобразования строки в дату
export const parseDate = (dateString: string | undefined): Date | undefined => {
  if (!dateString) return undefined;
  let formattedDateString = dateString;

  // Проверяем, если дата в формате YYYYMMDD (например, 20250221), преобразуем в YYYY-MM-DD
  if (/^\d{8}$/.test(dateString)) {
    formattedDateString = dateString.replace(
      /(\d{4})(\d{2})(\d{2})/,
      "$1-$2-$3"
    );
  }
  const parsedDate = new Date(formattedDateString);
  return !isNaN(parsedDate.getTime()) ? parsedDate : undefined;
};

export const formatPrice = (price: number) => {
  const integerPrice = Math.floor(price);
  return integerPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

// Преобразуем параметры URL в QueryState
export const convertToQueryState = (
  queryParams: Record<string, string | undefined>
): QueryState => {
  return {
    guestCount: {
      adults: Number(queryParams["adults"]) || 1,
      children: Number(queryParams["children"]) || 0,
      infants: Number(queryParams["infants"]) || 0,
      pets: Number(queryParams["pets"]) || 0,
    },
    radius: Number(queryParams["geoPoint[distance]"]) || 5,
    lon: queryParams["geoPoint[lon]"]
      ? Number(queryParams["geoPoint[lon]"]) || null
      : null,
    lat: queryParams["geoPoint[lat]"]
      ? Number(queryParams["geoPoint[lat]"]) || null
      : null,
    checkIn: queryParams["book[from]"] || undefined,
    checkOut: queryParams["book[to]"] || undefined,
    filters: {
      minPrice: Number(queryParams["minPrice"]) || 1,
      maxPrice: Number(queryParams["maxPrice"]) || 35000,
      selectedType: queryParams["kind[]"]
        ? decodeURIComponent(queryParams["kind[]"])
            .split(",")
            .filter((type) => ALLOWEDTYPES.includes(type))
        : ["hotel"],
      rooms: Number(queryParams["numBedrooms[gte]"]) || 1,
      beds: Number(queryParams["numBeds[gte]"]) || 1,
      bathrooms: Number(queryParams["numBathrooms[gte]"]) || 1,
    },
    rentalCost: queryParams["rentalCost"],
    rating: queryParams["rating"],
  };
};

export const getImageUrl = (
  storageUrl: string,
  width: number = 1400, // По умолчанию 1400
  height: number = 400 // По умолчанию 400
): string => {
  // Округление значений ширины и высоты
  const roundedWidth = Math.round(width);
  const roundedHeight = Math.round(height);

  if (storageUrl.startsWith("s3://")) {
    const imgHost = process.env.REACT_APP_IMAGE_HOST;

    // Применяем параметры для размера
    return `${imgHost}/unsafe/resizing_type:fill/resize:fill:${roundedWidth}:${roundedHeight}:0/gravity:sm/plain/${storageUrl}`;
  }

  return storageUrl; // Если это обычный URL, просто возвращаем его как есть
};

export const addToFavorites = (bookingId: string) => {
  const favoriteBookings = JSON.parse(
    localStorage.getItem("favoriteBookings") || "[]"
  );

  // Проверяем, существует ли уже карточка в избранном
  const existingFavoriteIndex = favoriteBookings.findIndex(
    (item: { id: string }) => item.id === bookingId
  );

  if (existingFavoriteIndex !== -1) {
    // Если карточка уже есть, обновляем дату
    favoriteBookings[existingFavoriteIndex].addedAt = new Date().toISOString();
  } else {
    // Если карточки нет, добавляем её с новой датой
    const newFavorite = {
      id: bookingId,
      addedAt: new Date().toISOString(),
    };
    favoriteBookings.push(newFavorite);
  }

  localStorage.setItem("favoriteBookings", JSON.stringify(favoriteBookings));
};

export const updateUrlDates = (
  navigate: NavigateFunction,
  pathname: string,
  searchParams: URLSearchParams,
  checkIn?: Date,
  checkOut?: Date
) => {
  if (!checkIn || !checkOut) {
    // Если даты не выбраны – удаляем их из URL
    searchParams.delete("book[from]");
    searchParams.delete("book[to]");
    searchParams.delete("book[nights]");
  } else {
    // Форматируем даты в нужный формат YYYYMMDD
    const formattedCheckIn = formatDate(checkIn);
    const formattedCheckOut = formatDate(checkOut);
    const nights = calculateNights(checkIn, checkOut);

    // Добавляем или обновляем параметры
    searchParams.set("book[from]", formattedCheckIn!);
    searchParams.set("book[to]", formattedCheckOut!);
    searchParams.set("book[nights]", nights.toString());
  }

  // Обновляем URL без перезагрузки страницы
  navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
};

export const updateUrlGuests = (
  navigate: NavigateFunction,
  pathname: string,
  searchParams: URLSearchParams,
  guests: { adults: number; children: number; infants: number; pets: number }
) => {
  // Удаляем старые параметры
  searchParams.delete("adults");
  searchParams.delete("children");
  searchParams.delete("infants");
  searchParams.delete("pets");

  // Добавляем новые параметры, если количество гостей больше 0
  if (guests.adults > 0) searchParams.set("adults", guests.adults.toString());
  if (guests.children > 0)
    searchParams.set("children", guests.children.toString());
  if (guests.infants > 0)
    searchParams.set("infants", guests.infants.toString());
  if (guests.pets > 0) searchParams.set("pets", guests.pets.toString());

  // Обновляем URL без перезагрузки страницы
  navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
};

interface BuildQueryOptions {
  includeKinds?: boolean;
}

export const buildSearchQueryString = (
  state: QueryState,
  location?: string,
  options: BuildQueryOptions = {}
): string => {
  const queryParams: Record<string, string> = {
    "geoPoint[distance]": state.radius.toString(),
    "numBeds[gte]": state.filters.beds.toString(),
    "numBedrooms[gte]": state.filters.rooms.toString(),
  };
  if (state.lat) {
    queryParams["geoPoint[lat]"] = state.lat.toString();
  }

  if (state.lon) {
    queryParams["geoPoint[lon]"] = state.lon.toString();
  }

  if (state.checkIn) {
    queryParams["book[from]"] = state.checkIn;
  }

  if (state.checkOut) {
    queryParams["book[to]"] = state.checkOut;
  }

  if (state.checkIn && state.checkOut) {
    queryParams["book[nights]"] = calculateNights(
      parseDate(state.checkIn),
      parseDate(state.checkOut)
    ).toString();
  }

  if (location) {
    queryParams["nameLocation"] = location;
  }

  if (state.guestCount.adults) {
    queryParams["adults"] = state.guestCount.adults.toString();
  }

  if (state.guestCount.children) {
    queryParams["children"] = state.guestCount.children.toString();
  }

  if (state.guestCount.infants) {
    queryParams["infants"] = state.guestCount.infants.toString();
  }

  if (state.guestCount.pets) {
    queryParams["pets"] = state.guestCount.pets.toString();
  }

  if (state.filters.bathrooms) {
    queryParams["numBathrooms[gte]"] = state.filters.bathrooms.toString();
  }

  if (state.rentalCost) {
    queryParams["rentalCost"] = state.rentalCost;
  }

  if (state.rating) {
    queryParams["rating"] = state.rating;
  }

  // Только если явно разрешено добавлять
  if (!options.includeKinds && state.filters.selectedType.length > 0) {
    queryParams["kind[]"] = state.filters.selectedType.join(",");
  }

  return Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};
