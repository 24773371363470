import React from "react";
import styles from "./bedsModal.module.scss";
import minusIcon from "../../assets/images/minusIcon.png";
import plusIcon from "../../assets/images/plusIcon.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateFilters } from "../../store/querySlice";

const BedsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const beds = useSelector(
    (state: { query: QueryState }) => state.query.filters.beds
  );
  const onChange = (change: number) => {
    const newBeds = beds + change;
    dispatch(updateFilters({ beds: newBeds >= 1 ? newBeds : 1 }));
  };

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t("bedsModal.beds")}</h3>
        <div className={styles.controls}>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              onChange(-1);
            }}
            disabled={beds <= 1}
          >
            <img src={minusIcon} alt="minus icon" />
          </button>
          <span className={styles.value}>{beds === 5 ? "5+" : beds}</span>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              onChange(1);
            }}
            disabled={beds >= 5}
          >
            <img src={plusIcon} alt="plus icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BedsModal;
