import React from "react";
import styles from "./cardList.module.scss";
import { Booking } from "../../types/booking";
import Card from "../card/card";

interface ICardListProps {
  bookings: Booking[];
  isEdit?: boolean;
  onDelete?: (id: string) => void;
}

const CardList: React.FC<ICardListProps> = ({ bookings, isEdit, onDelete }) => {
  return (
    <div className={styles.cardList}>
      {bookings.map((booking) => (
        <Card
          key={booking.id}
          booking={booking}
          isEdit={isEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default CardList;
