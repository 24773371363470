import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./stickyBanner.module.scss";
import leftHeader from "../../assets/images/header_left.png";
import logoIconWhite from "../../assets/images/logo/logoBlue.png";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateCoordinates } from "../../store/querySlice";
import { useNotification } from "../../context/notificationContext";
import { calculateNights, parseDate } from "../../utils/helpers";

interface IStickyBannerProps {
  id: string;
  pricePerNight: number | undefined;
}

const StickyBanner: React.FC<IStickyBannerProps> = ({ id, pricePerNight }) => {
  const { queryParams } = useQueryParams();
  const dispatch = useDispatch();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { t } = useTranslation();
  const getUrlWithParams = usePreserveQueryParams();
  const navigate = useNavigate();
  const lon = queryParams["geoPoint[lon]"]
    ? Number(queryParams["geoPoint[lon]"]) || null
    : null;
  const lat = queryParams["geoPoint[lat]"]
    ? Number(queryParams["geoPoint[lat]"]) || null
    : null;
  const location = queryParams["nameLocation"]
    ? queryParams["nameLocation"]
    : "";
  const { showNotification } = useNotification();

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -102;
      const yPosition =
        section.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: "smooth",
      });
    }
  };

  const handleBooking = () => {
    if (!state.checkIn || !state.checkOut) {
      showNotification(t("rightStickyContent.Please-enter-a-date"), "warning");
      return;
    }
    const queryParams: Record<string, string> = {
      "geoPoint[lat]": lat!.toString(),
      "geoPoint[lon]": lon!.toString(),
    };
    queryParams["geoPoint[distance]"] = state.radius.toString();

    if (state.checkIn) {
      queryParams["book[from]"] = state.checkIn;
    }

    if (state.checkOut) {
      queryParams["book[to]"] = state.checkOut;
    }

    if (state.checkIn && state.checkOut) {
      queryParams["book[nights]"] = calculateNights(
        parseDate(state.checkIn),
        parseDate(state.checkOut)
      ).toString();
    }
    if (location) {
      queryParams["nameLocation"] = location;
    }

    if (state.guestCount.adults) {
      queryParams["adults"] = state.guestCount.adults.toString();
    }

    if (state.guestCount.children) {
      queryParams["children"] = state.guestCount.children.toString();
    }

    queryParams["numBeds[gte]"] = state.filters.beds.toString();
    queryParams["numBedrooms[gte]"] = state.filters.rooms.toString();
    if (state.filters.selectedType.length > 0) {
      queryParams["kind[]"] = state.filters.selectedType.join(",");
    }
    // Формируем строку запроса
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    // если в процессе меняли локацию - но аерндуем старую локацию
    if (state.lat !== lat || state.lon !== lon) {
      dispatch(
        updateCoordinates({
          lat,
          lon,
        })
      );
    }
    // Навигация на страницу поиска
    navigate(`/booking-rent/${id}?${queryString}`);
  };
  return (
    <header className={`${styles.header} ${styles.sticky}`}>
      <div className={styles["header-content"]}>
        <div className={styles["header-left"]}>
          <div className={styles["sticky-navigation"]}>
            <div className={styles.logo}>
              <img
                className={styles.leftHeaderIcon}
                src={logoIconWhite}
                alt="icon"
                onClick={() => navigate(getUrlWithParams("/"))}
              />
            </div>
            <nav>
              <button onClick={() => scrollToSection("photos")}>
                {t("stickyBanner.Photos")}
              </button>
              <button onClick={() => scrollToSection("amenities")}>
                {t("stickyBanner.Amenities")}
              </button>
              <button onClick={() => scrollToSection("reviews")}>
                {t("stickyBanner.Reviews")}
              </button>
              <button onClick={() => scrollToSection("location")}>
                {t("stickyBanner.Location")}
              </button>
            </nav>
          </div>
        </div>
        <div className={styles["header-right-sticky"]}>
          <div className={styles["price-block"]}>
            {pricePerNight ? (
              <p className={styles.price}>
                ${pricePerNight}&nbsp;{t("stickyBanner.night")}
              </p>
            ) : (
              ""
            )}
            <p className={styles["header-reviews"]}>
              {t("stickyBanner.reviews", { count: 12 })}
            </p>
            <button
              className={styles["check-dates-btn"]}
              onClick={handleBooking}
            >
              <p className={styles.textInButton}>{t("stickyBanner.reserve")}</p>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default StickyBanner;
