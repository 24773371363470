import React, { useEffect, useState } from "react";
import { Photo } from "../../types/photo";
import styles from "./photoGallery.module.scss";
import { getImageUrl } from "../../utils/helpers";
import iconButtonClose from "../../assets/images/x-inGallery.png";
import leftArrowIcon from "../../assets/images/chevron-left-white.png";
import rightArrowIcon from "../../assets/images/chevron-right-white.png";
import { useSwipeable } from "react-swipeable";

interface PhotoGalleryProps {
  photos: Photo[];
  onClose: () => void;
  activeIndexPhoto?: number;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onClose, activeIndexPhoto }) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndexPhoto ? activeIndexPhoto : 0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1020);
  const hasMultiplePhotos = photos.length > 1;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1020);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
    setIsImageLoaded(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
    setIsImageLoaded(false);
  };

  useEffect(() => {
    // Отключаем скролл при открытии галереи
    document.body.style.overflow = "hidden";

    return () => {
      // Включаем скролл при закрытии галереи
      document.body.style.overflow = "";
    };
  }, []);

  const widthImage = isMobile ? window.innerWidth : 828;
  const heightIamge = isMobile
    ? window.innerHeight / 3
    : window.innerHeight - 146;

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
  });

  return (
    <div className={styles.photoGalleryModal}>
      <div
        className={styles.overlay}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      ></div>
      <button
        className={`${styles.closeBtn} ${isImageLoaded ? styles.visible : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <img
          src={iconButtonClose}
          alt="button close icon"
          className={styles.iconButtonclose}
        />
      </button>
      {!isMobile && isImageLoaded && hasMultiplePhotos &&(
        <>
          <button
            className={`${styles.arrow} ${styles.leftArrow} ${styles.visible}`}
            onClick={handlePrev}
          >
            <img
              src={leftArrowIcon}
              alt="icon arrow left"
              className={styles.iconArrow}
            />
          </button>
          <button
            className={`${styles.arrow} ${styles.rightArrow} ${styles.visible}`}
            onClick={handleNext}
          >
            <img
              src={rightArrowIcon}
              alt="icon arrow left"
              className={styles.iconArrow}
            />
          </button>
        </>
      )}
      <div {...handlers} className={styles.photoGalleryContent}>
        {!isImageLoaded && <div className={styles.loader}>Loading...</div>}
        <div className={styles.photoContainer}>
          <img
            src={getImageUrl(
              photos[currentIndex].storageUrl,
              widthImage,
              heightIamge
            )}
            alt={
              photos[currentIndex].description || `Photo ${currentIndex + 1}`
            }
            className={`${styles.photo} ${isImageLoaded ? styles.visible : ""}`}
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
      </div>
      {/* Счетчик фото */}
      <div className={styles.photoCounter}>
        {currentIndex + 1} / {photos.length}
      </div>
    </div>
  );
};

export default PhotoGallery;
