import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./languageSwitcher.module.scss";
import englishFlag from "../../assets/images/flags/united kingdom.png";
import austriaFlag from "../../assets/images/flags/austria.png";
import belgiumFlag from "../../assets/images/flags/belgium.png";
import bulgariaFlag from "../../assets/images/flags/bulgaria.png";
import usaFlag from "../../assets/images/flags/united states.png";
import hungaryFlag from "../../assets/images/flags/hungary.png";
import germanyFlag from "../../assets/images/flags/germany.png";
import greeceFlag from "../../assets/images/flags/greece.png";
import denmarkFlag from "../../assets/images/flags/denmark.png";
import irlandianFlag from "../../assets/images/flags/ireland.png";
import spainFlag from "../../assets/images/flags/spain.png";
import italyFlag from "../../assets/images/flags/italy.png";
import cyprusFlag from "../../assets/images/flags/cyprus.png";
import latviaFlag from "../../assets/images/flags/latvia.png";
import lithuaniaFlag from "../../assets/images/flags/lithuania.png";
import luxembourgFlag from "../../assets/images/flags/luxembourg.png";
import maltaFlag from "../../assets/images/flags/malta.png";
import netherlandsFlag from "../../assets/images/flags/netherlands.png";
import polandFlag from "../../assets/images/flags/poland.png";
import portugalFlag from "../../assets/images/flags/portugal.png";
import romaniaFlag from "../../assets/images/flags/romania.png";
import slovakiaFlag from "../../assets/images/flags/slovakia.png";
import sloveniaFlag from "../../assets/images/flags/slovenia.png";
import finlandFlag from "../../assets/images/flags/finland.png";
import franceFlag from "../../assets/images/flags/france.png";
import croatiaFlag from "../../assets/images/flags/croatia.png";
import czechFlag from "../../assets/images/flags/czech republic.png";
import swedenFlag from "../../assets/images/flags/sweden.png";
import estoniaFlag from "../../assets/images/flags/estonia.png";
import russianFlag from "../../assets/images/flags/russia.png";
import iconButtonCLose from "../../assets/images/x.png";

const languages = [
  { code: "at", name: "languageSwitcher.at", icon: austriaFlag },
  { code: "be", name: "languageSwitcher.be", icon: belgiumFlag },
  { code: "bg", name: "languageSwitcher.bg", icon: bulgariaFlag },
  { code: "us", name: "languageSwitcher.us", icon: usaFlag },
  { code: "en", name: "languageSwitcher.eng", icon: englishFlag },
  { code: "hu", name: "languageSwitcher.hu", icon: hungaryFlag },
  { code: "de", name: "languageSwitcher.de", icon: germanyFlag },
  { code: "gr", name: "languageSwitcher.gr", icon: greeceFlag },
  { code: "dk", name: "languageSwitcher.dk", icon: denmarkFlag },
  { code: "ie", name: "languageSwitcher.ie", icon: irlandianFlag },
  { code: "es", name: "languageSwitcher.es", icon: spainFlag },
  { code: "it", name: "languageSwitcher.it", icon: italyFlag },
  { code: "cy", name: "languageSwitcher.cy", icon: cyprusFlag },
  { code: "lv", name: "languageSwitcher.lv", icon: latviaFlag },
  { code: "lt", name: "languageSwitcher.lt", icon: lithuaniaFlag },
  { code: "lu", name: "languageSwitcher.lu", icon: luxembourgFlag },
  { code: "mt", name: "languageSwitcher.mt", icon: maltaFlag },
  { code: "nl", name: "languageSwitcher.nl", icon: netherlandsFlag },
  { code: "pl", name: "languageSwitcher.pl", icon: polandFlag },
  { code: "pt", name: "languageSwitcher.pt", icon: portugalFlag },
  { code: "ro", name: "languageSwitcher.ro", icon: romaniaFlag },
  { code: "sk", name: "languageSwitcher.sk", icon: slovakiaFlag },
  { code: "si", name: "languageSwitcher.si", icon: sloveniaFlag },
  { code: "fi", name: "languageSwitcher.fi", icon: finlandFlag },
  { code: "fr", name: "languageSwitcher.fr", icon: franceFlag },
  { code: "hr", name: "languageSwitcher.hr", icon: croatiaFlag },
  { code: "cz", name: "languageSwitcher.cz", icon: czechFlag },
  { code: "se", name: "languageSwitcher.se", icon: swedenFlag },
  { code: "ee", name: "languageSwitcher.ee", icon: estoniaFlag },
  { code: "ru", name: "languageSwitcher.rus", icon: russianFlag },
];

interface ILanguageSwitcher {
  isMainPage?: boolean;
  isBurgerMenu?: boolean;
}

const LanguageSwitcher: React.FC<ILanguageSwitcher> = ({
  isMainPage = false,
  isBurgerMenu = false,
}) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const languageMenuRef = useRef<HTMLDivElement>(null);
  const languageSwitcherRef = useRef<HTMLDivElement>(null);
  const isMobile = window.innerWidth <= 1020;

  const currentLanguage =
    languages.find((lang) => lang.code === i18n.language) || languages[0];

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const changeLanguage = (languageCode: string) => {
    console.log(languageCode);
    if (languageCode === "ru") {
      // TODO временно пока два языка - потом этот код убрать - а другой расскомментировать
      i18n.changeLanguage(languageCode);
    } else {
      i18n.changeLanguage("en");
    }
    // i18n.changeLanguage(languageCode);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      languageMenuRef.current &&
      !languageMenuRef.current.contains(event.target as Node) &&
      languageSwitcherRef.current &&
      !languageSwitcherRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Закрыть меню, если клик был вне модалки и кнопки переключения языка
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, isMobile]);

  return (
    <div
      className={
        isBurgerMenu
          ? `${styles["language-switcher"]} ${styles.heightInBurgerMenu}`
          : styles["language-switcher"]
      }
      ref={languageSwitcherRef}
    >
      {isBurgerMenu ? (
        <div className={styles.topInBurgerMenu} onClick={toggleMenu}>
          <p className={styles.textInBurgerMenu}>{currentLanguage.code}</p>
          <button className={`${styles["language-button"]} ${styles["mr-0"]}`}>
            <img
              src={currentLanguage.icon}
              alt={`${currentLanguage.name} flag`}
              className={styles["image-icon"]}
            />
          </button>
        </div>
      ) : (
        <div onClick={toggleMenu} className={styles["language-block"]}>
          <button className={styles["language-button"]}>
            <img
              src={currentLanguage.icon}
              alt={`${currentLanguage.name} flag`}
              className={styles["image-icon"]}
            />
          </button>
          <p
            className={
              isMainPage
                ? `${styles["text-language"]} ${styles.white}`
                : styles["text-language"]
            }
          >
            {currentLanguage.code}
          </p>
        </div>
      )}
      {isOpen && (
        <div className={styles["language-menu"]} ref={languageMenuRef}>
          <p className={`${styles.text} ${styles.desktop}`}>
            {t("languageSwitcher.chooseRegion")}
          </p>
          <div className={styles.mobile}>
            <div className={styles.top}>
              <p className={styles.mobileText}>
                {t("languageSwitcher.chooseRegion")}
              </p>
              <img
                src={iconButtonCLose}
                alt="icon button close"
                className={styles.buttonClose}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              />
            </div>
          </div>
          <div className={`${styles.languagesBlock} ${styles.desktop}`}>
            {languages.map((language) => (
              <button
                key={language.name}
                onClick={() => changeLanguage(language.code)}
                className={styles["language-option"]}
              >
                <img
                  src={language.icon}
                  alt={`${language.name} flag`}
                  className={styles["language-icon"]}
                />
                <p className={styles.languageName}> {t(language.name)}</p>
              </button>
            ))}
          </div>
          <div className={`${styles.languagesBlock} ${styles.mobile}`}>
            {languages.map((language) => (
              <button
                key={language.name}
                onClick={() => changeLanguage(language.code)}
                className={styles.buttonMobile}
              >
                <div className={styles.countryName}> {t(language.name)}</div>
                <img
                  src={language.icon}
                  alt={`${language.name} flag`}
                  className={styles["language-icon"]}
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
