import React, { useEffect, useState } from "react";
import styles from "./pollResults.module.scss";
import { useTranslation } from "react-i18next";

interface PollOption {
  id: string;
  text: {
    ru: string;
    en: string;
  };
  percentage: number;
}

interface PollResultsProps {
  question: {
    ru: string;
    en: string;
  };
  votedCount: number;
  options: PollOption[];
  pollId: string;
}

const PollResults: React.FC<PollResultsProps> = ({
  question,
  votedCount,
  options,
  pollId
}) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language as "ru" | "en";
  const localStorageKey = `poll_selected_${pollId}`;
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);

  // Загружаем из localStorage
  useEffect(() => {
    const stored = localStorage.getItem(localStorageKey);
    if (stored) {
      setSelectedOptionId(stored);
    }
  }, [localStorageKey]);
  
  const handleSelect = (id: string) => {
    if (selectedOptionId) return; // Уже проголосовал
    setSelectedOptionId(id);
    localStorage.setItem(localStorageKey, id);
    //TODO Тут отправляем данные на сервер
  };

  return (
    <div className={styles.pollWrapper}>
      <div className={styles.left}>
        <p className={styles.question}>{question[lang]}</p>
        <p className={styles.voted}>
          {t("pollResults.voted")}: <span>{votedCount}</span>
        </p>
      </div>
      <div className={styles.right}>
        {options.map((option) => (
          <div key={option.id} className={styles.optionRow}>
            <div className={styles.optionRowTop}>
              <div className={styles.radioText}>
              <div
                  className={styles.radioOuter}
                  onClick={() => handleSelect(option.id)}
                >
                  {selectedOptionId === option.id && (
                    <div className={styles.radioInner}></div>
                  )}
                </div>
                <span className={styles.spanText}>{option.text[lang]}</span>
              </div>
              <div className={styles.percent}>{option.percentage}%</div>
            </div>
            <div className={styles.progressBar}>
              <div
                className={styles.fill}
                style={{ width: `${option.percentage }%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PollResults;
