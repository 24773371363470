import React, { FC, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./mapWithPrices.module.scss";
import { useTranslation } from "react-i18next";
import { Booking } from "../../types/booking";
import CardForMap from "../cardForMap/cardForMap";
import viewMapIcon from "../../assets/images/icon-for-search/arrow-narrow-left.png";
import listIcon from "../../assets/images/icon-for-search/arrow-narrow-right.png";
import CustomControl from "../customControl/customControl";
import { CURRENCYSYMBOL } from "../../utils/const";

const CustomPopupStyle: React.FC = () => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .leaflet-popup {
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;
      }
      .leaflet-popup-content-wrapper {
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;
      }
      .leaflet-popup-content {
        margin: 0 !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  return null;
};

interface IMapWithPrices {
  bookings: Booking[];
  lat: number;
  lon: number;
  onClose: () => void;
  viewMode: "listAndMap" | "mapOnly";
}

const MapWithPrices: FC<IMapWithPrices> = ({
  bookings,
  lat,
  lon,
  onClose,
  viewMode,
}) => {
  const isMobile = window.innerWidth <= 768;
  const { t, i18n } = useTranslation();
  const [selectedBooking, setSelectedBooking] = useState<null | Booking>(null);
  const [activeMarkerId, setActiveMarkerId] = useState<string | null>(null);

  const handleBackButtonClick = () => {
    onClose();
  };

  // Создаём кастомную DivIcon через Leaflet
  const createPriceIcon = (price: number | undefined, isActive: boolean) =>
    L.divIcon({
      html: `<div style="
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: 18px;
        background: ${isActive ? "#7623FF" : "white"};
        color: ${isActive ? "white" : "#112211"};
        height: 35px;
        display: flex;
        align-items: center;
        border-radius: 33px;
        padding-left: 4px;
        padding-right: 4px;
        min-width: 67px;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-align: center;">
        ${price ? CURRENCYSYMBOL : ""}${price ? price : ""}
      </div>`,
      className: "",
    });

  // Этот компонент будет использовать invalidateSize всякий раз, когда карта рендерится
  const ResizeMap = () => {
    const map = useMap();

    useEffect(() => {
      map.invalidateSize(); // Обновляем размеры карты каждый раз при рендере
    }, [map]);

    return null;
  };

  const handleClose = () => {
    setSelectedBooking(null);
    setActiveMarkerId(null);
  };

  return (
    <div className={styles.mapContainer}>
      <button
        className={
          viewMode === "mapOnly" ? styles.listButton : styles.mapButton
        }
        onClick={handleBackButtonClick}
      >
        <img
          src={viewMode === "mapOnly" ? listIcon : viewMapIcon}
          alt={
            viewMode === "mapOnly"
              ? t("mapWithPrices.showList")
              : t("mapWithPrices.viewMap")
          }
        />
        {viewMode === "mapOnly" ? (
          <p className={styles.text}>{t("mapWithPrices.showList")}</p>
        ) : (
          ""
        )}
      </button>
      <CustomPopupStyle />
      <MapContainer
        center={[lat, lon]}
        zoom={13}
        minZoom={6}
        scrollWheelZoom={true}
        className={
          viewMode === "mapOnly"
            ? styles.mapContenOnly
            : styles.mapContetnAndList
        }
        zoomControl={false}
      >
        <ResizeMap />
        <CustomControl />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${i18n.language}`}
        />
        {bookings.map((booking) => (
          <Marker
            key={booking.id}
            position={[booking.geoPoint.lat, booking.geoPoint.lon]}
            icon={createPriceIcon(
              booking.rentalCost ? booking.rentalCost : booking.pricePerNight,
              activeMarkerId === booking.id
            )} // TODO Устанавливаем фиксированную цену пока ее нет на бэке
            eventHandlers={{
              click: () => {
                setSelectedBooking(booking);
                setActiveMarkerId(booking.id);
              },
              popupclose: () => {
                handleClose();
              },
            }}
          >
            {!isMobile && (
              <Popup closeButton={false}>
                {selectedBooking && (
                  <CardForMap booking={selectedBooking} onClose={handleClose} />
                )}
              </Popup>
            )}
          </Marker>
        ))}
      </MapContainer>
      {isMobile && selectedBooking && (
        <div className={styles.mobilePopup}>
          <CardForMap booking={selectedBooking} onClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default MapWithPrices;
