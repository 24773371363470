import { useEffect, useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import { addMonths, format, Locale, startOfMonth } from "date-fns";
import "react-day-picker/style.css";
import styles from "./twoCalendarsRangePicker.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateDates } from "../../store/querySlice";
import { formatDate, updateUrlDates } from "../../utils/helpers";
import {
  selectCheckIn,
  selectCheckOut,
} from "../../store/selectors/querySelectors";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  allowAllDates: boolean;
  availableDatesSet: Set<string>;
  currentLocale: Locale;
  useCustomData?: boolean;
  checkInLocal?: Date | undefined;
  checkOutLocal?: Date | undefined;
  handleChangeDates?: (
    checkIn: Date | undefined,
    checkOut: Date | undefined
  ) => void;
}

export function TwoCalendarsRangePicker({
  allowAllDates,
  availableDatesSet,
  currentLocale,
  useCustomData = false,
  checkInLocal,
  checkOutLocal,
  handleChangeDates,
}: IProps) {
  const checkInGlobal = useSelector(selectCheckIn);
  const checkOutGlobal = useSelector(selectCheckOut);
  const checkIn = useCustomData ? checkInLocal : checkInGlobal;
  const checkOut = useCustomData ? checkOutLocal : checkOutGlobal;
  const dispatch = useDispatch();
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(locationUrl.search);
  const [isBookingPage, setIsBookingPage] = useState(false);

  useEffect(() => {
    const testIsBookingPage = /^\/booking(-rent)?\/[^/]+$/.test(
      locationUrl.pathname
    );
    setIsBookingPage(testIsBookingPage);
  }, [locationUrl.pathname]);

  // Левый и правый месяц, которые действительно рендерятся
  const [leftMonth, setLeftMonth] = useState<Date>(
    startOfMonth(new Date()) // по умолчанию - текущий
  );
  const [rightMonth, setRightMonth] = useState<Date>(
    addMonths(startOfMonth(new Date()), 1) // по умолчанию +1 месяц
  );

  // Когда меняется checkIn (например, пользователь выбрал новую дату),
  // перенастраиваем месяцы:
  useEffect(() => {
    if (checkIn) {
      const monthOfCheckIn = startOfMonth(checkIn);
      setLeftMonth(monthOfCheckIn);
      setRightMonth(addMonths(monthOfCheckIn, 1));
    } else {
      // Если диапазон сброшен (нет checkIn),
      // возвращаемся к текущему месяцу + следующий:
      setLeftMonth(startOfMonth(new Date()));
      setRightMonth(addMonths(startOfMonth(new Date()), 1));
    }
  }, []);

  // Обработчик выбора дат (диапазона)
  const handleSelect = (range: DateRange | undefined) => {
    if (!range) {
      if (useCustomData) {
        if (handleChangeDates) {
          handleChangeDates(undefined, undefined);
        }
      } else {
        if (isBookingPage) {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            undefined,
            undefined
          );
        }
        dispatch(updateDates({ checkIn: undefined, checkOut: undefined }));
      }
      return;
    }
    // Если уже выбраны checkIn и checkOut, сбрасываем и начинаем с новой даты
    if (checkIn && checkOut && checkIn.getTime() !== checkOut.getTime()) {
      if (range.from !== checkIn) {
        if (useCustomData) {
          if (handleChangeDates) {
            handleChangeDates(range.from, range.from);
          }
        } else {
          if (isBookingPage) {
            updateUrlDates(
              navigate,
              locationUrl.pathname,
              searchParams,
              range.from,
              range.from
            );
          }
          dispatch(
            updateDates({
              checkIn: formatDate(range.from),
              checkOut: formatDate(range.from),
            })
          );
        }
      } else {
        if (useCustomData) {
          if (handleChangeDates) {
            handleChangeDates(range.to, range.to);
          }
        } else {
          if (isBookingPage) {
            updateUrlDates(
              navigate,
              locationUrl.pathname,
              searchParams,
              range.to,
              range.to
            );
          }
          dispatch(
            updateDates({
              checkIn: formatDate(range.to),
              checkOut: formatDate(range.to),
            })
          );
        }
      }
    } else {
      if (useCustomData) {
        if (handleChangeDates) {
          handleChangeDates(range.from, range.to);
        }
      } else {
        if (isBookingPage) {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            range.from,
            range.to
          );
        }
        dispatch(
          updateDates({
            checkIn: formatDate(range.from),
            checkOut: formatDate(range.to),
          })
        );
      }
    }
  };

  // Пользователь перелистывает месяц в левом календаре:
  const handleLeftMonthChange = (newMonth: Date) => {
    setLeftMonth(newMonth);
  };

  // Пользователь перелистывает месяц в правом календаре:
  const handleRightMonthChange = (newMonth: Date) => {
    setRightMonth(newMonth);
  };

  return (
    <div className={styles.dayPickers}>
      {/* Левый календарь */}
      <DayPicker
        mode="range"
        month={leftMonth} // какой месяц показать
        onMonthChange={handleLeftMonthChange}
        selected={{
          from: checkIn,
          to: checkOut,
        }}
        onSelect={handleSelect}
        numberOfMonths={1}
        disabled={[
          {
            before: new Date(),
          },
          (date) => {
            if (allowAllDates) return false; // Если все даты разрешены, не блокируем
            const formattedDate = format(date, "yyyyMMdd");
            return !availableDatesSet.has(formattedDate);
          },
        ]}
        locale={currentLocale}
        formatters={{
          formatCaption: (date) =>
            format(date, "LLLL yyyy", { locale: currentLocale }),
        }}
      />

      {/* Правый календарь */}
      <div className={styles.secondCalendar}>
        <DayPicker
          mode="range"
          month={rightMonth} // какой месяц показать
          onMonthChange={handleRightMonthChange}
          selected={{ from: checkIn, to: checkOut }}
          onSelect={handleSelect}
          numberOfMonths={1}
          disabled={[
            {
              before: new Date(),
            },
            (date) => {
              if (allowAllDates) return false; // Если все даты разрешены, не блокируем
              const formattedDate = format(date, "yyyyMMdd");
              return !availableDatesSet.has(formattedDate);
            },
          ]}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
        />
      </div>
    </div>
  );
}
