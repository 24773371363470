import { FC } from "react";
import styles from "./roomGallery.module.scss";
import { Photo } from "../../types/photo";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";

interface RoomGalleryProps {
  photos: Photo[];
  onShowAll: (indexPhoto?: number) => void;
}

const RoomGallery: FC<RoomGalleryProps> = ({ photos, onShowAll }) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 1020;

  const widthImage = isMobile
    ? ((window.innerWidth - 20 - 24) / 11) * 10
    : 436.5;
  const heightImage = isMobile ? 312 : 400;

  const handleClick = (index: number) => {
    onShowAll(index);
  };

  return (
    <div className={styles["room-gallery-wrapper"]}>
      <h2 className={styles["room-gallery-heading"]}>
        {t("roomGallery.whereYouSleep")}
      </h2>

      {/* Галерея */}
      <div className={styles["room-gallery"]}>
        {photos.length >= 1 && (
          <Swiper
            slidesPerView={isMobile ? 1.1 : 2}
            spaceBetween={24}
            freeMode={true}
            grabCursor={true}
            direction="horizontal"
            loop={photos.length > 2 ? true : false}
            centeredSlides={false} // Отключает смещение первого слайда
          >
            {photos.map((photo, index) => (
              <SwiperSlide key={index} className={styles["room-gallery-item"]}>
                <img
                  src={getImageUrl(photo.storageUrl, widthImage, heightImage)}
                  alt={photo.description}
                  className={styles["room-gallery-image"]}
                  onClick={() => handleClick(index)}
                />
                <div className={styles["room-gallery-info"]}>
                  <h3 className={styles["room-gallery-title"]}>
                    {photo.semanticId}
                  </h3>
                  <p className={styles["room-gallery-subtitle"]}>
                    {photo.description}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default RoomGallery;
