import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import styles from "./filterModal.module.scss";
import closeIcon from "../../assets/images/icon-for-search/x.png";
import Slider from "react-slider";
import PriceChart from "../priceChart/priceChart";
import { useTranslation } from "react-i18next";
import { fetchSearchResults } from "../../api/api";
import {
  applyFilterValue,
  applyFilterValueArray,
  calculateNights,
  getRoomFilterValue,
  parseDate,
} from "../../utils/helpers";
import { useQueryParams } from "../../hooks/useQueryParams";
import iconButtonCLose from "../../assets/images/x.png";
import { ALLOWEDTYPES, CURRENCYSYMBOL } from "../../utils/const";
import { useIOSViewportFix } from "../../hooks/useIOSViewportFix";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateFilters } from "../../store/querySlice";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  setShouldSearch,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { queryParams } = useQueryParams();
  useIOSViewportFix();
  const { t } = useTranslation();
  const [isDisableSearchButton, setIsDisableSearchButton] = useState(true);

  useEffect(() => {
    if (state.lat && state.lon) {
      setIsDisableSearchButton(false);
    }
  }, [state.lat, state.lon]);
  const [isEditing, setIsEditing] = useState(false);
  const [minPrice, setMinPrice] = useState(state.filters.minPrice);
  const [maxPrice, setMaxPrice] = useState(state.filters.maxPrice);
  const [selectedType, setSelectedType] = useState<string[]>(
    Array.isArray(state.filters.selectedType) &&
      state.filters.selectedType.length > 0
      ? state.filters.selectedType // Если типы были переданы, используем их
      : ["hotel"] // Если ничего не передано, ставим "hotel"
  );
  const [selectedBedrooms, setSelectedBedrooms] = useState(
    state.filters.rooms.toString()
  );
  const [selectedBeds, setSelectedBeds] = useState(
    state.filters.beds.toString()
  );
  const [selectedBathrooms, setSelectedBathrooms] = useState(
    state.filters.bathrooms.toString()
  );

  // Используем useRef для хранения актуальных значений
  const priceRef = useRef({ minPrice, maxPrice });

  useEffect(() => {
    priceRef.current = { minPrice, maxPrice };
  }, [minPrice, maxPrice]);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const formatInputValue = (value: number, isMax?: boolean) => {
    const formattedValue = value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return isMax
      ? `${CURRENCYSYMBOL} ${formattedValue}+`
      : `${CURRENCYSYMBOL} ${formattedValue}`;
  };

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ""), 10);
    if (!isNaN(value) && value <= maxPrice) {
      setMinPrice(value);
    }
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ""), 10);
    if (!isNaN(value) && value >= minPrice) {
      setMaxPrice(value);
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (isOpen) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, [isOpen]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleTypeSelection = (type: string) => {
    setSelectedType((prevSelectedTypes) => {
      // Если тип уже выбран, его удаляем
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter((item) => item !== type);
      }
      // Если тип не выбран, добавляем его
      return [...prevSelectedTypes, type];
    });
  };

  // Убедиться, что всегда выбран хотя бы один тип (например, "hotel")
  useEffect(() => {
    if (selectedType.length === 0) {
      setSelectedType(["hotel"]);
    }
  }, [selectedType]);

  const handleRoomSelection = (
    type: "bedrooms" | "beds" | "bathrooms",
    value: string
  ) => {
    if (type === "bedrooms") setSelectedBedrooms(value);
    if (type === "beds") setSelectedBeds(value);
    if (type === "bathrooms") setSelectedBathrooms(value);
  };

  const handleSliderChange = (values: [number, number]) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
    debouncedFetchResults();
  };

  useEffect(() => {
    const allowedValues = ["Any", "1", "2", "3", "4", "5+"];

    const numberBedrooms = queryParams["numBedrooms[gte]"];
    const numberBathrooms = queryParams["numBathrooms[gte]"];
    const numberBeds = queryParams["numBeds[gte]"];
    const type = queryParams.kind;

    if (type) {
      applyFilterValueArray(type, ALLOWEDTYPES, setSelectedType);
    }

    applyFilterValue(numberBeds, allowedValues, setSelectedBeds);
    applyFilterValue(numberBathrooms, allowedValues, setSelectedBathrooms);
    applyFilterValue(numberBedrooms, allowedValues, setSelectedBedrooms);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchResults = async () => {
    const { minPrice, maxPrice } = priceRef.current; //TOD когда бэк сделает параметры для цены - нужно будет их применить
    try {
      const data = await fetchSearchResults({
        lat: state.lat?.toString(),
        lon: state.lon?.toString(),
        distance: state.radius.toString(),
        from: state.checkIn,
        nights:
          state.checkIn && state.checkOut
            ? calculateNights(
                parseDate(state.checkIn),
                parseDate(state.checkOut)
              ).toString()
            : undefined,
        numberBeds: getRoomFilterValue(selectedBeds),
        numberBedrooms: getRoomFilterValue(selectedBedrooms),
        numberBathrooms: getRoomFilterValue(selectedBathrooms),
        kind: selectedType,
        rentalCost: state.rentalCost,
        rating: state.rating
      });
      setTotalOffers(data["hydra:totalItems"]);
    } catch (err) {
      console.log("Произошла ошибка при запросе к серверу");
    }
  };

  // Вызываем fetchResults, если меняются значимые параметры
  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedBedrooms, selectedBeds, selectedBathrooms]);

  const handleClear = () => {
    setMinPrice(state.filters.minPrice);
    setMaxPrice(state.filters.maxPrice);
    setSelectedType(state.filters.selectedType);
    setSelectedBedrooms(state.filters.rooms.toString());
    setSelectedBeds(state.filters.beds.toString());
    setSelectedBathrooms(state.filters.bathrooms.toString());
  };

  // Функция для обработки клика по кнопке Apply
  const handleApply = () => {
    dispatch(
      updateFilters({
        minPrice,
        maxPrice,
        selectedType,
        rooms: selectedBedrooms === "Any" ? 1 : parseInt(selectedBedrooms, 10),
        beds: selectedBeds === "Any" ? 1 : parseInt(selectedBeds, 10),
        bathrooms:
          selectedBathrooms === "Any" ? 1 : parseInt(selectedBathrooms, 10),
      })
    );
    setShouldSearch(true);
    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchResults = useCallback(
    debounce(() => {
      fetchResults();
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedFetchResults.cancel();
    };
  }, [debouncedFetchResults]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button
          className={`${styles.closeButton} ${styles.desktop}`}
          onClick={onClose}
        >
          <img src={closeIcon} alt={t("filterModal.closeButton")} />
        </button>
        <div className={styles.modalContent}>
          <h2 className={`${styles.title} ${styles.desktop}`}>
            {t("filterModal.title")}
          </h2>
          <div className={styles.mobile}>
            <div className={styles.mobileTop}>
              <div className={styles.mobileTopLeft}>
                <h2 className={styles.title}>{t("filterModal.title")}</h2>
                <button
                  className={styles.clearButtonMobile}
                  onClick={handleClear}
                >
                  {t("filterModal.clearButton")}
                </button>
              </div>
              <img
                src={iconButtonCLose}
                alt="icon button close"
                className={styles.buttonClose}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            </div>
          </div>
          <div className={styles.BlockForMobileOverflow}>
            <div className={styles.filterSection}>
              <h3 className={styles.subtitle}>
                {" "}
                {t("filterModal.typeAllocationTitle")}
              </h3>
              <p className={styles.text}>
                {t("filterModal.typeAllocationDescription")}
              </p>
              <div className={styles.allocationButtons}>
                {["hotel", "apartment", "house"].map((type) => (
                  <button
                    key={type}
                    className={
                      selectedType.includes(type)
                        ? styles.activeButton
                        : styles.button
                    }
                    onClick={() => handleTypeSelection(type)}
                  >
                    {t(`filterModal.typeAllocation${type}`)}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.filterSection}>
              <h3 className={styles.subtitle}>
                {t("filterModal.priceRangeTitle")}
              </h3>
              <div className={styles.priceSliderImage}>
                <PriceChart />
              </div>
              <Slider
                className={styles.priceSlider}
                renderThumb={({ key, ...props }, state) => (
                  <div
                    key={key}
                    {...props}
                    className={`${styles.sliderThumb} ${
                      state.index === 0 ? styles.firstThumb : ""
                    }`}
                  />
                )}
                trackClassName={styles.sliderTrack}
                value={[minPrice, maxPrice]}
                min={0}
                max={35000}
                step={35}
                onChange={handleSliderChange}
              />
              <div className={styles.priceInputs}>
                {/* Input для минимальной цены */}
                <div className={styles.priceInput}>
                  <label htmlFor="minPrice">
                    {t("filterModal.priceRangeMinLabel")}
                  </label>
                  <input
                    type="text"
                    id="minPrice"
                    value={formatInputValue(minPrice)}
                    onChange={handleMinPriceChange}
                    className={styles.input}
                  />
                </div>

                {/* Input для максимальной цены */}
                <div className={styles.priceInput}>
                  <label htmlFor="maxPrice">
                    {" "}
                    {t("filterModal.priceRangeMaxLabel")}
                  </label>
                  <input
                    type="text"
                    id="maxPrice"
                    value={
                      isEditing
                        ? formatInputValue(maxPrice)
                        : formatInputValue(maxPrice, true)
                    }
                    onChange={handleMaxPriceChange}
                    className={styles.input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.filterSection}>
              <h3 className={styles.subtitle}>{t("filterModal.roomsTitle")}</h3>
              <div className={styles.roomFilters}>
                {["bedrooms", "beds", "bathrooms"].map((type) => (
                  <div key={type} className={styles.roomFiltersContent}>
                    <h4 className={styles.textType}>
                      {t(
                        `filterModal.rooms${
                          type.charAt(0).toUpperCase() + type.slice(1)
                        }`
                      )}
                    </h4>
                    <div className={styles.roomButtons}>
                      {["Any", "1", "2", "3", "4", "5+"].map((value) => (
                        <button
                          key={value}
                          className={`${styles.roomButton} ${
                            value === "Any"
                              ? styles.roomButtonAny
                              : value === "5+"
                              ? styles.roomButton5Plus
                              : ""
                          } ${
                            (type === "bedrooms" &&
                              selectedBedrooms === value) ||
                            (type === "beds" && selectedBeds === value) ||
                            (type === "bathrooms" &&
                              selectedBathrooms === value)
                              ? styles.activeRoomButton
                              : ""
                          }`}
                          onClick={() =>
                            handleRoomSelection(
                              type as "bedrooms" | "beds" | "bathrooms",
                              value
                            )
                          }
                        >
                          {value === "Any"
                            ? t("filterModal.roomsOptionAny")
                            : value}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.separator}></div>
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.applyButton}
              onClick={handleApply}
              disabled={isDisableSearchButton}
            >
              {t("filterModal.applyButton", { count: totalOffers })}
            </button>
            <button className={styles.clearButton} onClick={handleClear}>
              {t("filterModal.clearButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
