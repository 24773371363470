import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./tripDetails.module.scss";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { QueryState } from "../../store/querySlice";
import { useSelector } from "react-redux";
import { parseDate } from "../../utils/helpers";
import { enUS, ru } from "date-fns/locale";
import { TwoCalendarsRangePicker } from "../twoCalendarsRangePicker/twoCalendarsRangePicker";
import { MobileCalendar } from "../mobileCalendar/mobileCalendar";
import GuestModal from "../guestModal/guestModal";
import { Booking } from "../../types/booking";

interface ITripDetails {
  isDesktop: boolean;
  availableDatesSet: Set<string>;
  booking: Booking;
}

const TripDetails: FC<ITripDetails> = ({
  isDesktop,
  availableDatesSet,
  booking,
}) => {
  const { t, i18n } = useTranslation();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const checkInDate = state.checkIn ? parseDate(state.checkIn) : undefined;
  const checkOutDate = state.checkOut ? parseDate(state.checkOut) : undefined;
  const totalGuests = state.guestCount.adults + state.guestCount.children;
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const guestsRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
  const handleClose = useCallback(() => {
    setIsModalOpen(null);
  }, []);

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;

    if (
      (isModalOpen === "calendar" &&
        calendarRef.current &&
        !calendarRef.current.contains(target)) ||
      (isModalOpen === "guests" &&
        guestsRef.current &&
        !guestsRef.current.contains(target))
    ) {
      setIsModalOpen(null);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className={styles.section}>
      <h3 className={styles.title}>{t("tripDetails.yourTrip")}</h3>
      <div className={styles.option}>
        <div className={styles.dataBlock}>
          <p className={styles.textLabel}>{t("tripDetails.dates")}</p>
          <p className={styles.valueOption}>
            {checkInDate && checkOutDate
              ? `${format(checkInDate, "d MMM", {
                  locale: currentLocale,
                })} - 
                     ${format(checkOutDate, "d MMM", {
                       locale: currentLocale,
                     })}`
              : ""}
          </p>
        </div>
        <button
          className={styles.buttonEdit}
          onClick={() => setIsModalOpen("calendar")}
        >
          {t("tripDetails.edit")}
        </button>
        {/* Date Picker */}
        {isModalOpen === "calendar" &&
          (isDesktop ? (
            <div
              className={styles["date-picker"]}
              ref={calendarRef}
              onClick={(e) => e.stopPropagation()}
            >
              <TwoCalendarsRangePicker
                currentLocale={currentLocale}
                allowAllDates={false}
                availableDatesSet={availableDatesSet}
              />
            </div>
          ) : (
            <MobileCalendar
              onClose={handleClose}
              allowAllDates={false}
              availableDatesSet={availableDatesSet}
            />
          ))}
      </div>
      <div className={styles.optionGuests}>
        <div className={styles.guestBlock}>
          <p className={styles.textLabel}>{t("tripDetails.Guests")}</p>
          <p className={styles.valueOption}>
            {t("tripDetails.guests", { count: totalGuests })}
          </p>
        </div>
        <button
          className={styles.buttonEdit}
          onClick={() => setIsModalOpen("guests")}
        >
          {t("tripDetails.edit")}
        </button>
        {isModalOpen === "guests" && (
          <div ref={guestsRef} className={styles.guestModal}>
            <GuestModal onClose={handleClose} maxGuests={booking.numGuests} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TripDetails;
