import { FC } from "react";
import styles from "./articleCard.module.scss";
import { useTranslation } from "react-i18next";
import authorIcon from "../../assets/images/authorIcon.png";
import { enUS, ru } from "date-fns/locale";
import { format } from "date-fns";
import articleImage from "../../assets/images/articleImage.png";
import articleImage2 from "../../assets/images/articleImage2.png";
import articleImage3 from "../../assets/images/imageArticle3.png";
import articleImage4 from "../../assets/images/imageArticle4.png";
import articleImage5 from "../../assets/images/imageArticle5.png";
import ArticleGallery from "../articleGallery/articleGallery";
import PollResults from "../pollResults/pollResults";
import ShareButtons from "../shareButtons/shareButtons";
import ArticleNavigation from "../articleNavigation/articleNavigation";

const ArticleCard: FC = () => {
  const { t, i18n } = useTranslation();
  const nameAuthor = "Nicholas DeRenzo";
  const dateArticle = new Date(2023, 11, 20);
  const timeMinutesToRead = 8;
  const currentLocale = i18n.language === "ru" ? ru : enUS;

  // Выберите нужный формат: для русского добавляем "г.", для английского – обычный формат
  const formatString =
    i18n.language === "ru" ? "d MMM yyyy 'г.'" : "d MMM yyyy";

  const formattedDate = format(dateArticle, formatString, {
    locale: currentLocale,
  });

  const options = [
    {
      id: "1",
      text: {
        ru: "Да",
        en: "Yes",
      },
      percentage: 68,
    },
    {
      id: "2",
      text: {
        ru: "Нет",
        en: "No",
      },
      percentage: 9,
    },
    {
      id: "3",
      text: {
        ru: "Не знаю/затрудняюсь ответить",
        en: "I don't know/find it difficult to answer",
      },
      percentage: 23,
    },
  ];

  const images = [
    {
      id: "1",
      src: articleImage,
      alt: "Сан-Валли, Айдахо",
      caption:
        "Горнолыжные склоны в Сан-Валли, штат Айдахо. Изображение: Lynnie Flynnie/Tripadvisor",
    },
    {
      id: "2",
      src: articleImage,
      alt: "Другое место",
      caption: "Описание другого изображения",
    },
  ];

  const images2 = [
    {
      id: "1",
      src: articleImage4,
      alt: "Сан-Валли, Айдахо",
      caption:
        "Горнолыжные склоны в Сан-Валли, штат Айдахо. Изображение: Lynnie Flynnie/Tripadvisor",
    },
    {
      id: "2",
      src: articleImage4,
      alt: "Другое место",
      caption: "Описание другого изображения",
    },
  ];

  const previousArticle = {
    id: "flora-express",
    title: {
      ru: "Flora Express создает и доставляет авторские букеты и цветочные композиции",
      en: "Flora Express creates and delivers custom flower arrangements",
    },
  };

  const nextArticle = {
    id: "xiaomi-bootloader",
    title: {
      ru: "Разблокировка загрузчика смартфона Xiaomi Redmi 4 серии",
      en: "Unlocking the bootloader of Xiaomi Redmi 4 series",
    },
  };

  return (
    <article className={styles.content}>
      <h2 className={styles.titleArticle}>{t("articleCard.title")}</h2>
      <h4 className={styles.subtitle}>{t("articleCard.subTitle")}</h4>
      <div className={styles.authorBlock}>
        <img className={styles.authorIcon} src={authorIcon} alt="author icon" />
        <p className={styles.authorDetailsText}>
          {t("articleCard.author")} {nameAuthor} {formattedDate}{" "}
          {t("articleCard.timeToRead", { count: timeMinutesToRead })}
        </p>
      </div>
      <ArticleGallery images={images} />
      <section className={styles.section}>
        <h4 className={styles.subtitle}>{t("articleCard.updatedOn")}</h4>
        <p className={styles.paragraphText}>
          {t("articleCard.ifYouStick")}
          <span className={styles.underlinText}>
            {t("articleCard.FortunatelyFebruary")}
          </span>
          {t("articleCard.withoutCrowds")}
          <span className={styles.underlinText}>
            {t("articleCard.northernLights")}
          </span>
          {t("articleCard.remoteCities")}
        </p>
        <p className={`${styles.paragraphText} ${styles["mb-0"]}`}>
          {t("articleCard.becomePartner")}
        </p>
      </section>
      <section className={styles.section}>
        <h3 className={styles.title}>{t("articleCard.LimaPeru")}</h3>
        <h4 className={styles.subtitle}>
          {t("articleCard.AdventurersGourmets")}
        </h4>
        <p className={styles.paragraphText}>
          {t("articleCard.AverageTemperature")}
        </p>
        <p className={styles.paragraphText}>
          {t("articleCard.InFebruaryResidents")}
          <span className={styles.underlinText}>{t("articleCard.Maita")}</span>
          {t("articleCard.tastingMenu")}
          <span className={styles.underlinText}>{t("articleCard.Maido")}</span>
          {t("articleCard.specializing")}
          <span className={styles.underlinText}>{t("articleCard.nikkei")}</span>
          {t("articleCard.representingCombination")}
        </p>
        <div className={styles.imageTextBlock}>
          <img src={articleImage2} alt="image2" />
          <p className={`${styles.paragraphText} ${styles["mb-0"]}`}>
            {t("articleCard.takingAdvantage")}
          </p>
        </div>
        <p className={styles.paragraphText}>
          {t("articleCard.beSure")}
          <span className={styles.underlinText}>
            {t("articleCard.Central")}
          </span>
          {t("articleCard.whichTopped")}
          <span className={styles.underlinText}>
            {t("articleCard.LimaMuseum")}
          </span>
          {t("articleCard.museum")}
          <span className={styles.underlinText}>{t("articleCard.larco")}</span>
          {t("articleCard.whichOccupies")}
        </p>
        <ul className={styles.options}>
          <li className={styles.option}>{t("articleCard.lookForExcuses")}</li>
          <li className={styles.option}>{t("articleCard.youResponsible")}</li>
          <li className={styles.option}>
            {t("articleCard.prepareForDelivery")}
          </li>
          <li className={styles.option}>{t("articleCard.joyToPeople")}</li>
          <li className={styles.option}>{t("articleCard.alwaysInTouch")}</li>
        </ul>
        <img
          src={articleImage3}
          alt="image3"
          className={styles.imageInSection}
        />
        <p className={styles.paragraphText}>
          {t("articleCard.beSure")}
          <span className={styles.underlinText}>
            {t("articleCard.Central")}
          </span>
          {t("articleCard.whichTopped")}
          <span className={styles.underlinText}>
            {t("articleCard.LimaMuseum")}
          </span>
          {t("articleCard.museum")}
          <span className={styles.underlinText}>{t("articleCard.larco")}</span>
          {t("articleCard.whichOccupies")}
        </p>
      </section>
      <section className={styles.section}>
        <div className={styles.twoBlocks}>
          <h3 className={`${styles.title} ${styles.widthTwoBlocks}`}>
            {t("articleCard.FloraExpress")}
          </h3>
          <p className={`${styles.paragraphText} ${styles.widthTwoBlocks}`}>
            {t("articleCard.FreshFlowers")}
          </p>
        </div>
        <div className={styles.border}></div>
      </section>
      <section className={styles.section}>
        <h3 className={styles.title}>{t("articleCard.LimaPeru")}</h3>
        <p className={styles.paragraphText}>
          {t("articleCard.beSure")}
          <span className={styles.underlinText}>
            {t("articleCard.Central")}
          </span>
          {t("articleCard.whichTopped")}
          <span className={styles.underlinText}>
            {t("articleCard.LimaMuseum")}
          </span>
          {t("articleCard.museum")}
          <span className={styles.underlinText}>{t("articleCard.larco")}</span>
          {t("articleCard.whichOccupies")}
        </p>
      </section>
      <section className={`${styles.section} ${styles["mb-0"]}`}>
        <ArticleGallery images={images2} isSmallSize={true} />
      </section>
      <section className={styles.section}>
        <h3 className={styles.title}>{t("articleCard.LimaPeru")}</h3>
        <p className={styles.paragraphText}>
          {t("articleCard.beSure")}
          <span className={styles.underlinText}>
            {t("articleCard.Central")}
          </span>
          {t("articleCard.whichTopped")}
          <span className={styles.underlinText}>
            {t("articleCard.LimaMuseum")}
          </span>
          {t("articleCard.museum")}
          <span className={styles.underlinText}>{t("articleCard.larco")}</span>
          {t("articleCard.whichOccupies")}
        </p>
      </section>
      <div className={styles.blockForImage}>
        <img src={articleImage5} alt="image5" className={styles.imageBig} />
        <div className={styles.blockTextAbsolute}>
          <h5 className={styles.titleAbsoluteText}>
            {t("articleCard.TodosSantos")}
          </h5>
          <p
            className={`${styles.paragraphText} ${styles["mb-0"]} ${styles.maxWidth316}`}
          >
            {t("articleCard.ForThose")}
          </p>
        </div>
      </div>
      <section className={styles.section}>
        <h3 className={styles.title}>{t("articleCard.LimaPeru")}</h3>
        <p className={styles.paragraphText}>
          {t("articleCard.beSure")}
          <span className={styles.underlinText}>
            {t("articleCard.Central")}
          </span>
          {t("articleCard.whichTopped")}
          <span className={styles.underlinText}>
            {t("articleCard.LimaMuseum")}
          </span>
          {t("articleCard.museum")}
          <span className={styles.underlinText}>{t("articleCard.larco")}</span>
          {t("articleCard.whichOccupies")}
        </p>
      </section>
      <PollResults
        votedCount={226}
        question={{
          ru: "Как узнать, разблокирован загрузчик Xiaomi или нет?",
          en: "How do I find out if the Xiaomi bootloader is unlocked or not?",
        }}
        options={options}
        pollId="xiaomi-bootloader"
      />
      <ShareButtons />
      <ArticleNavigation previous={previousArticle} next={nextArticle} />
    </article>
  );
};
export default ArticleCard;
