import { FC, useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import {
  buildSearchQueryString,
  calculateNights,
  parseDate,
} from "../../utils/helpers";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iconButtonGoBack from "../../assets/images/chevron-left.png";
import styles from "./bookingObjectPage.module.scss";
import { useQueryParams } from "../../hooks/useQueryParams";
import ShareFavoriteBar from "../../components/shareFavoriteBar/shareFavoriteBar";
import { Booking } from "../../types/booking";
import { checkAvailability, fetchSearchResults } from "../../api/api";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { CURRENCYSYMBOL } from "../../utils/const";
import selectedIcon from "../../assets/images/radiobutton.png";
import visaIcon from "../../assets/images/icons-payment-systems/visa.png";
import masterIcon from "../../assets/images/icons-payment-systems/master.png";
import americanExpressIcon from "../../assets/images/icons-payment-systems/american express.png";
import applePayIcon from "../../assets/images/icons-payment-systems/apply pay logo.png";
import googlePayIcon from "../../assets/images/icons-payment-systems/google pay.png";
import payPalIcon from "../../assets/images/icons-payment-systems/payPal.png";
import dropDownIcon from "../../assets/images/arrow_down.png";
import avatarIcon from "../../assets/images/host.png";
import Footer from "../../components/footer/footer";
import PhotoGallery from "../../components/photoGallery/photoGallery";
import DropDownPaymentMethods from "../../components/dropDownPaymentMethods/dropDownPaymentMethods";
import { IPaymentMethod } from "../../types/paymentMethod";
import BookingSidebar from "../../components/bookingSidebar/bookingSidebar";
import TripDetails from "../../components/tripDetails/tripDetails";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const BookingObjectPage: FC = () => {
  const { queryParams } = useQueryParams();
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const discount = 0; // TODO потом будет с бэка приходить
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const state = useSelector((state: { query: QueryState }) => state.query);
  const [booking, setBooking] = useState<Booking | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [shouldSearch, setShouldSearch] = useState(false);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const isDesktop = window.innerWidth >= 1440;
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const partPayment = 480.9; //TODO потом будет приходить с бэка
  const cancelBeforeDate = format(new Date(2024, 2, 25), "MMM d", {
    locale: currentLocale,
  }); //TODO потом будет приходить с бэка
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([
    {
      type: "mastercard",
      name: "Mastercard",
      icon: masterIcon,
      cards: [
        { id: "1", last4: "7305" },
        { id: "2", last4: "5632" },
      ],
    },
    {
      type: "visa",
      name: "Visa",
      icon: visaIcon,
      cards: [{ id: "3", last4: "4236" }],
    },
    {
      type: "amex",
      name: "Amex",
      icon: americanExpressIcon,
      cards: [],
    },
    {
      type: "applePay",
      name: "Apple Pay",
      icon: applePayIcon,
      added: true, // Маркер, что этот способ добавлен пользователем
    },
    {
      type: "googlePay",
      name: "Google Pay",
      icon: googlePayIcon,
      added: false, // Этот способ еще не добавлен
    },
    {
      type: "paypal",
      name: "PayPal",
      icon: payPalIcon,
      added: true, // Этот способ уже добавлен пользователем
    },
  ]);

  // Оставляем только методы с картами или "добавленные" платежные сервисы
  const addedPaymentMethods = paymentMethods.filter(
    (method) => (method.cards && method.cards.length > 0) || method.added
  );

  const availableMethodsToAdd = paymentMethods.filter(
    (method) => (!method.cards || method.cards.length === 0) && !method.added
  );

  const [selectedPayment, setSelectedPayment] = useState(paymentMethods[1]); // По умолчанию Mastercard
  const [selectedCard, setSelectedCard] = useState(
    paymentMethods[1]?.cards && paymentMethods[1].cards.length > 0
      ? paymentMethods[1].cards[0]
      : null
  );
  // По умолчанию первая карта
  const [nights, setNights] = useState(0);
  const [pricePerNight, setPricePerNight] = useState(0);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const addPaymentMethod = (methodType: string) => {
    setPaymentMethods((prevMethods) => {
      return prevMethods.map((method) => {
        if (method.type === methodType) {
          if (method.cards) {
            // Если это карточный метод (Visa, Mastercard, Amex), добавляем новую карту
            return {
              ...method,
              cards: [
                ...method.cards,
                { id: Date.now().toString(), last4: "0000" },
              ],
            };
          } else {
            // Если это PayPal, Google Pay или Apple Pay — просто меняем флаг
            return { ...method, added: true };
          }
        }
        return method;
      });
    });
  };

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;

    if (
      isModalOpen === "dropDown" &&
      dropDownRef.current &&
      !dropDownRef.current.contains(target)
    ) {
      setIsModalOpen(null);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setNights(
      calculateNights(parseDate(state.checkIn), parseDate(state.checkOut))
    );
  }, [state.checkIn, state.checkOut]);

  useEffect(() => {
    if (booking?.rentalCost && nights > 0) {
      setPricePerNight(parseFloat((booking?.rentalCost / nights).toFixed(2)));
    } else {
      setPricePerNight(0);
    }
  }, [booking?.rentalCost, nights]);

  const formattedPartPaymentDate = format(
    new Date(2024, 2, 17),
    "MMM d, yyyy",
    { locale: currentLocale }
  ); //TODO потом будет приходить с бэка
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    "full" | "part"
  >("full");

  const handlePaymentSelection = (method: "full" | "part") => {
    setSelectedPaymentMethod(method);
  };

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  const fetchBookingData = async () => {
    try {
      const response = await fetchSearchResults({
        from: state.checkIn,
        nights: nights.toString(),
        id,
      });
      if (response["hydra:member"].length > 0) {
        const bookingObject = response["hydra:member"][0];

        setBooking({
          ...bookingObject,
          location: {
            latitude: bookingObject.geoPoint.lat,
            longitude: bookingObject.geoPoint.lon,
          },
          id,
        });
      } else {
        console.warn("Не найдено данных для бронирования");
        setBooking(null);
      }
    } catch (error) {
      console.error("Error fetching booking:", error);
    }
  };

  const fetchAvailabilityData = async () => {
    try {
      const response = await checkAvailability(id!);
      const dates = response.data["hydra:member"];
      if (Array.isArray(dates)) {
        setAvailableDates(dates);
      } else {
        setAvailableDates([]);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  useEffect(() => {
    if (id && nights > 0) {
      fetchBookingData();
      fetchAvailabilityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, state.checkIn, state.checkOut, nights]);

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const dispatch = useDispatch();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  if (!booking) return <div>{t("bookingObjectPage.loading")}</div>;

  return (
    <div className={styles.page}>
      <Header
        location={location}
        onLocationChange={handleLocationChange}
        availableDates={availableDates}
        setShouldSearch={setShouldSearch}
        handleSearch={handleSearch}
        maxGuests={booking.numGuests}
        locationSuggestions={locationSuggestions}
        onSelectSuggestion={handleSelectSuggestion}
      />
      <div className={`${styles.top}`}>
        <button className={styles.buttonGoBack} onClick={() => navigate(-1)}>
          <img
            src={iconButtonGoBack}
            alt="icon button go back"
            className={styles.iconButtonGoBack}
          />
          {isDesktop && (
            <p className={styles.textInButtonGoBack}>
              {t("bookingObjectPage.goBack")}
            </p>
          )}
        </button>
        {!isDesktop && (
          <h2 className={styles.titleTop}> {t("bookingObjectPage.booking")}</h2>
        )}
        {!isDesktop && <div className={styles.emptyBlock}></div>}
      </div>
      <section className={styles.section}>
        {isDesktop && (
          <ShareFavoriteBar
            bookingId={booking.id}
            title={t("bookingObjectPage.requestBook")}
          />
        )}
        <div className={styles.content}>
          <div className={styles.leftContent}>
            <TripDetails
              isDesktop={isDesktop}
              availableDatesSet={availableDatesSet}
              booking={booking}
            />
            <div className={styles.chooseHowPayBlock}>
              <h3 className={styles.titleChooseHowPay}>
                {t("bookingObjectPage.howPay")}
              </h3>
              <div className={styles.radioButtons}>
                <div className={styles.radioButtonBLock}>
                  <div className={styles.textsInRadioButton}>
                    <p className={styles.textTopInRadioButton}>
                      {t("bookingObjectPage.payInFull")}
                    </p>
                    <p className={styles.textBottomInRadioButton}>
                      {t("bookingObjectPage.payTheTotal")}
                      {`(${CURRENCYSYMBOL}${
                        booking.rentalCost ? booking.rentalCost : ""
                      }).`}
                    </p>
                  </div>
                  <button
                    className={`${styles.radioButton} ${
                      selectedPaymentMethod === "full" ? styles.active : ""
                    }`}
                    onClick={() => handlePaymentSelection("full")}
                  >
                    {selectedPaymentMethod === "full" && (
                      <img
                        className={styles.iconSelected}
                        src={selectedIcon}
                        alt="Selected"
                      />
                    )}
                  </button>
                </div>
                <div className={styles.radioButtonBLock}>
                  <div className={styles.textsInRadioButton}>
                    <p className={styles.textTopInRadioButton}>
                      {t("bookingObjectPage.payPart")}
                    </p>
                    <p className={styles.textBottomInRadioButton}>
                      {`${CURRENCYSYMBOL}${partPayment} `}
                      {t("bookingObjectPage.dueToday")},
                    </p>
                    <p className={styles.textBottomInRadioButton}>
                      {`${CURRENCYSYMBOL}${partPayment} `}
                      {t("bookingObjectPage.on")} {formattedPartPaymentDate}.
                    </p>
                    <p className={styles.textBottomInRadioButton}>
                      {t("bookingObjectPage.noExtraFees")}.
                    </p>
                    <button className={styles.buttonShowMore}>
                      {t("bookingObjectPage.showMore")}
                    </button>
                  </div>
                  <button
                    className={`${styles.radioButton} ${
                      selectedPaymentMethod === "part" ? styles.active : ""
                    }`}
                    onClick={() => handlePaymentSelection("part")}
                  >
                    {selectedPaymentMethod === "part" && (
                      <img
                        className={styles.iconSelected}
                        src={selectedIcon}
                        alt="Selected"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.howPayBlock}>
              <div className={styles.topHowPay}>
                <h3 className={styles.titleHowPay}>
                  {t("bookingObjectPage.howToPay")}
                </h3>
                <div className={styles.systemsCardsBlock}>
                  <img
                    src={visaIcon}
                    alt="visa icon"
                    className={styles.visaIcon}
                  />
                  <img
                    src={masterIcon}
                    alt="master icon"
                    className={styles.masterIcon}
                  />
                  <img
                    src={americanExpressIcon}
                    alt="american express icon"
                    className={styles.americanExpressIcon}
                  />
                  <img
                    src={applePayIcon}
                    alt="apple pay icon"
                    className={styles.applePayIcon}
                  />
                  <img
                    src={googlePayIcon}
                    alt="google pay icon"
                    className={styles.googlePayIcon}
                  />
                  <img
                    src={payPalIcon}
                    alt="pay pal icon"
                    className={styles.payPalIcon}
                  />
                </div>
              </div>
              <div
                className={styles.bottomHowPayBlock}
                ref={dropDownRef}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isModalOpen === "dropDown") {
                    setIsModalOpen(null);
                  } else {
                    setIsModalOpen("dropDown");
                  }
                }}
              >
                <div className={styles.logoPaymant}>
                  <img
                    src={selectedPayment.icon}
                    alt="Selected Payment System"
                    className={styles.iconLogoPayment}
                  />
                </div>
                {selectedPayment.cards && (
                  <input
                    type="text"
                    className={styles.dataCard}
                    value={`•••• ${selectedCard?.last4}`}
                    readOnly
                  />
                )}
                <img
                  className={styles.dropDownIcon}
                  alt="dropdown icon"
                  src={dropDownIcon}
                />

                {/* Выпадающий список */}
                {isModalOpen === "dropDown" && (
                  <DropDownPaymentMethods
                    addPaymentMethod={addPaymentMethod}
                    addedPaymentMethods={addedPaymentMethods}
                    selectedCard={selectedCard}
                    setSelectedPayment={setSelectedPayment}
                    setSelectedCard={setSelectedCard}
                    setIsModalOpen={setIsModalOpen}
                    selectedPayment={selectedPayment}
                    availableMethodsToAdd={availableMethodsToAdd}
                  />
                )}
              </div>
            </div>
            <div className={styles.tripBlock}>
              <h3 className={styles.tripTitle}>
                {t("bookingObjectPage.forYourTrip")}
              </h3>
              <p className={styles.titleMessageHost}>
                {t("bookingObjectPage.messageHost")}
              </p>
              <p className={styles.textMessage}>
                {t("bookingObjectPage.textMessage")}
              </p>
              <div className={styles.hostBlock}>
                <img
                  className={styles.avatar}
                  src={avatarIcon}
                  alt="avatar icon"
                />
                <div className={styles.hostDetails}>
                  <p className={styles.nameHost}>Ann</p>
                  <p className={styles.textDate}>
                    {t("bookingObjectPage.joinedIn", { date: 2019 })}
                  </p>
                </div>
              </div>
              <div className={styles.tapMessage}>
                {t("bookingObjectPage.tapMessage")}
              </div>
            </div>
            <div className={styles.cancelBLock}>
              <div className={styles.contentCancelBlock}>
                <h3 className={styles.titleCancel}>
                  {t("bookingObjectPage.cancelPolicy")}
                </h3>
                <p className={styles.subtitleCancel}>
                  {t("bookingObjectPage.freeCancel")}
                </p>
                <p className={styles.cancelBeforeText}>
                  {t("bookingObjectPage.cancelBefore", {
                    date: cancelBeforeDate,
                  })}{" "}
                  {t("bookingObjectPage.partialRefund")}
                </p>
                <button className={styles.buttonCancel}>
                  {t("bookingObjectPage.learnMore")}
                </button>
              </div>
            </div>
            <div className={styles.groundBlock}>
              <h3 className={styles.titleGround}>
                {t("bookingObjectPage.groundRules")}
              </h3>
              <p className={styles.textGround}>
                {t("bookingObjectPage.weAsk")}
              </p>
              <ul className={styles.ulGround}>
                <li className={styles.textInGround}>
                  {t("bookingObjectPage.follow")}
                </li>
                <li className={styles.textInGround}>
                  {t("bookingObjectPage.treat")}
                </li>
              </ul>
            </div>
            <div className={styles.reservationBlock}>
              {t("bookingObjectPage.reservationMessage")}
            </div>
            <div className={styles.bookAndPayBlock}>
              <p className={styles.bookAndPayText}>
                {t("bookingObjectPage.payText1")}
              </p>
              <p className={styles.bookAndPayText}>
                {t("bookingObjectPage.payText2")}
              </p>
              <button className={styles.payButton}>
                {t("bookingObjectPage.payButton")}
              </button>
            </div>
          </div>
          <BookingSidebar
            booking={booking}
            discount={discount}
            nights={nights}
            pricePerNight={pricePerNight}
            isDesktop={isDesktop}
            setIsGalleryOpen={setIsGalleryOpen}
          />
        </div>
      </section>
      <Footer booking={booking} />
      {/* Модальная фотогалерея */}
      {isGalleryOpen && (
        <PhotoGallery
          photos={booking.objectPhotos}
          onClose={handleCloseGallery}
        />
      )}
    </div>
  );
};
export default BookingObjectPage;
