export enum InfrastructureType {
    BUS_STOP = "busStop",
    SCHOOL = "school",
    RESTAURANT = "restaurant",
    UNIVERSITY = "university",
    BANK = "bank",
    LIBRARY = "library",
    SHOP = "shop",
    SPORTS = "sports",
    PARKING = "parking",
    PHARMACY = "pharmacy",
    GAS_STATION = "gasStation",
    HOSPITAL = "hospital",
  }
  
  export interface InfrastructureItem {
    type: InfrastructureType;
    distance: number; // В метрах
  }
  
  