import { FC, useState } from "react";
import FooterBottom from "../../components/footerBottom/footerBottom";
import styles from "./mainPage.module.scss";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import SearchBlock from "../../components/searchBlock/searchBlock";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
import { buildSearchQueryString } from "../../utils/helpers";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const MainPage: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [shouldSearch, setShouldSearch] = useState(false);
  // Состояние для определения ввода лоакации - ручной или автоматический
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);

  // Состояние для автоподсказок
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  // Анимационные строки
  const phrases = [t("mainPage.homeForWork"), t("mainPage.hofowo")];

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location, {
      includeKinds: true,
    });
    navigate(`/search?${queryString}`);
  };

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };
  const isFreeRowsInTitle = window.innerWidth <= 1180;
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <Header
            location={location}
            onLocationChange={handleLocationChange}
            availableDates={availableDates}
            isMainPage={true}
            setShouldSearch={setShouldSearch}
            handleSearch={handleSearch}
          />
          <h1 className={styles.title}>
            {isFreeRowsInTitle ? (
              <>
                <span>{t("mainPage.titleOnePart")}</span>
                <span>{t("mainPage.titleTwoPart")}</span>
              </>
            ) : (
              <span>{t("mainPage.title")}</span>
            )}
            <div className={styles.typewriter}>
              <Typewriter
                key={i18n.language}
                words={phrases}
                loop={1} // Анимация запускается один раз
                typeSpeed={100} // Скорость печати
                deleteSpeed={50} // Скорость удаления
                delaySpeed={1500} // Задержка перед переключением фразы
              />
            </div>
          </h1>
          <div className={styles.searchContent}>
            <SearchBlock
              handleSearch={handleSearch}
              location={location}
              onLocationChange={handleLocationChange}
              availableDates={availableDates}
              allowAllDates={true}
              locationSuggestions={locationSuggestions}
              onSelectSuggestion={handleSelectSuggestion}
            />
            <p className={styles.travelText}>{t("mainPage.travelText")}</p>
          </div>
        </div>
        <FooterBottom isMainPage={true} />
      </div>
    </div>
  );
};

export default MainPage;
