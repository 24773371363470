import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Booking } from "../../types/booking";
import LeftContent from "../../components/leftContent/leftContent";
import RightStickyContent from "../../components/rightStickyContent/rightStickyContent";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { checkAvailability, fetchBooking, getFullPrice } from "../../api/api";
import styles from "./bookingDetailsPage.module.scss";
import Reviews from "../../components/reviews/reviews";
import Map from "../../components/map/map";
import { Review } from "../../types/review";
import derekAvatar from "../../assets/images/derek.png";
import tomAvatar from "../../assets/images/tom.png";
import lizaAvatar from "../../assets/images/liza.png";
import HostDetails from "../../components/hostDetails/hostDetails";
import iconAnn from "../../assets/images/ann.png";
import { useTranslation } from "react-i18next";
import {
  buildSearchQueryString,
  calculateNights,
  parseDate,
} from "../../utils/helpers";
import StickyBanner from "../../components/stickyBanner/stickyBanner";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import useStickyScroll from "../../hooks/useStickyScroll";
import ShareFavoriteBar from "../../components/shareFavoriteBar/shareFavoriteBar";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const reviews: Review[] = [
  // TODO в юудущем эти данные будут приходить с бэкенда
  {
    name: "Derek",
    avatar: derekAvatar,
    rating: 4.5,
    date: "September 2023",
    mobileDate: "Sept 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Tom",
    avatar: tomAvatar,
    rating: 4.5,
    date: "September 2023",
    mobileDate: "Sept 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Liza",
    avatar: lizaAvatar,
    rating: 4.5,
    date: "September 2023",
    mobileDate: "Sept 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
];

const BookingDetailsPage: React.FC = () => {
  const { queryParams } = useQueryParams();
  const dispatch = useDispatch();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const [maxGuests, setMaxGuests] = useState(1);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [pricePerNight, setPricePerNight] = useState<number | undefined>(
    undefined
  );
  const isMobile = window.innerWidth <= 1439;
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const isStickyVisible = useStickyScroll();
  const [shouldSearch, setShouldSearch] = useState(false);
  // Состояние для определения ввода лоакации - ручной или автоматический
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const handleGuestLimitExceeded = () => {
    showNotification(t("bookingDetailsPage.guestLimitExceeded"), "warning");
  };

  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };

  const fetchBookingData = async () => {
    try {
      const response = await fetchBooking(id!);
      setBooking({
        ...response.data,
        location: {
          latitude: response.data.geoPoint.lat,
          longitude: response.data.geoPoint.lon,
        },
        id,
      });
      if (response.data.numGuests) {
        setMaxGuests(response.data.numGuests);
      }
    } catch (error) {
      console.error("Error fetching booking:", error);
    }
  };

  const fetchAvailabilityData = async () => {
    try {
      const response = await checkAvailability(id!);
      const dates = response.data["hydra:member"];
      if (Array.isArray(dates)) {
        setAvailableDates(dates);
      } else {
        setAvailableDates([]);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBookingData();
      fetchAvailabilityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchPriceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkIn, state.checkOut, state.guestCount, id]);

  const fetchPriceData = async () => {
    if (!state.checkIn || !state.checkOut) {
      setPrice(undefined);
      try {
        const data = await getFullPrice(
          id!,
          undefined,
          undefined,
          state.guestCount.adults + state.guestCount.children
        );

        if (data.data["hydra:member"]?.length > 0) {
          setPricePerNight(data.data["hydra:member"][0].pricePerNight);
        } else {
          setPricePerNight(undefined);
        }
      } catch {
        setPricePerNight(undefined);
      }
      return;
    }

    const nights = calculateNights(
      parseDate(state.checkIn),
      parseDate(state.checkOut)
    );
    const formattedDate = state.checkIn;

    try {
      const data = await getFullPrice(
        id!,
        formattedDate,
        nights,
        state.guestCount.adults + state.guestCount.children
      );

      if (data.data["hydra:member"]?.length > 0) {
        const rentalCost = data.data["hydra:member"][0].rentalCost;
        setPrice(rentalCost);
        setPricePerNight(parseFloat((rentalCost / nights).toFixed(2)));
      } else {
        setPrice(undefined);
        setPricePerNight(undefined);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
      setPrice(undefined);
      setPricePerNight(undefined);
    }
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const navigate = useNavigate();

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  if (!booking) return <div>{t("bookingDetailsPage.loading")}</div>;

  return (
    <div className={styles["booking-page"]}>
      <div
        className={
          !isMobile
            ? isStickyVisible
              ? ""
              : styles.hidden
            : styles.stickyBlock
        }
      >
        <Header
          availableDates={availableDates}
          location={location}
          onLocationChange={handleLocationChange}
          setShouldSearch={setShouldSearch}
          handleSearch={handleSearch}
          locationSuggestions={locationSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          maxGuests={maxGuests}
        />
      </div>
      {!isMobile && (
        <div className={!isStickyVisible ? "" : styles.hidden}>
          <StickyBanner id={id!} pricePerNight={pricePerNight} />
        </div>
      )}
      <div className={styles.mainBlock}>
        <ShareFavoriteBar title={booking.name} bookingId={booking.id} />
        <div className={styles["content"]}>
          <div className={styles["left"]}>
            <LeftContent booking={booking} />
          </div>
          <div className={styles["right"]}>
            <RightStickyContent
              availableDates={availableDates}
              pricePerNight={pricePerNight}
              price={price}
              maxGuests={maxGuests}
              id={id!}
              location={location}
            />
          </div>
        </div>
        <Reviews reviews={reviews} />
        <Map lat={booking.geoPoint.lat} lon={booking.geoPoint.lon} />
        {/* TODO в будущем данные должны приходить с бэкенда */}
        <HostDetails
          name="Ann"
          joinedDate="August 2019"
          verified={true}
          reviewsCount={63}
          bio="Good day! What is important to know about us? So this is what we are people. We try to make the life of our clients happier, and the rest in Phuket more comfortable. We care about the interests of our guests, help them in everything, advise."
          languages={["English", "Русский"]}
          responseRate="91%"
          responseTime="within an hour"
          avatarUrl={iconAnn}
        />
      </div>
      <Footer booking={booking} />
      {isMobile && <div className={styles.emptyBlock}></div>}
    </div>
  );
};

export default BookingDetailsPage;
