import React from "react";
import ReactSlider from "react-slider";
import styles from "./radiusModal.module.scss";
import { useTranslation } from "react-i18next";
import iconButtonCLose from "../../assets/images/x.png";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateRadius } from "../../store/querySlice";

interface RadiusModalProps {
  onClose: () => void;
}

const RadiusModal: React.FC<RadiusModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const radius = useSelector(
    (state: { query: QueryState }) => state.query.radius
  );
  const isMobile = window.innerWidth <= 1020;
  const screenWidth = window.innerWidth;
  const { t } = useTranslation();
  const step = 2.62; // размер смещения в пикселях при изменении радиуса на одну единицу

  const handleRadiusChange = (newRadius: number) => {
    dispatch(updateRadius(newRadius));
  };

  const calculatePosition = (
    radius: number,
    isMobile: boolean,
    screenWidth: number
  ) => {
    if (isMobile) {
      const trackWidth = screenWidth - 40; // Ширина экрана - 40px отступов (по 20px с каждой стороны)
      const mobileStep = trackWidth / 95; // Шаг для мобильного трека
      return ((radius - 5) * mobileStep + 20) / 2;
    }
    return (radius - 5) * step + 20; // Позиция для десктопа
  };

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <div className={styles.mobile}>
          <div className={styles.top}>
            <p className={styles.textTop}>{t("radiusModal.radius")}</p>
            <img
              src={iconButtonCLose}
              alt="icon button close"
              className={styles.buttonClose}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        <div
          className={styles.sliderWrapper}
          onClick={(e) => e.stopPropagation()}
        >
          <ReactSlider
            value={radius}
            min={5}
            max={100}
            step={1}
            onChange={(newValue) => handleRadiusChange(newValue)}
            className={styles.slider}
            thumbClassName={styles.thumb}
            trackClassName={styles.track}
            renderTrack={(props, state) => {
              // Позиционирование для левого и правого трека
              const { key, ...restProps } = props;
              const trackStyle =
                state.index === 0 ? styles.trackLeft : styles.trackRight;
              return (
                <div
                  key={key}
                  {...restProps}
                  className={`${styles.track} ${trackStyle}`}
                />
              );
            }}
          />
          <div
            className={styles.value}
            style={{
              left: `${calculatePosition(radius, isMobile, screenWidth)}px`,
              transform: isMobile ? "none" : "translateX(-50%)", // Без центрирования на мобильных
            }}
            /* Позиционируем значение относительно слайдера */
          >
            <span>
              {radius} {t("radiusModal.km")}
            </span>
          </div>
          <div className={styles.mobile}>
            <div className={styles.minMax}>
              <p className={styles.text}>5{t("radiusModal.km")}</p>
              <p className={styles.text}>100+{t("radiusModal.km")}</p>
            </div>
          </div>
        </div>
        <div className={styles.desktop}>
          <div className={styles.minMax}>
            <p className={styles.text}>5{t("radiusModal.km")}</p>
            <p className={styles.text}>100+{t("radiusModal.km")}</p>
          </div>
        </div>
        <div className={styles.mobile}>
          <div className={styles.bottom}>
            <button
              className={styles.buttonDone}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t("radiusModal.done")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadiusModal;
