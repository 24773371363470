import React from "react";
import styles from "./pagination.module.scss";
import chevronLeftIcon from "../../assets/images/icon-for-search/chevron-left.png";
import chevronRightIcon from "../../assets/images/icon-for-search/chevron-right.png";

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pagesToShow = 5;

  const generatePageNumbers = () => {
    if (totalPages <= pagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, "...", totalPages];
    }

    if (currentPage >= totalPages - 2) {
      return [
        1,
        "...",
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }

    return [
      1,
      "...",
      currentPage - 1,
      currentPage,
      currentPage + 1,
      "...",
      totalPages,
    ];
  };

  const pageNumbers = generatePageNumbers();

  const handlePrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  const handleEllipsisClick = (position: "left" | "right") => {
    if (position === "left") {
      onPageChange(Math.floor((currentPage + 1) / 2)); // Переход на середину между 1 и текущей
    } else {
      onPageChange(Math.ceil((currentPage + totalPages) / 2)); // Переход на середину между текущей и последней
    }
  };

  return (
    <div className={styles.pagination}>
      <button onClick={handlePrevious} disabled={currentPage === 1}>
        <img src={chevronLeftIcon} alt="left arrow icon" />
      </button>
      {pageNumbers.map((page, index) =>
        typeof page === "number" ? (
          <button
            key={index}
            className={currentPage === page ? styles.active : ""}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ) : (
          <button
            key={index}
            className={styles.ellipsis}
            onClick={() =>
              handleEllipsisClick(
                page === "..." && index === 1 ? "left" : "right"
              )
            }
          >
            ...
          </button>
        )
      )}
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        <img src={chevronRightIcon} alt="right arrow icon" />
      </button>
    </div>
  );
};

export default Pagination;
