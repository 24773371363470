import L from "leaflet";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import zoomInIcon from "../../assets/images/icon-for-search/plus.png";
import zoomOutIcon from "../../assets/images/icon-for-search/minus.png";
import locateIcon from "../../assets/images/icon-for-search/locate.png";
import { useNotification } from "../../context/notificationContext";

interface ICustomControlProps {
  position?: "left" | "right"; // Расположение
  desktopOffset?: { top: number; side: number }; // Отступы на десктопе
  mobileOffset?: { top: number; side: number }; // Отступы на мобилке
}

const CustomControl: FC<ICustomControlProps> = ({
  position = "right",
  desktopOffset = { top: 19, side: 24 },
  mobileOffset = { top: 9, side: 8 },
}) => {
  const map = useMap();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1020);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1020);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const controlContainer = L.Control.extend({
      onAdd: function () {
        const container = L.DomUtil.create("div", "custom-control-container");
        const { top, side } = isMobile ? mobileOffset : desktopOffset;
        Object.assign(container.style, {
          width: "40px",
          height: "120px",
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px",
          boxShadow: "0px 4px 4px 0px #00000040",
          position: "absolute",
          top: `${top}px`,
          [position]: `${side}px`,
          boxSizing: "border-box",
        });

        // Кнопка увеличения масштаба
        const zoomInButton = L.DomUtil.create("button", "", container);
        zoomInButton.style.width = "24px";
        zoomInButton.style.height = "24px";
        zoomInButton.style.backgroundImage = `url(${zoomInIcon})`;
        zoomInButton.style.backgroundSize = "contain";
        zoomInButton.style.backgroundColor = "transparent";
        zoomInButton.style.border = "none";
        zoomInButton.style.cursor = "pointer";
        zoomInButton.title = t("customControl.zoomIn");
        L.DomEvent.on(zoomInButton, "click", () => {
          map.zoomIn();
        });

        // Кнопка уменьшения масштаба
        const zoomOutButton = L.DomUtil.create("button", "", container);
        zoomOutButton.style.width = "24px";
        zoomOutButton.style.height = "24px";
        zoomOutButton.style.backgroundImage = `url(${zoomOutIcon})`;
        zoomOutButton.style.backgroundSize = "contain";
        zoomOutButton.style.backgroundColor = "transparent";
        zoomOutButton.style.border = "none";
        zoomOutButton.style.cursor = "pointer";
        zoomOutButton.title = t("customControl.zoomOut");
        L.DomEvent.on(zoomOutButton, "click", () => {
          map.zoomOut();
        });

        // Кнопка определения текущего местоположения
        const locateButton = L.DomUtil.create("button", "", container);
        locateButton.style.width = "24px";
        locateButton.style.height = "24px";
        locateButton.style.backgroundImage = `url(${locateIcon})`;
        locateButton.style.backgroundSize = "contain";
        locateButton.style.backgroundColor = "transparent";
        locateButton.style.border = "none";
        locateButton.style.cursor = "pointer";
        locateButton.title = t("customControl.locate");
        L.DomEvent.on(locateButton, "click", () => {
          if (!navigator.geolocation) {
            showNotification(
              t("customControl.geolocationNotSupported"),
              "warning"
            );
            return;
          }

          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              map.setView([latitude, longitude], 13);
            },
            () => {
              showNotification(t("customControl.locationError"), "warning");
            }
          );
        });

        return container;
      },
    });

    const controlInstance = new controlContainer({ position: position === "left" ? "topleft" : "topright" });

    map.addControl(controlInstance);

    return () => {
      map.removeControl(controlInstance);
    };
  }, [map, t]);

  return null;
};

export default CustomControl;
