import { FC } from "react";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import styles from "./suggestionsModal.module.scss";
import imageIcon from "../../assets/images/location-marker-blue.png";
import iconButtonCLose from "../../assets/images/x.png";
import { useTranslation } from "react-i18next";
import searchIcon from "../../assets/images/search.png";
import flagIcon from "../../assets/images/flag.png";
import { useIOSViewportFix } from "../../hooks/useIOSViewportFix";
import clearButtonIcon from "../../assets/images/clear-icon.png";

interface ISuggestionsModal {
  locationSuggestions: ISearchSuggestionItem[];
  onLocationChange: (value: string) => void;
  onSelectSuggestion: (suggestion: ISearchSuggestionItem) => void;
  onClose: () => void;
  location: string | undefined;
  isBottom?: boolean;
}

const SuggestionsModal: FC<ISuggestionsModal> = ({
  locationSuggestions,
  onSelectSuggestion,
  onClose,
  onLocationChange,
  location,
  isBottom = false,
}) => {
  const { t } = useTranslation();
  useIOSViewportFix();

  return (
    <div
      className={isBottom ? `${styles.modal} ${styles.isBottom}` : styles.modal}
      onPointerDown={(e) => e.stopPropagation()}
    >
      <div className={styles.content}>
        <div className={styles.mobile}>
          <div className={styles.top}>
            <p className={styles.textTop}>{t("suggestionsModal.direction")}</p>
            <img
              src={iconButtonCLose}
              alt="icon button close"
              className={styles.buttonClose}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        <div className={styles.mobile}>
          <div className={styles.searchBlock}>
            <img src={searchIcon} alt="search icon" />
            <input
              type="text"
              className={styles.textSearch}
              placeholder={t("suggestionsModal.searchText")}
              value={location}
              onChange={(e) => onLocationChange(e.target.value)}
            />
            {location && location.length > 2 ? (
              <img
                src={clearButtonIcon}
                alt="clear button icon"
                className={styles.clearButtonIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onLocationChange("");
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  Временно убрали пока <div className={styles.mobile}>
            <div className={styles.searchBlockWithFlag}>
              <img src={flagIcon} alt="flag icon" />
              <p className={styles.textSearchBlack}>
                {t("suggestionsModal.searchTextPlace")}
              </p>
            </div>
          </div> */}
        <div className={styles.filteredSuggestions}>
          {locationSuggestions.map((item) => (
            <div
              key={item["@id"]}
              className={styles.suggestionItem}
              onClick={() => onSelectSuggestion(item)}
            >
              <div className={styles.image}></div>
              <div className={styles.text}>{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuggestionsModal;
