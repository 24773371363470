import { FC, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./articleGallery.module.scss";
import arrowRight from "../../assets/images/chevron-right-blue.png";
import type { Swiper as SwiperType } from "swiper/types";
import arrowLeft from "../../assets/images/chevron-left.png";

interface IArticleGalleryProps {
  isSmallSize?: boolean,
  images: {
    id: string;
    src: string;
    alt: string;
    caption?: string;
  }[];
}

const ArticleGallery: FC<IArticleGalleryProps> = ({ images, isSmallSize }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    swiperRef.current?.slidePrev();
  };

  const handleNext = () => {
    swiperRef.current?.slideNext();
  };

  return (
    <div className={styles.galleryWrapper}>
      <Swiper
        onSlideChange={(swiper: SwiperType) =>
          setActiveIndex(swiper.activeIndex)
        }
        onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
        className={styles.swiper}
      >
        {images.map((img) => (
          <SwiperSlide key={img.id}>
            <img src={img.src} alt={img.alt} className={isSmallSize ? styles.imageSmall : styles.image} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.bottomControls}>
        <p className={styles.caption}>
          {images[activeIndex]?.caption ||
            "Изображение: Lynnie Flynnie/Tripadviso"}
        </p>
        <div className={styles.rightBottomControls}>
          <div className={styles.bullets}>
            {images.map((_, index) => (
              <span
                key={index}
                className={`${styles.bullet} ${
                  index === activeIndex ? styles.active : ""
                }`}
              />
            ))}
          </div>
          <div className={styles.arrows}>
            <button onClick={handlePrev} className={styles.arrowButton}>
              <img src={arrowLeft} alt="prev" />
            </button>
            <button onClick={handleNext} className={styles.arrowButton}>
              <img src={arrowRight} alt="next" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleGallery;
