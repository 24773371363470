import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import styles from "./header.module.scss";
import locationMarker from "../../assets/images/location-marker.png";
import calendarIcon from "../../assets/images/calendar.png";
import usersIcon from "../../assets/images/users.png";
import adjustmentIcon from "../../assets/images/adjustments.png";
import searchIcon from "../../assets/images/search_icon.png";
import searchIconMobile from "../../assets/images/searchIconMobile.png";
import userAvatar from "../../assets/images/avatar.png";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useNotification } from "../../context/notificationContext";
import { useNavigate } from "react-router-dom";
import logoIconWhite from "../../assets/images/logo/hofowo.png";
import logoIconBlue from "../../assets/images/logo/logoBlue.png";
import dropDownMenuIconForMainPage from "../../assets/images/icons-for-mainPage/menu-alt-4.png";
import dropDownMenuIcon from "../../assets/images/dropdownMenu.png";
import logoIconMobile from "../../assets/images/logo/logoIconMobile.png";
import logoMobile from "../../assets/images/logo/logoMobile.png";
import { useAuth } from "../../context/authContext";
import DropdownMenu from "../dropdownMenu/dropdownMenu";
import SuggestionsModal from "../suggestionsModal/suggestionsModal";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { TwoCalendarsRangePicker } from "../twoCalendarsRangePicker/twoCalendarsRangePicker";
import GuestModal from "../guestModal/guestModal";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import { ModalNames } from "../../utils/const";
import ModalManager from "../modalManager/modalManager";
import { useSelector } from "react-redux";
import { QueryState } from "../../store/querySlice";
import { parseDate } from "../../utils/helpers";
import clearButtonIcon from "../../assets/images/clear-icon.png";

interface IHeaderProps {
  handleSearch: () => void;
  location: string | undefined;
  onLocationChange: (value: string) => void;
  availableDates: string[];
  allowAllDates?: boolean;
  isMainPage?: boolean;
  locationSuggestions?: ISearchSuggestionItem[];
  onSelectSuggestion?: (suggestion: ISearchSuggestionItem) => void;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  maxGuests?: number | undefined;
}

const Header: React.FC<IHeaderProps> = ({
  handleSearch,
  isMainPage = false,
  location = "",
  onLocationChange,
  availableDates,
  allowAllDates = false,
  locationSuggestions = [],
  onSelectSuggestion = (suggestion: ISearchSuggestionItem) => {},
  setShouldSearch,
  maxGuests = undefined,
}) => {
  const state = useSelector((state: { query: QueryState }) => state.query);
  const checkInDate = state.checkIn ? parseDate(state.checkIn) : undefined;
  const checkOutDate = state.checkOut ? parseDate(state.checkOut) : undefined;
  const [isDisableSearchButton, setIsDisableSearchButton] = useState(true);

  useEffect(() => {
    if (state.lat && state.lon) {
      setIsDisableSearchButton(false);
    }
  }, [state.lat, state.lon]);

  const [openModalName, setOpenModalName] = useState<ModalNames | null>(null);
  const openModal = useCallback((modalName: ModalNames) => {
    setOpenModalName((prev) => (prev === modalName ? null : modalName));
  }, []);

  const closeModal = useCallback(() => setOpenModalName(null), []);
  const getUrlWithParams = usePreserveQueryParams();
  const { isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const datePickerRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const searchBlockRef = useRef<HTMLDivElement | null>(null);
  const guestsRefModal = useRef<HTMLDivElement | null>(null);
  const dropDownMenuRef = useRef<HTMLDivElement | null>(null);

  const handleSuggestionPointerDown = (event: React.PointerEvent) => {
    // Предотвращаем потерю фокуса
    event.preventDefault();
  };
  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    onSelectSuggestion(suggestion);
    setTimeout(() => {
      closeModal();
      if (!state.checkIn && !state.checkOut) {
        openModal(ModalNames.DatePicker);
      }
    }, 0);
  };

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;
    if (
      (openModalName === ModalNames.DatePicker &&
        datePickerRef.current &&
        !datePickerRef.current.contains(target)) ||
      (openModalName === ModalNames.Suggestions &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(target) &&
        !searchBlockRef.current?.contains(target)) ||
      (openModalName === ModalNames.Guest &&
        guestsRefModal.current &&
        !guestsRefModal.current.contains(target)) ||
      (openModalName === ModalNames.DropDownMenu &&
        dropDownMenuRef.current &&
        !dropDownMenuRef.current.contains(target))
    ) {
      closeModal();
    }
  };

  useEffect(() => {
    if (
      openModalName === ModalNames.DatePicker ||
      openModalName === ModalNames.Suggestions ||
      openModalName === ModalNames.Guest ||
      openModalName === ModalNames.DropDownMenu
    ) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalName]);

  const handleFocus = () => {
    openModal(ModalNames.Suggestions);
  };

  const handleSearchClick = () => {
    setShouldSearch(true);
  };

  return (
    <header
      className={
        isMainPage ? styles.header : `${styles.header} ${styles.sticky}`
      }
    >
      <div className={styles["header-content"]}>
        {/* Левая часть */}
        <picture
          onClick={() => navigate(getUrlWithParams("/"))}
          className={styles.picture}
        >
          <source
            srcSet={isMainPage ? logoIconMobile : logoMobile}
            media="(max-width: 1020px)"
          />
          <source
            srcSet={isMainPage ? logoIconWhite : logoIconBlue}
            media="(min-width: 1021px)"
          />
          <img
            src={isMainPage ? logoIconWhite : logoIconBlue} // Fallback для браузеров без поддержки <picture>
            alt="logo"
            className={isMainPage ? styles.logoGuestMainPage : styles.logoGuest}
          />
        </picture>
        {/* Средняя часть для мобильной версии не главной страницы */}
        {!isMainPage && (
          <div className={styles.mobile}>
            <div className={styles.searchBlockMobile}>
              <div
                className={styles.leftSearchBlock}
                ref={searchBlockRef}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(ModalNames.FilterMobile);
                }}
              >
                <img
                  className={styles.locationMarker}
                  src={locationMarker}
                  alt="location marker"
                />
                <p className={styles.textSearchBlock}>
                  {location ? location : t("header.direction")}
                </p>
              </div>
              <button
                onClick={handleSearchClick}
                className={styles.searchIconMobileButton}
                disabled={isDisableSearchButton}
              >
                <img
                  src={searchIconMobile}
                  alt="search icon"
                  className={styles.searchIconMobile}
                />
              </button>
            </div>
          </div>
        )}
        {/* Средняя часть для десктопной версии не главной страницы */}
        {!isMainPage && (
          <div className={styles.desktop}>
            <div className={styles.navigation}>
              <div
                className={
                  locationSuggestions.length > 0 &&
                  openModalName === ModalNames.Suggestions
                    ? `${styles["location-tag"]} ${styles.activeElement}`
                    : styles["location-tag"]
                }
              >
                <img
                  className={styles.locationMarker}
                  src={locationMarker}
                  alt="location marker"
                />
                <input
                  type="text"
                  value={location}
                  onChange={(e) => onLocationChange(e.target.value)}
                  className={styles["location-input"]}
                  placeholder={t("header.locationPlaceholder")}
                  onFocus={handleFocus}
                />
                {location && location.length > 2 ? (
                  <img
                    src={clearButtonIcon}
                    alt="clear button icon"
                    className={styles.clearButtonIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      onLocationChange("");
                    }}
                  />
                ) : (
                  ""
                )}
                {locationSuggestions.length > 0 &&
                  openModalName === ModalNames.Suggestions && (
                    <div
                      ref={suggestionsRef}
                      onPointerDown={handleSuggestionPointerDown}
                    >
                      <SuggestionsModal
                        locationSuggestions={locationSuggestions}
                        onSelectSuggestion={handleSelectSuggestion}
                        onClose={closeModal}
                        location={location}
                        onLocationChange={onLocationChange}
                        isBottom={true}
                      />
                    </div>
                  )}
              </div>
              <div className={styles.separator}></div>
              <div
                ref={datePickerRef}
                className={
                  openModalName === ModalNames.DatePicker
                    ? `${styles["date-block"]} ${styles.activeElement}`
                    : styles["date-block"]
                }
                onClick={() => openModal(ModalNames.DatePicker)}
              >
                <img
                  src={calendarIcon}
                  alt="icon calendar"
                  className={styles["calendar-icon"]}
                />
                <span className={styles["calendar-text"]}>
                  {checkInDate && checkOutDate
                    ? `${format(checkInDate, "d MMM", {
                        locale: currentLocale,
                      })} - 
       ${format(checkOutDate, "d MMM", { locale: currentLocale })}`
                    : t("header.dates")}
                </span>
                {/* Date Picker */}
                {openModalName === ModalNames.DatePicker && (
                  <div
                    className={styles["date-picker"]}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <TwoCalendarsRangePicker
                      currentLocale={currentLocale}
                      allowAllDates={allowAllDates}
                      availableDatesSet={availableDatesSet}
                    />
                  </div>
                )}
              </div>
              <div className={styles.separator}></div>
              <div
                ref={guestsRefModal}
                className={
                  openModalName === ModalNames.Guest
                    ? `${styles["guests-block"]} ${styles.activeElement}`
                    : styles["guests-block"]
                }
                onClick={() => openModal(ModalNames.Guest)}
              >
                <img
                  src={usersIcon}
                  alt="icon guests"
                  className={styles["guests-icon"]}
                />
                <span className={styles["guests-qantity"]}>
                  {state.guestCount.adults + (state.guestCount.children || 0)}
                </span>
                {openModalName === ModalNames.Guest && (
                  <GuestModal
                    onClose={closeModal}
                    isBottom={true}
                    maxGuests={maxGuests}
                  />
                )}
              </div>
              <div className={styles.separator}></div>
              <div
                className={
                  openModalName === ModalNames.Filter
                    ? `${styles["adjustment-block"]} ${styles.activeElement}`
                    : styles["adjustment-block"]
                }
              >
                <img
                  src={adjustmentIcon}
                  alt="adjustments icon"
                  className={styles["adjustment-icon"]}
                  onClick={() => openModal(ModalNames.Filter)}
                />
              </div>
              <div className={styles.searchBlockMiddle}>
                <button
                  className={styles["search-icon-button"]}
                  onClick={handleSearchClick}
                  disabled={isDisableSearchButton}
                >
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className={styles["search-icon"]}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Правая часть */}
        <div className={styles["header-right"]}>
          <LanguageSwitcher isMainPage={isMainPage} />
          {isAuthenticated ? (
            <div
              className={styles["user-avatar"]}
              onClick={() => openModal(ModalNames.DropDownMenu)}
            >
              <img
                src={userAvatar}
                alt="User Avatar"
                className={styles["avatar-image"]}
              />
              <div ref={dropDownMenuRef}>
                <DropdownMenu
                  isOpen={openModalName === ModalNames.DropDownMenu}
                  onClose={closeModal}
                />
              </div>
            </div>
          ) : (
            <button
              className={
                isMainPage ? styles.buttonLoginMainPage : styles.buttonLogin
              }
              onClick={() => openModal(ModalNames.Login)}
            >
              {t("header.login")}
            </button>
          )}
        </div>
        <img
          className={styles.dropDownMenu}
          src={isMainPage ? dropDownMenuIconForMainPage : dropDownMenuIcon}
          alt={"icon menu"}
          onClick={() => openModal(ModalNames.BurgerMenu)}
        />
      </div>
      <ModalManager
        openModalName={openModalName}
        closeModal={closeModal}
        openModal={openModal}
        props={{
          setShouldSearch,
          location,
          onLocationChange,
          onSelectSuggestion,
          locationSuggestions,
          availableDates,
          allowAllDates,
          handleSearch,
        }}
      />
    </header>
  );
};

export default Header;
