import React, { useEffect, useState } from "react";
import styles from "./card.module.scss";
import { useTranslation } from "react-i18next";
import { Booking } from "../../types/booking";
import heartIcon from "../../assets/images/icon-for-search/heart.png";
import starIcon from "../../assets/images/icon-for-search/star.png";
import { useNavigate } from "react-router-dom";
import BookingDetailsModal from "../bookingDetailsModal/bookingDeatailModal";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import { CURRENCYSYMBOL } from "../../utils/const";
import {
  addToFavorites,
  calculateNights,
  formatPrice,
  getImageUrl,
  parseDate,
} from "../../utils/helpers";
import { useNotification } from "../../context/notificationContext";
import { useQueryParams } from "../../hooks/useQueryParams";
import iconHeartFavorite from "../../assets/images/heart_blue.png";
import deleteButtonIcon from "../../assets/images/clearDestinationOnMainPage.png";
import logoIcon from "../../assets/images/logo/logo-grey.png";

interface ICardProps {
  booking: Booking;
  isEdit?: boolean;
  onDelete?: (id: string) => void;
}

const Card: React.FC<ICardProps> = ({ booking, isEdit = false, onDelete }) => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const checkIn = parseDate(queryParams["book[from]"]);
  const checkOut = parseDate(queryParams["book[to]"]);
  const nights =
    checkIn && checkOut ? calculateNights(checkIn, checkOut) : undefined;
  const navigate = useNavigate();
  const getUrlWithParams = usePreserveQueryParams();
  const photos = booking.objectPhotos ? booking.objectPhotos.slice(0, 6) : [];
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = window.innerWidth <= 1020;
  const [isFavorite, setIsFavorite] = useState(false);
  const { showNotification } = useNotification();

  // При монтировании проверяем, есть ли карточка в избранных
  useEffect(() => {
    const favoriteBookings = JSON.parse(
      localStorage.getItem("favoriteBookings") || "[]"
    );
    const isCardFavorite = favoriteBookings.some(
      (item: { id: string }) => item.id === booking.id
    );
    setIsFavorite(isCardFavorite);
  }, [booking.id]);

  const handlePhotoChange = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentPhotoIndex(index);
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Если клик произошел на индикаторе фото, прерываем переход
    if (
      (e.target as HTMLElement).classList.contains(styles.photoIndicatorBlock)
    ) {
      return;
    }

    if (!isMobile) {
      setIsOpenModal(true);
    } else {
      navigate(getUrlWithParams(`/booking/${booking.id}`));
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setIsOpenModal(false);
    }, 0);
  };

  const deleteFromFavorites = () => {
    if (onDelete) {
      onDelete(booking.id);
    }
  };

  const handleAddToFavorites = () => {
    addToFavorites(booking.id);
    setIsFavorite(true);
  };

  const [imageWidth, setImageWidth] = useState<number>(322); // Начальная ширина для десктопа

  // Функция для обновления ширины при изменении размера экрана
  const updateImageWidth = () => {
    if (window.innerWidth <= 724) {
      // Для мобильных устройств устанавливаем ширину 100% (или другое значение)
      setImageWidth(window.innerWidth - 40); // Пример: уменьшаем на 30px для отступов
    } else {
      if (window.innerWidth <= 1020) {
        setImageWidth((window.innerWidth - 40 - 24) / 2);
      } else {
        // Для десктопа оставляем стандартную ширину
        setImageWidth(322);
      }
    }
  };

  useEffect(() => {
    updateImageWidth();
    window.addEventListener("resize", updateImageWidth);

    return () => {
      window.removeEventListener("resize", updateImageWidth);
    };
  }, []);
  const heightImage = 280;

  return (
    <div className={styles.card} onClick={handleCardClick}>
      <div className={styles.imageContainer}>
        {photos.length > 0 ? (
          <>
            <img
              src={getImageUrl(
                photos[currentPhotoIndex].storageUrl,
                imageWidth,
                heightImage
              )}
              alt={booking.name}
              className={styles.image}
            />
            <div className={styles.photoSlider}>
              {photos.map((_, index) => (
                <div
                  onClick={(e) => handlePhotoChange(index, e)}
                  className={styles.photoIndicatorBlock}
                  key={index}
                >
                  <div
                    className={`${styles.photoIndicator} ${
                      index === currentPhotoIndex ? styles.active : ""
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={styles.noImage}>
            <img src={logoIcon} alt="logo" className={styles.iconLogo} />
            <p className={styles.textNoPhoto}>{t("card.noPhotos")}</p>
          </div>
        )}
        <div className={styles.rating}>
          <img src={starIcon} alt="icon star" className={styles.starIcon} />
          <span>4.5</span>
        </div>
        {isEdit ? (
          <button
            className={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation();
              deleteFromFavorites();
            }}
          >
            <img
              src={deleteButtonIcon}
              alt="delete icon"
              className={styles.deleteIcon}
            />
          </button>
        ) : (
          <button
            className={styles.likeButton}
            onClick={(e) => {
              e.stopPropagation();
              if (!isFavorite) {
                handleAddToFavorites();
              }
            }}
          >
            <img
              src={isFavorite ? iconHeartFavorite : heartIcon}
              alt="icon heart"
              className={styles.heartIcon}
            />
          </button>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>{booking.name}</h3>
          <p className={styles["parameters-block"]}>
            {`${t("card.beds", { count: booking.numBeds })} | 
        ${t("card.bedrooms", { count: booking.numBedrooms })}`}
          </p>
        </div>
        <div className={styles.footer}>
          <p className={styles.price}>
            {nights ? (
              booking.rentalCost ? (
                `${CURRENCYSYMBOL} ${formatPrice(booking.rentalCost)}`
              ) : (
                ""
              )
            ) : booking.pricePerNight ? (
              ""
            ) : (
              <span
                className={styles.textShowCost}
                onClick={(e) => {
                  e.stopPropagation();
                  showNotification(t("card.showNotification"), "warning");
                }}
              >
                {t("card.showCost")}
              </span>
            )}
          </p>
          <span className={styles.night}>
            {nights && booking.rentalCost
              ? `${CURRENCYSYMBOL} ${formatPrice(
                  booking.rentalCost / nights
                )}/${t("card.night")}`
              : booking.pricePerNight
              ? `${CURRENCYSYMBOL} ${formatPrice(booking.pricePerNight)}/${t(
                  "card.night"
                )}`
              : ""}
          </span>
        </div>
      </div>
      {isOpenModal && (
        <BookingDetailsModal
          isOpen={isOpenModal}
          onClose={handleClose}
          booking={booking}
          nights={nights}
        />
      )}
    </div>
  );
};

export default Card;
