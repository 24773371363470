import { FC, useEffect, useState } from "react";
import styles from "./loginModal.module.scss";
import facebookIcon from "../../assets/images/facebook.png";
import emailIcon from "../../assets/images/mail.png";
import closeButtonIcon from "../../assets/images/buttonClose.png";
import { useTranslation } from "react-i18next";
import { fetchGoogleSignIn } from "../../api/api";
import { useNavigate } from "react-router-dom";
import FooterBottom from "../footerBottom/footerBottom";
import phoneIcon from "../../assets/images/phone.png";
import { useAuth } from "../../context/authContext";

declare global {
  interface Window {
    handleCredentialResponse: (response: any) => void;
  }
}

interface ILoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToRegister: () => void;
}

const LoginModal: FC<ILoginModalProps> = ({
  isOpen,
  onClose,
  onSwitchToRegister,
}) => {
  const { t } = useTranslation();
  const { setAuthState } = useAuth();
  const navigate = useNavigate();
  const [authMethod, setAuthMethod] = useState<"email" | "phone">("email");
  const [isSmsCodeInputVisible, setIsSmsCodeInputVisible] =
    useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const isMobile = window.innerWidth <= 1220;

  const handleCredentialResponse = (response: any) => {
    fetchGoogleSignIn(response.credential)
      .then((res) => {
        const data = res.data;
        console.log("Login success:", data);
        if (data.signInResult.roles.includes("ROLE_USER")) {
          setAuthState(data.signInResult.accessToken);
          onClose();
        } else {
          setAuthState(data.signInResult.accessToken);
          onClose();
        }
      })
      .catch((error) => console.error("Login error:", error));
  };

  useEffect(() => {
    window.handleCredentialResponse = handleCredentialResponse;
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = () => {
        console.log("Google Identity Services script loaded.");
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (authMethod === "phone" && !isSmsCodeInputVisible) {
      // Скрываем текущие элементы и показываем поле для ввода кода
      setIsSmsCodeInputVisible(true);
    } else {
      // Действия после ввода кода или отправки по email
      // TODO надо будет реализовать как будет готов бэкенд
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalBlock}>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.block}>
            <div className={styles.header}>
              <div className={styles.logo} />
            </div>
            <div className={styles.modalWithCloseButton}>
              <div className={styles.modal}>
                <form onSubmit={handleSubmit} className={styles.form}>
                  <h3 className={styles.title}>{t("loginModal.title")}</h3>
                  {/* Вход через Email */}
                  {authMethod === "email" && (
                    <>
                      <input
                        className={styles.inputBlock}
                        type="email"
                        placeholder={t("loginModal.emailPlaceholder")}
                      />
                      <input
                        className={styles.inputBlock}
                        type="password"
                        placeholder={t("loginModal.passwordPlaceholder")}
                      />
                    </>
                  )}
                  {/* Вход через Телефон */}
                  {authMethod === "phone" && !isSmsCodeInputVisible && (
                    <div className={styles.inputBlock}>
                      {phoneNumber || isFocused ? (
                        <span className={styles.plusSign}>+</span>
                      ) : null}

                      <input
                        type="tel"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        placeholder={
                          isFocused ? "" : t("loginModal.phoneNumber")
                        }
                        className={
                          phoneNumber || isFocused
                            ? `${styles.phoneInput} ${styles["pl-20"]}`
                            : styles.phoneInput
                        }
                      />
                    </div>
                  )}
                  {authMethod === "email" && (
                    <p className={styles.textForgotPassword}>
                      {t("loginModal.forgotPassword")}
                    </p>
                  )}
                  {authMethod === "phone" && isSmsCodeInputVisible && (
                    <input
                      className={styles.inputBlock}
                      type="text"
                      placeholder={t("loginModal.smsCode")}
                      value={smsCode}
                      onChange={(e) => setSmsCode(e.target.value)}
                    />
                  )}
                  <button className={styles.nextButton} type="submit">
                    {t("loginModal.nextButton")}
                  </button>
                </form>
                {authMethod === "phone" && isSmsCodeInputVisible && (
                  <p className={styles.textDontSmsCode}>
                    {t("loginModal.textDontSmsCode")}
                  </p>
                )}
                {authMethod === "phone" && !isSmsCodeInputVisible && (
                  <p className={styles.textSendSms}>
                    {t("loginModal.sendSMS")}
                  </p>
                )}
                {!isSmsCodeInputVisible && (
                  <p className={styles.or}>{t("loginModal.or")}</p>
                )}
                {!isSmsCodeInputVisible && (
                  <div className={styles.socialButtons}>
                    <button className={styles.socialButton}>
                      <img
                        src={facebookIcon}
                        alt="Facebook"
                        className={styles.facebookIcon}
                      />
                    </button>
                    {/* Стилизация кнопки Google */}
                    <div className={styles.googleWrapper}>
                      <div
                        id="g_id_onload"
                        data-client_id={googleClientId}
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleCredentialResponse"
                        data-auto_prompt="false"
                      ></div>
                      <div
                        className="g_id_signin"
                        data-type="icon"
                        data-theme="outline"
                        data-size="large"
                        data-logo_alignment="center"
                      ></div>
                      <div className={styles.customIcon}></div>
                    </div>
                    <button
                      className={styles.socialButton}
                      onClick={() =>
                        setAuthMethod(
                          authMethod === "phone" ? "email" : "phone"
                        )
                      }
                    >
                      <img
                        src={authMethod === "phone" ? emailIcon : phoneIcon}
                        alt="icon"
                        className={styles.icon}
                      />
                    </button>
                  </div>
                )}
                {!isSmsCodeInputVisible && (
                  <p className={styles.textRegister}>
                    {t("loginModal.registerText")}{" "}
                    <span
                      className={styles.linkText}
                      onClick={onSwitchToRegister}
                    >
                      {" "}
                      {t("loginModal.registerSignText")}
                    </span>
                  </p>
                )}
                {!isSmsCodeInputVisible && (
                  <p className={styles.terms}>
                    {t("loginModal.terms")}{" "}
                    <a href="/terms" className={styles.link}>
                      {t("loginModal.termsLink")}
                    </a>{" "}
                    {t("loginModal.and")}{" "}
                    <a href="/privacy" className={styles.link}>
                      {t("loginModal.privacyLink")}
                    </a>
                    .
                  </p>
                )}
              </div>
              <button className={styles.closeButton} onClick={onClose}>
                <img src={closeButtonIcon} alt="close button icon" />
              </button>
            </div>
          </div>
          {isMobile ? (
            ""
          ) : (
            <div className={styles.footerBlock}>
              <FooterBottom isMainPage={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
