import { Country } from "../types/country";

export const BASEURL = "https://app-staging.selof.ru/api/v1";

export const COUNTRIES: Country[] = [
  { telephoneCode: "+7", value: "russia" },
  { telephoneCode: "+1", value: "usa" },
  { telephoneCode: "+44", value: "uk" },
  { telephoneCode: "+66", value: "thailand" },
  { telephoneCode: "+86", value: "china" },
  { telephoneCode: "+81", value: "japan" },
  { telephoneCode: "+7", value: "kazakhstan" },
  { telephoneCode: "+49", value: "germany" },
  { telephoneCode: "+33", value: "france" },
  { telephoneCode: "+91", value: "india" },
  { telephoneCode: "+39", value: "italy" },
];

export const ALLOWEDTYPES = ["apartment", "house", "hotel"];

export enum ModalNames {
  Login = "login",
  Register = "registration",
  Guest = "guest",
  Suggestions = "suggestions",
  DatePicker = "date-picker",
  DropDownMenu = "dropDownMenu",
  Filter = "filter",
  FilterMobile = "filterMobile",
  BurgerMenu = "burgerMenu",
}

export const CURRENCYSYMBOL = "$";
