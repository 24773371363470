import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchSearchSuggestions } from "../api/api";
import { ISearchSuggestionItem } from "../types/searchSuggestionItem";

interface IUseLocationSuggestionsParams {
  location: string;
  enabled: boolean;
}

export const useLocationSuggestions = ({
  location,
  enabled,
}: IUseLocationSuggestionsParams) => {
  const { i18n } = useTranslation();
  const [locationSuggestions, setLocationSuggestions] = useState<
    ISearchSuggestionItem[]
  >([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!enabled || location.length <= 2) {
      setLocationSuggestions([]);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      setIsFetching(true);
      fetchSearchSuggestions(location, i18n.language)
        .then((data) => {
          if (data["hydra:totalItems"] > 0) {
            setLocationSuggestions(data["hydra:member"]);
          } else {
            setLocationSuggestions([]);
          }
        })
        .catch((error) => {
          console.error("Ошибка при получении подсказок:", error);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [location, i18n.language, enabled]);

  const clearSuggestions = () => {
    setLocationSuggestions([]);
  };

  return { locationSuggestions, isFetching, clearSuggestions };
};
