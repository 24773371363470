import React, { useEffect, useState } from "react";
import styles from "./guestModal.module.scss";
import minusIcon from "../../assets/images/minusIcon.png";
import plusIcon from "../../assets/images/plusIcon.png";
import { useTranslation } from "react-i18next";
import iconButtonCLose from "../../assets/images/x.png";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateGuestCount } from "../../store/querySlice";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUrlGuests } from "../../utils/helpers";
import { useNotification } from "../../context/notificationContext";

interface GuestModalProps {
  onClose: () => void;
  isBottom?: boolean;
  guestCount?: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  handleChangeGuests?: (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => void;
  useCustomData?: boolean;
  maxGuests?: number | undefined;
}

const GuestModal: React.FC<GuestModalProps> = ({
  onClose,
  isBottom = false,
  guestCount,
  handleChangeGuests,
  useCustomData = false,
  maxGuests = undefined,
}) => {
  const globalGuestCount = useSelector(
    (state: { query: QueryState }) => state.query.guestCount
  );
  // Используем либо переданные данные, либо данные из Redux
  const currentGuestCount = useCustomData ? guestCount : globalGuestCount;
  const { showNotification } = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationUrl = useLocation();
  const searchParams = new URLSearchParams(locationUrl.search);
  const [isBookingPage, setIsBookingPage] = useState(false);

  useEffect(() => {
    const testIsBookingPage = /^\/booking(-rent)?\/[^/]+$/.test(
      locationUrl.pathname
    );
    setIsBookingPage(testIsBookingPage);
  }, [locationUrl.pathname]);

  // Функция для изменения количества гостей
  const changeGuestCount = (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => {
    if (useCustomData && guestCount && handleChangeGuests) {
      handleChangeGuests(type, change);
    } else {
      const newCount = Math.max(0, globalGuestCount[type] + change);
      if (
        maxGuests &&
        (type === "adults" || type === "children") &&
        change > 0
      ) {
        const totalAdults =
          type === "adults" ? newCount : globalGuestCount.adults;
        const totalChildren =
          type === "children" ? newCount : globalGuestCount.children;
        const totalGuests = totalAdults + totalChildren;
        if (totalGuests > maxGuests) {
          showNotification(t("guestModal.wrongQuantityGuests"), "warning");
          return;
        }
      }
      dispatch(updateGuestCount({ type, value: newCount }));
      const newGuestState = {
        ...globalGuestCount,
        [type]: newCount,
      };

      // Проверка, находимся ли мы на booking-странице
      if (isBookingPage) {
        updateUrlGuests(
          navigate,
          locationUrl.pathname,
          searchParams,
          newGuestState
        );
      }
    }
  };

  const { t } = useTranslation();

  return (
    <div
      className={isBottom ? `${styles.modal} ${styles.isBottom}` : styles.modal}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.mobile}>
          <div className={styles.top}>
            <p className={styles.textTop}>{t("guestModal.guests")}</p>
            <img
              src={iconButtonCLose}
              alt="icon button close"
              className={styles.buttonClose}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
        </div>
        {/* Adults Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.adults.title")}</p>
            <p className={styles.text}>{t("guestModal.adults.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("adults", -1);
              }}
              disabled={currentGuestCount?.adults === 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{currentGuestCount?.adults}</span>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("adults", 1);
              }}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Children Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>
              {t("guestModal.children.title")}
            </p>
            <p className={styles.text}>{t("guestModal.children.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("children", -1);
              }}
              disabled={currentGuestCount?.children === 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{currentGuestCount?.children}</span>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("children", 1);
              }}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Infants Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.infants.title")}</p>
            <p className={styles.text}>{t("guestModal.infants.subtitle")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("infants", -1);
              }}
              disabled={currentGuestCount?.infants === 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{currentGuestCount?.infants}</span>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("infants", 1);
              }}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>

        {/* Pets Section */}
        <div className={styles.guestItem}>
          <div className={styles.texts}>
            <p className={styles.textOption}>{t("guestModal.pets.title")}</p>
          </div>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("pets", -1);
              }}
              disabled={currentGuestCount?.pets === 0}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{currentGuestCount?.pets}</span>
            <button
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                changeGuestCount("pets", 1);
              }}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>
        <div className={styles.mobile}>
          <div className={styles.bottom}>
            <button
              className={styles.buttonDone}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t("guestModal.done")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestModal;
