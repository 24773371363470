import React, { useEffect, useRef, useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import styles from "./rightStickyContent.module.scss";
import arrowDownIcon from "../../assets/images/arrow_down.png";
import minusIcon from "../../assets/images/minus.png";
import plusIcon from "../../assets/images/plus.png";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateDates,
  updateGuestCount,
} from "../../store/querySlice";
import {
  calculateNights,
  formatDate,
  parseDate,
  updateUrlDates,
  updateUrlGuests,
} from "../../utils/helpers";
import { CURRENCYSYMBOL } from "../../utils/const";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";

interface RightStickyContentProps {
  price: number | undefined;
  pricePerNight: number | undefined;
  maxGuests: number;
  availableDates: string[];
  id: string;
  location: string;
}

const RightStickyContent: React.FC<RightStickyContentProps> = ({
  price,
  pricePerNight,
  maxGuests,
  availableDates,
  id,
  location,
}) => {
  const { queryParams } = useQueryParams();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const dispatch = useDispatch();
  const { showNotification } = useNotification();
  const [discount, setDiscount] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isGuestDropdownOpen, setIsGuestDropdownOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const guestDropdownRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const checkInDate = state.checkIn ? parseDate(state.checkIn) : undefined;
  const checkOutDate = state.checkOut ? parseDate(state.checkOut) : undefined;
  const isMobile = window.innerWidth <= 1439;
  const navigate = useNavigate();
  const lon = queryParams["geoPoint[lon]"]
    ? Number(queryParams["geoPoint[lon]"]) || null
    : null;
  const lat = queryParams["geoPoint[lat]"]
    ? Number(queryParams["geoPoint[lat]"]) || null
    : null;

  const [nights, setNights] = useState<number | undefined>(undefined);
  const totalGuests = state.guestCount.adults + state.guestCount.children;
  const locationUrl = useLocation();
  const searchParams = new URLSearchParams(locationUrl.search);

  useEffect(() => {
    if (!state.checkIn || !state.checkOut) return;
    setNights(
      calculateNights(parseDate(state.checkIn), parseDate(state.checkOut))
    );
  }, [state.checkIn, state.checkOut]);

  const toggleGuestDropdown = () => {
    setIsGuestDropdownOpen((prev) => !prev);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleChangeGuests = (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => {
    const newCount = Math.max(0, state.guestCount[type] + change);
    if ((type === "adults" || type === "children") && change > 0) {
      const totalAdults =
        type === "adults" ? newCount : state.guestCount.adults;
      const totalChildren =
        type === "children" ? newCount : state.guestCount.children;
      const totalGuests = totalAdults + totalChildren;
      if (totalGuests > maxGuests) {
        showNotification(
          t("rightStickyContent.wrongQuantityGuests"),
          "warning"
        );
        return;
      }
    }
    dispatch(updateGuestCount({ type, value: newCount }));
    updateUrlGuests(navigate, locationUrl.pathname, searchParams, {
      ...state.guestCount,
      [type]: newCount,
    });
  };

  const handleDateChange = (range: DateRange | undefined) => {
    const checkIn = range?.from;
    const checkOut = range?.to;
    if (!range || !range.from || !range.to) {
      updateUrlDates(
        navigate,
        locationUrl.pathname,
        searchParams,
        undefined,
        undefined
      );
      dispatch(updateDates({ checkIn: undefined, checkOut: undefined }));
      return;
    }

    // Проверяем, что все даты в диапазоне доступны
    const rangeIsValid = Array.from(
      {
        length:
          (range.to.getTime() - range.from.getTime()) / (1000 * 60 * 60 * 24) +
          1,
      },
      (_, i) => {
        //@ts-ignore
        const date = new Date(range.from.getTime() + i * 24 * 60 * 60 * 1000);
        return format(date, "yyyyMMdd"); // Преобразуем в формат YYYYMMDD
      }
    ).every((dateString) => availableDatesSet.has(dateString));

    if (rangeIsValid) {
      if (
        checkInDate &&
        checkOutDate &&
        checkInDate.getTime() !== checkOutDate.getTime()
      ) {
        if (range.from.getTime() !== checkInDate.getTime()) {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            checkIn,
            checkIn
          );
          dispatch(
            updateDates({
              checkIn: formatDate(range.from),
              checkOut: formatDate(range.from),
            })
          );
        } else {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            checkOut,
            checkOut
          );
          dispatch(
            updateDates({
              checkIn: formatDate(range.to),
              checkOut: formatDate(range.to),
            })
          );
        }
      } else {
        updateUrlDates(
          navigate,
          locationUrl.pathname,
          searchParams,
          checkIn,
          checkOut
        );
        dispatch(
          updateDates({
            checkIn: formatDate(range.from),
            checkOut: formatDate(range.to),
          })
        );
      }
    } else {
      showNotification(t("rightStickyContent.wrongPeriod"), "warning");
      updateUrlDates(
        navigate,
        locationUrl.pathname,
        searchParams,
        undefined,
        undefined
      );
      dispatch(updateDates({ checkIn: undefined, checkOut: undefined }));
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      (isDatePickerOpen &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)) ||
      (isGuestDropdownOpen &&
        guestDropdownRef.current &&
        !guestDropdownRef.current.contains(event.target as Node))
    ) {
      setIsDatePickerOpen(false);
      setIsGuestDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen || isGuestDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen, isGuestDropdownOpen]);

  const handleBooking = () => {
    if (!state.checkIn || !state.checkOut) {
      showNotification(t("rightStickyContent.Please-enter-a-date"), "warning");
      return;
    }
    const queryParams: Record<string, string> = {
      "geoPoint[lat]": lat!.toString(),
      "geoPoint[lon]": lon!.toString(),
    };
    queryParams["geoPoint[distance]"] = state.radius.toString();

    if (state.checkIn) {
      queryParams["book[from]"] = state.checkIn;
    }

    if (state.checkOut) {
      queryParams["book[to]"] = state.checkOut;
    }

    if (state.checkIn && state.checkOut) {
      queryParams["book[nights]"] = calculateNights(
        parseDate(state.checkIn),
        parseDate(state.checkOut)
      ).toString();
    }
    if (location) {
      queryParams["nameLocation"] = location;
    }

    if (state.guestCount.adults) {
      queryParams["adults"] = state.guestCount.adults.toString();
    }

    if (state.guestCount.children) {
      queryParams["children"] = state.guestCount.children.toString();
    }

    queryParams["numBeds[gte]"] = state.filters.beds.toString();
    queryParams["numBedrooms[gte]"] = state.filters.rooms.toString();
    if (state.filters.selectedType.length > 0) {
      queryParams["kind[]"] = state.filters.selectedType.join(",");
    }
    // Формируем строку запроса
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    // если в процессе меняли локацию - но аерндуем старую локацию
    if (state.lat !== lat || state.lon !== lon) {
      dispatch(
        updateCoordinates({
          lat,
          lon,
        })
      );
    }
    // Навигация на страницу поиска
    navigate(`/booking-rent/${id}?${queryString}`);
  };
  return (
    <div className={styles["right-sticky-content"]}>
      {isMobile ? (
        <div className={styles.fixBottomMobileBlock}>
          <div className={styles.leftFixedBlock}>
            <p className={styles.textPriceNight}>
              {CURRENCYSYMBOL}
              {pricePerNight ? pricePerNight : ""}{" "}
              {t("rightStickyContent.night")}
            </p>
            <p className={styles.textDate}>
              {checkInDate && checkOutDate
                ? `${format(checkInDate, "d MMM", {
                    locale: currentLocale,
                  })} - 
                                   ${format(checkOutDate, "d MMM", {
                                     locale: currentLocale,
                                   })}`
                : ""}
            </p>
          </div>
          <button
            className={styles.buttonReserve}
            onClick={(e) => {
              e.stopPropagation();
              handleBooking();
            }}
          >
            <p className={styles.textInButtonReserve}>
              {t("rightStickyContent.reserve")}
            </p>
          </button>
        </div>
      ) : (
        <div className={styles["sticky-block"]}>
          <div className={styles["price-info"]}>
            <h2>{price ? `${CURRENCYSYMBOL}${price}` : ""}</h2>
            <span>
              {pricePerNight ? pricePerNight : ""}
              {CURRENCYSYMBOL}/{t("rightStickyContent.night")}
            </span>
          </div>
          <div ref={datePickerRef}>
            <div className={styles["date-inputs"]} onClick={toggleDatePicker}>
              <div className={styles["date-input"]}>
                <span className={styles["date-input-label"]}>
                  {t("rightStickyContent.Check-in")}
                </span>
                <span className={styles["date-input-placeholder"]}>
                  {checkInDate
                    ? `${format(checkInDate, "d MMM", {
                        locale: currentLocale,
                      })}`
                    : t("rightStickyContent.Please-enter-a-date")}
                </span>
              </div>
              <div className={styles["date-input"]}>
                <span className={styles["date-input-label"]}>
                  {t("rightStickyContent.Check-out")}
                </span>
                <span className={styles["date-input-placeholder"]}>
                  {checkOutDate
                    ? `${format(checkOutDate, "d MMM", {
                        locale: currentLocale,
                      })}`
                    : t("rightStickyContent.Please-enter-a-date")}
                </span>
              </div>
            </div>
            {/* Date Picker */}
            {isDatePickerOpen && (
              <div className={styles["date-picker"]}>
                <DayPicker
                  mode="range"
                  selected={{
                    from: state.checkIn ? parseDate(state.checkIn) : undefined,
                    to: state.checkOut ? parseDate(state.checkOut) : undefined,
                  }}
                  onSelect={handleDateChange}
                  disabled={[
                    {
                      before: new Date(),
                    },
                    (date) => {
                      // Делаем недоступными все даты, которые не входят в доступные
                      const formattedDate = format(date, "yyyyMMdd");
                      return !availableDatesSet.has(formattedDate);
                    },
                  ]}
                  locale={currentLocale}
                  formatters={{
                    formatCaption: (date) =>
                      format(date, "LLLL yyyy", { locale: currentLocale }),
                  }}
                  numberOfMonths={1}
                />
              </div>
            )}
          </div>
          <div className={styles["guest-dropdown"]} ref={guestDropdownRef}>
            <button onClick={toggleGuestDropdown}>
              <div className={styles["guest-dropdown-left"]}>
                <span className={styles["guest-label"]}>
                  {t("rightStickyContent.guests", { count: totalGuests })}
                </span>
              </div>
              <img
                src={arrowDownIcon}
                alt="Arrow Down"
                className={styles["dropdown-icon"]}
              />
            </button>
            {isGuestDropdownOpen && (
              <div className={styles["guest-dropdown-menu"]}>
                {(["adults", "children", "infants", "pets"] as const).map(
                  (type, index) => (
                    <div key={index} className={styles["guest-item"]}>
                      <div className={styles["guest-labels"]}>
                        <span className={styles["guest-title"]}>
                          {t(`rightStickyContent.${type}.title`)}
                        </span>
                        <span className={styles["guest-subtitle"]}>
                          {t(`rightStickyContent.${type}.subtitle`)}
                        </span>
                      </div>
                      <div className={styles["guest-controls"]}>
                        <button
                          onClick={() => handleChangeGuests(type, -1)}
                          disabled={
                            state.guestCount[
                              type as keyof typeof state.guestCount
                            ] === 0
                          }
                        >
                          <img
                            src={minusIcon}
                            alt={t("rightStickyContent.minusIconAlt")}
                            className={styles["minus-icon"]}
                          />
                        </button>
                        <span>
                          {
                            state.guestCount[
                              type as keyof typeof state.guestCount
                            ]
                          }
                        </span>
                        <button onClick={() => handleChangeGuests(type, 1)}>
                          <img
                            src={plusIcon}
                            alt={t("rightStickyContent.plusIconAlt")}
                            className={styles["plus-icon"]}
                          />
                        </button>
                      </div>
                    </div>
                  )
                )}
                <p>
                  {t("rightStickyContent.maxGuestsMessage", {
                    maxGuests,
                  })}
                </p>
                <button
                  onClick={toggleGuestDropdown}
                  className={styles["close-button"]}
                >
                  {t("rightStickyContent.close")}
                </button>
              </div>
            )}
          </div>
          <button
            className={styles["availability-button"]}
            onClick={handleBooking}
          >
            {t("rightStickyContent.reserve")}
          </button>
          <div className={styles.priceBlock}>
            <p className={styles.textTop}>
              {t("rightStickyContent.chargedYet")}
            </p>
            <div className={styles.blocks}>
              <p className={styles.textUnderLine}>
                {price && nights
                  ? `${CURRENCYSYMBOL}${pricePerNight}${" "}x${" "}${
                      nights
                        ? t("rightStickyContent.nights", { count: nights })
                        : ""
                    } `
                  : ""}
              </p>
              <p className={styles.textPrice}>{`${CURRENCYSYMBOL}${
                price ? price : ""
              }`}</p>
            </div>
            <div className={styles.blocks}>
              <p className={styles.textUnderLine}>
                {t("rightStickyContent.discountStay")}
              </p>
              {/* TODO проставить скидку когда будет готова на бэке*/}
              <p
                className={styles.textPriceDiscount}
              >{`-${CURRENCYSYMBOL}${discount}`}</p>
            </div>
            <div className={styles.totalCostBlock}>
              <p className={styles.textTotalCost}>
                {t("rightStickyContent.totalTaxes")}
              </p>
              <p className={styles.textTotalPirce}>{`${CURRENCYSYMBOL}${
                price ? price - discount : ""
              }`}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightStickyContent;
