import React from "react";
import styles from "./roomModal.module.scss";
import minusIcon from "../../assets/images/minusIcon.png";
import plusIcon from "../../assets/images/plusIcon.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateFilters } from "../../store/querySlice";

const RoomModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rooms = useSelector(
    (state: { query: QueryState }) => state.query.filters.rooms
  );

  const onChange = (change: number) => {
    const newRooms = rooms + change;
    dispatch(updateFilters({ rooms: newRooms >= 1 ? newRooms : 1 }));
  };
  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <h3 className={styles.title}>{t("roomModal.rooms")}</h3>
        <div className={styles.controls}>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              onChange(-1);
            }}
            disabled={rooms <= 1}
          >
            <img src={minusIcon} alt="minus icon" />
          </button>
          <span className={styles.value}>{rooms === 5 ? "5+" : rooms}</span>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              onChange(1);
            }}
            disabled={rooms >= 5}
          >
            <img src={plusIcon} alt="plus icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomModal;
