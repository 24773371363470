import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import { buildSearchQueryString } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import styles from "./notFoundPage.module.scss";
import iconButtonGoBack from "../../assets/images/chevron-left.png";
import { useTranslation } from "react-i18next";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import icon404 from "../../assets/images/404 img.png";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const NotFoundPage: React.FC = () => {
  const { queryParams } = useQueryParams();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const { t } = useTranslation();

  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [shouldSearch, setShouldSearch] = useState(false);
  const getUrlWithParams = usePreserveQueryParams();

  const navigate = useNavigate();

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const dispatch = useDispatch();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  return (
    <div>
      <Header
        location={location}
        onLocationChange={handleLocationChange}
        availableDates={[]}
        setShouldSearch={setShouldSearch}
        handleSearch={handleSearch}
        locationSuggestions={locationSuggestions}
        onSelectSuggestion={handleSelectSuggestion}
        allowAllDates={true}
      />
      <div className={styles.section}>
        <div className={`${styles.top} ${styles.desktop}`}>
          <button className={styles.buttonGoBack} onClick={() => navigate(-1)}>
            <img
              src={iconButtonGoBack}
              alt="icon button go back"
              className={styles.iconButtonGoBack}
            />
            <p className={styles.textInButtonGoBack}>
              {t("notFoundPage.goBack")}
            </p>
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles.texts}>
            <h1 className={`${styles.title} ${styles.desktop}`}>Oops!</h1>
            <h3 className={styles.subtitle}>{t("notFoundPage.canNotFind")}</h3>
            <p className={styles.text}>{t("notFoundPage.404")}</p>
            <p className={styles.text}>{t("notFoundPage.links")}</p>
            <Link to={getUrlWithParams("/")} className={styles.link}>
              {t("notFoundPage.mainPage")}
            </Link>
            <Link to={getUrlWithParams("/search")} className={styles.link}>
              {t("notFoundPage.beginSearch")}
            </Link>
            <Link to={getUrlWithParams("/help")} className={styles.link}>
              {t("notFoundPage.help")}
            </Link>
            <Link to={getUrlWithParams("/safety")} className={styles.link}>
              {t("notFoundPage.safety")}
            </Link>
          </div>
          <div className={styles.rightBlock}>
            <h1 className={`${styles.title} ${styles.mobile}`}>Oops!</h1>
            <img
              src={icon404}
              alt="icon error 404"
              className={styles.icon404}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
