import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setQueryParams } from "../../store/querySlice";
import { convertToQueryState } from "../../utils/helpers";


const AppInitializer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const formattedQueryParams = Object.fromEntries(queryParams.entries());
    dispatch(setQueryParams(convertToQueryState(formattedQueryParams)));
  }, []);

  return null; 
};

export default AppInitializer;
