import React from "react";
import styles from "./footer.module.scss";
import { Booking } from "../../types/booking";
import { useTranslation } from "react-i18next";
import FooterBottom from "../footerBottom/footerBottom";

interface IFooter {
  booking?: Booking;
}
const Footer: React.FC<IFooter> = ({ booking }) => {
  const { t } = useTranslation();
  const handleShowMore = (section: string) => {
    console.log(`Show more clicked for ${section}`);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles["footer-top"]}>
        <div className={styles.footerContent}>
          <h3 className={styles["footer-heading"]}>
            {t("footer.thingsToKnow")}
          </h3>
          <div className={styles["footer-columns"]}>
            <div className={styles["footer-column"]}>
              <h4 className={styles["footer-column-heading"]}>
                {t("footer.houseRules")}
              </h4>
              <ul>
                <li>{t("footer.checkIn", { time: "2:00 PM" })}</li>
                <li>{t("footer.checkout", { time: "12:00 PM" })}</li>
                <li>
                  {t("footer.maxGuests", { count: booking?.numGuests || 0 })}
                </li>
                <li>
                  <button
                    className={styles["footer-button"]}
                    onClick={() => handleShowMore("House rules")}
                  >
                    {t("footer.showMore")}
                  </button>
                </li>
              </ul>
            </div>
            <div className={styles["footer-column"]}>
              <h4 className={styles["footer-column-heading"]}>
                {t("footer.safetyAndProperty")}
              </h4>
              <ul>
                <li>{t("footer.coAlarm")}</li>
                <li>{t("footer.smokeAlarm")}</li>
                <li>
                  <button
                    className={styles["footer-button"]}
                    onClick={() => handleShowMore("Safety & property")}
                  >
                    {t("footer.showMore")}
                  </button>
                </li>
              </ul>
            </div>
            <div className={styles["footer-column"]}>
              <h4 className={styles["footer-column-heading"]}>
                {t("footer.cancellationPolicy")}
              </h4>
              <ul>
                <li>{t("footer.cancellationDetails")}</li>
                <li>
                  <button
                    className={styles["footer-button"]}
                    onClick={() => handleShowMore("Cancellation policy")}
                  >
                    {t("footer.showMore")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
