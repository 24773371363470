import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./footerBottom.module.scss";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";

interface IFooterBottom {
  isMainPage?: boolean;
}
const FooterBottom: FC<IFooterBottom> = ({ isMainPage = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles["footer-bottom"]} ${styles.desktop}`}>
        <span className={isMainPage ? styles.white : ""}>
          2025 {t("footerBottom.companyName")}
        </span>
        <div className={styles["footer-links"]}>
          <a
            href="/help"
            className={`${styles.link} ${styles["footer-link-visible"]} ${
              isMainPage ? styles.white : ""
            }`}
          >
            {t("footerBottom.help")}
          </a>
          <a
            href="/sitemap"
            className={
              isMainPage ? `${styles.link} ${styles.white}` : styles.link
            }
          >
            {t("footerBottom.sitemap")}
          </a>
          <a
            href="/terms"
            className={`${styles.link} ${styles["footer-link-visible"]} ${
              isMainPage ? styles.white : ""
            }`}
          >
            {t("footerBottom.terms")}
          </a>
          <a
            href="/privacy"
            className={
              isMainPage ? `${styles.link} ${styles.white}` : styles.link
            }
          >
            {t("footerBottom.privacyPolicy")}
          </a>
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.links}>
          <a href="/help" className={styles.linkMobile}>
            {t("footerBottom.help")}
          </a>
          <a href="/terms" className={styles.linkMobile}>
            {t("footerBottom.terms")}
          </a>
          <a href="/sitemap" className={styles.linkMobile}>
            {t("footerBottom.sitemap")}
          </a>
        </div>
        <LanguageSwitcher isBurgerMenu={true}/>
        <div className={styles.bottomMobile}>
          <p className={styles.companyNameMobile}> 2025 {t("footerBottom.companyName")}</p>
        </div>
      </div>
    </>
  );
};
export default FooterBottom;
