import { FC } from "react";
import styles from "./photoPreview.module.scss";
import { Photo } from "../../types/photo";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import logoIcon from "../../assets/images/logo/logo-grey.png";

interface PhotoPreviewProps {
  photos: Photo[];
  onShowAll: (indexPhoto?: number) => void; // Функция для открытия полной галереи
}

const PhotoPreview: FC<PhotoPreviewProps> = ({ photos, onShowAll }) => {
  const { t } = useTranslation();
  const maxVisiblePhotos = 5; // Максимальное количество отображаемых фотографий
  const handleClick = (index: undefined | number) => {
    if (index) {
      onShowAll(index);
    } else {
      onShowAll();
    }
  };
  const renderPhotoBlock = (index: number, width: number, height: number) => {
    const photo = photos[index];
    return (
      <>
        {photo?.storageUrl ? (
          <img
            src={getImageUrl(photo.storageUrl, width, height)}
            alt={photo.description || "Photo"}
            onClick={() => handleClick(index)}
            className={styles.image}
          />
        ) : (
          <div className={styles.noImage}>
            <img src={logoIcon} alt="logo" className={styles.iconLogo} />
            <p className={styles.textNoPhoto}>{t("photoPreview.noPhotos")}</p>
          </div>
        )}
      </>
    );
  };

  const widthImage = window.innerWidth;
  const heightIamge = 250;
  return (
    <div className={styles.top} id="photos">
      <div className={styles.desktop}>
        <div className={styles.firstColumn}>
          {renderPhotoBlock(0, 444.5, 496)}
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.secondImageBlock}>
            {renderPhotoBlock(1, 218.25, 244)}
          </div>
          <div className={styles.secondImageBlock}>
            {renderPhotoBlock(2, 218.25, 244)}
          </div>
        </div>
        <div className={styles.threeColumn}>
          <div className={styles.secondImageBlock}>
            {renderPhotoBlock(3, 218.25, 244)}
          </div>
          <div className={`${styles.secondImageBlock} ${styles.relative}`}>
            {renderPhotoBlock(4, 218.25, 244)}
            {photos.length > maxVisiblePhotos ? (
              <div
                className={styles.showAllPhotosBlock}
                onClick={() => handleClick(undefined)}
              >
                <p className={styles.quantityPhotos}>+{photos.length - 5}</p>
                <p className={styles.textShowPhotos}>
                  {t("photoPreview.showAllPhotos")}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                src={getImageUrl(photo.storageUrl, widthImage, heightIamge)}
                alt={photo.description || `Photo ${index + 1}`}
                className={styles.photo}
              />
              <div className={styles.numberPhoto}>
                {index + 1} / {photos.length}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PhotoPreview;
