import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { getAddressFromCoordinates } from "../../api/api";
import "leaflet/dist/leaflet.css";
import styles from "./map.module.scss";
import marketIcon from "../../assets/images/market_icon.png";
import { useTranslation } from "react-i18next";
import {
  InfrastructureItem,
  InfrastructureType,
} from "../../types/infrastructureTypes";
import { infrastructureIcons } from "../../utils/infrastructureIcons";
import CustomControl from "../customControl/customControl";

interface MapProps {
  lat: number;
  lon: number;
}

const Map: React.FC<MapProps> = ({ lat, lon }) => {
  const { t, i18n } = useTranslation();
  const [address, setAddress] = useState<string | null>(null);
  const [visibleCount, setVisibleCount] = useState<number>(3);

  const handleShowMore = () => {
    setVisibleCount(tempArray.length);
  };
  useEffect(() => {
    // Получаем адрес при загрузке
    const fetchAddress = async () => {
      const fetchedAddress = await getAddressFromCoordinates(
        lat,
        lon,
        i18n.language
      );
      setAddress(fetchedAddress);
    };
    fetchAddress();
  }, [lat, lon, i18n.language]);

  const customIcon = L.icon({
    iconUrl: marketIcon,
    iconSize: [60, 68.85],
  });

  // Массив инфраструктуры
  const tempArray: InfrastructureItem[] = [
    { type: InfrastructureType.BUS_STOP, distance: 100 },
    { type: InfrastructureType.SCHOOL, distance: 150 },
    { type: InfrastructureType.RESTAURANT, distance: 50 },
    { type: InfrastructureType.UNIVERSITY, distance: 250 },
    { type: InfrastructureType.BANK, distance: 100 },
    { type: InfrastructureType.LIBRARY, distance: 100 },
    { type: InfrastructureType.SHOP, distance: 100 },
    { type: InfrastructureType.SPORTS, distance: 100 },
    { type: InfrastructureType.PARKING, distance: 100 },
    { type: InfrastructureType.PHARMACY, distance: 100 },
    { type: InfrastructureType.GAS_STATION, distance: 100 },
    { type: InfrastructureType.HOSPITAL, distance: 100 },
  ];

  return (
    <div className={styles["map-container"]} id="location">
      <h3 className={styles["map-heading"]}>{t("map.whereYouWillBe")}</h3>
      <p className={styles["map-address"]}>
        {address || t("map.loadingAddress")}
      </p>
      <div className={styles.mobile}>
        <h4 className={styles.subtitle}>{t("map.infrastructure")}</h4>
        <ul className={styles.infrastructureList}>
          {tempArray.slice(0, visibleCount).map((item, index) => (
            <li key={index} className={styles.infrastructureItem}>
              <div className={styles.item}>
                <img
                  className={styles.icon}
                  src={infrastructureIcons[item.type]}
                  alt={t(`map.${item.type}`)}
                />
                <span className={styles.typeInfrastructure}>
                  {t(`map.${item.type}`)}
                </span>
              </div>
              <span className={styles.distance}>
                {item.distance}&nbsp;{t("map.metr")}
              </span>
            </li>
          ))}
        </ul>
        {visibleCount < tempArray.length && (
          <button className={styles.showMore} onClick={handleShowMore}>
            {t("map.showMore")}
          </button>
        )}
      </div>
      <div className={styles["map-wrapper"]}>
        <MapContainer
          center={[lat, lon]}
          zoom={13}
          scrollWheelZoom={false}
          className={styles["leaflet-map"]}
          zoomControl={false}
        >
          <CustomControl
            position="left"
            desktopOffset={{ top: 10, side: 15 }}
            mobileOffset={{ top: 9, side: 8 }}
          />
          <TileLayer
            url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${i18n.language}`}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          <Marker position={[lat, lon]} icon={customIcon}>
            <Popup>{address || t("map.loadingAddress")}</Popup>
          </Marker>
        </MapContainer>
        {/* Инфраструктурный блок (только на десктопе) */}
        <div className={styles["infrastructure-box"]}>
          <h4 className={styles.subtitle}>{t("map.infrastructure")}</h4>
          <ul className={styles.infrastructureList}>
            {tempArray.map((item, index) => (
              <li key={index} className={styles.infrastructureItem}>
                <div className={styles.item}>
                  <img
                    className={styles.icon}
                    src={infrastructureIcons[item.type]}
                    alt={t(`map.${item.type}`)}
                  />
                  <span className={styles.typeInfrastructure}>
                    {t(`map.${item.type}`)}
                  </span>
                </div>
                <span className={styles.distance}>
                  {item.distance}&nbsp;{t("map.metr")}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Map;
