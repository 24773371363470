import { useLocation } from "react-router-dom";
import { getQueryParams } from "../utils/helpers";

export const usePreserveQueryParams = () => {
  const location = useLocation();

  // Функция для добавления или изменения query параметров
  return (pathname: string, additionalParams?: Record<string, string | number | undefined>) => {
    const currentParams = getQueryParams(location.search); // Получаем текущие параметры
    const updatedParams = additionalParams
      ? {
          ...currentParams,
          ...Object.fromEntries(
            Object.entries(additionalParams).map(([key, value]) => [
              key,
              value !== undefined ? String(value) : "", // Преобразуем все значения в строку
            ])
          ),
        }
      : currentParams; // Если нет — оставляем текущие параметры

    // Преобразуем обновленные параметры обратно в строку
    const queryString = new URLSearchParams(updatedParams as Record<string, string>).toString();

    return `${pathname}?${queryString}`; // Возвращаем новый URL с обновленными параметрами
  };
};
