import { FC, useEffect, useState } from "react";
import styles from "./registrationModal.module.scss";
import facebookIcon from "../../assets/images/facebook.png";
import emailIcon from "../../assets/images/mail.png";
import closeButtonIcon from "../../assets/images/buttonClose.png";
import { useTranslation } from "react-i18next";
import { fetchGoogleSignIn } from "../../api/api";
import { useNavigate } from "react-router-dom";
import FooterBottom from "../footerBottom/footerBottom";
import phoneIcon from "../../assets/images/phone.png";
import emailGreyIcon from "../../assets/images/mailGrey.png";
import telephoneGreyIcon from "../../assets/images/phoneGrey.png";
import PhoneCodeSelector from "../phoneCodeSelector/phoneCodeSelector";
import { Country } from "../../types/country";
import AccountTypeSelector from "../accountTypeSelector/accountTypeSelector";

declare global {
  interface Window {
    handleCredentialResponse: (response: any) => void;
  }
}

interface IRegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToLogin: () => void;
}

const RegistrationModal: FC<IRegistrationModalProps> = ({
  isOpen,
  onClose,
  onSwitchToLogin,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [authMethod, setAuthMethod] = useState<"email" | "phone">("phone");
  const [step, setStep] = useState<"start" | "sms" | "details">("start");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [smsCode, setSmsCode] = useState<string>("");
  const [selectedType, setSelectedType] = useState<"individual" | "legal">(
    "individual"
  );

  const handleSelect = (type: "individual" | "legal") => {
    setSelectedType(type);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleSmsCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSmsCode(e.target.value);
  };

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const isMobile = window.innerWidth <= 1220;

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = () => {
        console.log("Google Identity Services script loaded.");
        window.handleCredentialResponse = handleCredentialResponse;
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleCredentialResponse = (response: any) => {
    fetchGoogleSignIn(response.credential)
      .then((res) => {
        const data = res.data;
        console.log("Login success:", data);
        if (data.signInResult.roles.includes("ROLE_USER")) {
          onClose();
          navigate("/profile");
        } else {
          onClose();
          navigate("/dashboard");
        }
      })
      .catch((error) => console.error("Login error:", error));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (step === "start" && authMethod === "phone") {
      // Переход к вводу SMS
      console.log(
        `Phone submitted: ${selectedCountry?.telephoneCode} ${phoneNumber}`
      );
      setStep("sms");
    } else if (step === "sms") {
      // Переход к вводу деталей
      console.log(`SMS code submitted: ${smsCode}`);
      setStep("details");
    } else if (step === "details") {
      // Завершение регистрации
      console.log("Registration completed");
      onClose();
    } else if (step === "start" && authMethod === "email") {
      // Переход к вводу деталей
      setStep("details");
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalBlock}>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.block}>
            <div className={styles.header}>
              <div className={styles.logo} />
            </div>
            <div className={styles.modalWithCloseButton}>
              <div className={styles.modal}>
                <form onSubmit={handleSubmit} className={styles.form}>
                  {step === "start" && (
                    <>
                      <h3 className={styles.title}>
                        {t("registrationModal.title")}
                      </h3>
                      {/* Регистрация через Email */}
                      {authMethod === "email" && (
                        <div className={styles.inputBlock}>
                          <img
                            src={emailGreyIcon}
                            alt="icon email"
                            className={styles.icon}
                          />
                          <input
                            className={styles.inputEmail}
                            type="email"
                            placeholder={t(
                              "registrationModal.emailPlaceholder"
                            )}
                          />
                        </div>
                      )}
                      {/* Регистрация через Телефон */}
                      {authMethod === "phone" && (
                        <>
                          <PhoneCodeSelector
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                          />
                          <div className={styles.inputBlock}>
                            <img
                              src={telephoneGreyIcon}
                              alt="telephone icon"
                              className={styles.icon}
                            />
                            <input
                              className={styles.inputPhoneNumber}
                              type="tel"
                              value={phoneNumber}
                              onChange={handlePhoneNumberChange}
                              placeholder={t("registrationModal.phoneNumber")}
                            />
                          </div>
                        </>
                      )}
                      {authMethod === "phone" && (
                        <p className={styles.terms}>
                          {t("registrationModal.terms")}{" "}
                          <a href="/privacy" className={styles.link}>
                            {t("registrationModal.privacyLink")}
                          </a>
                        </p>
                      )}
                      <button className={styles.nextButton} type="submit">
                        {t("registrationModal.nextButton")}
                      </button>
                    </>
                  )}
                  {step === "sms" && (
                    <>
                      <h3 className={styles.title}>
                        {t("registrationModal.title")}
                      </h3>
                      <input
                        className={styles.inputBlock}
                        type="text"
                        placeholder={t("registrationModal.smsCode")}
                        value={smsCode}
                        onChange={handleSmsCodeChange}
                      />
                      <button className={styles.nextButton} type="submit">
                        {t("registrationModal.nextButton")}
                      </button>
                      <p className={styles.textDontSmsCode}>
                        {t("registrationModal.textDontSmsCode")}
                      </p>
                    </>
                  )}
                  {step === "details" && (
                    <>
                      <h3 className={styles.title}>
                        {t("registrationModal.registration")}
                      </h3>
                      <input
                        className={styles.inputBlock}
                        type="text"
                        placeholder={t("registrationModal.name")}
                      />
                      <input
                        className={styles.inputBlock}
                        type="text"
                        placeholder={t("registrationModal.secondName")}
                      />
                      {authMethod === "email" && (
                        <>
                          <PhoneCodeSelector
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                          />
                          <div className={styles.inputBlock}>
                            <img
                              src={telephoneGreyIcon}
                              alt="telephone icon"
                              className={styles.icon}
                            />
                            <input
                              className={styles.inputPhoneNumber}
                              type="tel"
                              value={phoneNumber}
                              onChange={handlePhoneNumberChange}
                              placeholder={t("registrationModal.phoneNumber")}
                            />
                          </div>
                        </>
                      )}
                      <AccountTypeSelector
                        selectedType={selectedType}
                        onSelect={handleSelect}
                      />
                      {authMethod === "phone" && (
                        <input
                          className={styles.inputBlock}
                          type="email"
                          placeholder={t("registrationModal.emailPlaceholder")}
                        />
                      )}
                      <input
                        className={styles.inputBlock}
                        type="password"
                        placeholder={t("registrationModal.password")}
                      />
                      <button
                        className={`${styles.nextButton} ${styles.buttonRegistration}`}
                        type="submit"
                      >
                        {t("registrationModal.nextButton")}
                      </button>
                    </>
                  )}
                </form>
                {step === "start" && (
                  <>
                    <p className={styles.or}>{t("registrationModal.or")}</p>
                    <div className={styles.socialButtons}>
                      <button className={styles.socialButton}>
                        <img
                          src={facebookIcon}
                          alt="Facebook"
                          className={styles.facebookIcon}
                        />
                      </button>
                      {/* Стилизация кнопки Google */}
                      <div className={styles.googleWrapper}>
                        <div
                          id="g_id_onload"
                          data-client_id={googleClientId}
                          data-context="signin"
                          data-ux_mode="popup"
                          data-callback="handleCredentialResponse"
                          data-auto_prompt="false"
                        ></div>
                        <div
                          className="g_id_signin"
                          data-type="icon"
                          data-theme="outline"
                          data-size="large"
                          data-logo_alignment="center"
                        ></div>
                        <div className={styles.customIcon}></div>
                      </div>
                      <button
                        className={styles.socialButton}
                        onClick={() =>
                          setAuthMethod(
                            authMethod === "phone" ? "email" : "phone"
                          )
                        }
                      >
                        <img
                          src={authMethod === "phone" ? emailIcon : phoneIcon}
                          alt="icon"
                          className={styles.icon}
                        />
                      </button>
                    </div>
                    <p className={styles.textToLogin}>
                      {t("registrationModal.haveAccount")}{" "}
                      <span
                        className={styles.spanLogin}
                        onClick={onSwitchToLogin}
                      >
                        {t("registrationModal.login")}
                      </span>
                    </p>
                  </>
                )}
              </div>
              <button className={styles.closeButton} onClick={onClose}>
                <img src={closeButtonIcon} alt="close button icon" />
              </button>
            </div>
          </div>
          {isMobile ? (
            ""
          ) : (
            <div className={styles.footerBlock}>
              <FooterBottom isMainPage={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;
