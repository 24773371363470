import { useState, useEffect, useRef } from "react";

// Функция для дебаунса
const debounce = (fn: Function, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
};

const useStickyScroll = () => {
  const [isStickyVisible, setIsStickyVisible] = useState(true); // Начальное состояние хедера - видимый
  const lastScrollY = useRef(0); // Сохраняем последний скролл
  const ticking = useRef(false); // Флаг, чтобы не запускать анимацию несколько раз подряд
  const scrollThreshold = 130; // Порог для скрытия хедера

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (ticking.current) return;

      ticking.current = true;
      const currentScroll = window.scrollY;

      // Пороговые значения для скролла
      if (currentScroll > scrollThreshold) {
        // Если прокручиваем вниз
        setIsStickyVisible(false); // Скрываем хедер
      } else if (currentScroll <= scrollThreshold) {
        // Если мы в верхней части страницы
        setIsStickyVisible(true); // Показываем хедер
      }

      lastScrollY.current = currentScroll;
      ticking.current = false; // Разрешаем следующее обновление
    }, 50);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isStickyVisible;
};

export default useStickyScroll;
