import { FC } from "react";
import styles from "./accountTypeSelector.module.scss";
import checkIcon from "../../assets/images/check-circle.png";
import { useTranslation } from "react-i18next";

interface IAccountTypeSelectorProps {
  selectedType: "individual" | "legal";
  onSelect: (type: "individual" | "legal") => void;
}

const AccountTypeSelector:FC<IAccountTypeSelectorProps> = ({
  selectedType,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div
        className={`${styles.option} ${
          selectedType === "individual" ? styles.selected : ""
        }`}
        onClick={() => onSelect("individual")}
      >
        {selectedType !== "individual" && (
          <div className={styles.checkBox}></div>
        )}
        {selectedType === "individual" && (
          <img src={checkIcon} alt="Selected" className={styles.icon} />
        )}
        <span>{t("accountTypeSelector.individual")}</span>
      </div>
      <div
        className={`${styles.option} ${
          selectedType === "legal" ? styles.selected : ""
        }`}
        onClick={() => onSelect("legal")}
      >
        {selectedType !== "legal" && <div className={styles.checkBox}></div>}
        {selectedType === "legal" && (
          <img src={checkIcon} alt="Selected" className={styles.icon} />
        )}
        <span>{t("accountTypeSelector.legal")}</span>
      </div>
    </div>
  );
};

export default AccountTypeSelector;
