import { FC } from "react";
import styles from "./roomsAndBedsModal.module.scss";
import iconButtonCLose from "../../assets/images/x.png";
import { useTranslation } from "react-i18next";
import minusIcon from "../../assets/images/minusIcon.png";
import plusIcon from "../../assets/images/plusIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { QueryState, updateFilters } from "../../store/querySlice";

interface IRoomsAndBedsModal {
  onClose: () => void;
}

const RoomsAndBedsModal: FC<IRoomsAndBedsModal> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const beds = useSelector(
    (state: { query: QueryState }) => state.query.filters.beds
  );
  const rooms = useSelector(
    (state: { query: QueryState }) => state.query.filters.rooms
  );

  const onChangeRooms = (change: number) => {
    const newRooms = rooms + change;
    dispatch(updateFilters({ rooms: newRooms >= 1 ? newRooms : 1 }));
  };
  const onChangeBeds = (change: number) => {
    const newBeds = beds + change;
    dispatch(updateFilters({ beds: newBeds >= 1 ? newBeds : 1 }));
  };

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <div className={styles.top}>
          <p className={styles.textTop}>
            {t("roomsAndBedsModal.roomsAndBeds")}
          </p>
          <img
            src={iconButtonCLose}
            alt="icon button close"
            className={styles.buttonClose}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
        </div>
        <div className={styles.item}>
          <p className={styles.textOption}>{t("roomsAndBedsModal.rooms")}</p>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChangeRooms(-1)}
              disabled={rooms <= 1}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{rooms === 5 ? "5+" : rooms}</span>
            <button
              className={styles.button}
              onClick={() => onChangeRooms(1)}
              disabled={rooms >= 5}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>
        <div className={styles.item}>
          <p className={styles.textOption}>{t("roomsAndBedsModal.beds")}</p>
          <div className={styles.controls}>
            <button
              className={styles.button}
              onClick={() => onChangeBeds(-1)}
              disabled={beds <= 1}
            >
              <img src={minusIcon} alt="minus icon" />
            </button>
            <span>{beds === 5 ? "5+" : beds}</span>
            <button
              className={styles.button}
              onClick={() => onChangeBeds(1)}
              disabled={beds >= 5}
            >
              <img src={plusIcon} alt="plus icon" />
            </button>
          </div>
        </div>
        <div className={styles.bottom}>
          <button
            className={styles.buttonDone}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            {t("roomsAndBedsModal.done")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default RoomsAndBedsModal;
