import { useLocation } from "react-router-dom";
import { convertToQueryState, getQueryParams } from "../utils/helpers";

export const useQueryParams = () => {
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  // Преобразуем параметры URL в нужный формат для Redux
  const formattedQueryParams = convertToQueryState(queryParams);

  return {
    queryParams,      
    formattedQueryParams,             
  };
};
