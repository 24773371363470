import React, { useState } from "react";
import { Review } from "../../types/review";
import styles from "./reviews.module.scss";
import starIcon from "../../assets/images/star.png";
import { useTranslation } from "react-i18next";

interface ReviewsProps {
  reviews: Review[];
}

const Reviews: React.FC<ReviewsProps> = ({ reviews }) => {
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState<number>(3); // Количество видимых отзывов
  const isMobile = window.innerWidth <= 1020;

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 3); // Показываем больше отзывов
  };

  return (
    <div className={styles["reviews"]} id="reviews">
      <div className={styles["reviews-top"]}>
        <h3 className={styles["reviews-heading"]}>
          {t("reviews.heading", { count: reviews.length })}
        </h3>
        {visibleCount < reviews.length && !isMobile && (
          <button
            className={styles["reviews-show-more"]}
            onClick={handleShowMore}
          >
            {t("reviews.showMore")}
          </button>
        )}
      </div>
      <p className={styles["reviews-subheading"]}>{t("reviews.subheading")}</p>
      <div className={styles["reviews-list"]}>
        {reviews.slice(0, visibleCount).map((review, index) => (
          <div key={index} className={styles["review-item"]}>
            <div className={styles.flex}>
              <div className={styles["review-avatar"]}>
                <img src={review.avatar} alt={`${review.name}'s avatar`} />
              </div>
              <div className={styles["review-content"]}>
                <h4 className={styles["review-name"]}>{review.name}</h4>
                <div className={styles["review-details"]}>
                  <div className={styles["review-rating"]}>
                    <img
                      src={starIcon}
                      alt="star icon"
                      className={styles["review-star-icon"]}
                    />
                    <span className={styles["review-rating-text"]}>
                      {review.rating}
                    </span>
                  </div>
                  <span className={styles["review-date"]}>
                    {isMobile ? review.mobileDate : review.date}
                  </span>
                  <span className={styles["review-location"]}>
                    {review.location}
                  </span>
                </div>
              </div>
            </div>
            <p className={styles["review-text"]}>{review.text}</p>
          </div>
        ))}
      </div>
      {visibleCount < reviews.length && isMobile && (
        <button
          className={styles["reviews-show-more"]}
          onClick={handleShowMore}
        >
          {t("reviews.showMore")}
        </button>
      )}
    </div>
  );
};

export default Reviews;
