import { FC, useState } from "react";
import styles from "./searchFilterMobileModal.module.scss";
import iconButtonCLose from "../../assets/images/x.png";
import { useTranslation } from "react-i18next";
import destinationIcon from "../../assets/images/mailGrey.png";
import calendarIcon from "../../assets/images/calendar.png";
import arrowDownIcon from "../../assets/images/arrow_down.png";
import SuggestionsModal from "../suggestionsModal/suggestionsModal";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { MobileCalendar } from "../mobileCalendar/mobileCalendar";
import RadiusModal from "../radiusModal/radiusModal";
import GuestModal from "../guestModal/guestModal";
import { useSelector } from "react-redux";
import { QueryState } from "../../store/querySlice";
import { parseDate } from "../../utils/helpers";

interface ISearchFilterMobileModal {
  onClose: () => void;
  location: string | undefined;
  onLocationChange: (value: string) => void;
  locationSuggestions: ISearchSuggestionItem[];
  onSelectSuggestion: (suggestion: ISearchSuggestionItem) => void;
  availableDates: string[];
  allowAllDates: boolean;
  isDisableSearchButton: boolean;
  handleSearch: () => void;
}

const SearchFilterMobileModal: FC<ISearchFilterMobileModal> = ({
  onClose,
  location,
  onLocationChange,
  locationSuggestions,
  onSelectSuggestion,
  availableDates,
  allowAllDates,
  isDisableSearchButton,
  handleSearch,
}) => {
  const state = useSelector((state: { query: QueryState }) => state.query);
  const checkInDate = state.checkIn ? parseDate(state.checkIn) : undefined;
  const checkOutDate = state.checkOut ? parseDate(state.checkOut) : undefined;
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      if (isModalOpen === null) {
        onClose();
      } else {
        setIsModalOpen(null);
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);

  const handleClose = () => {
    setIsModalOpen(null);
  };

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    onSelectSuggestion(suggestion);
    handleClose();
    if (!state.checkIn && !state.checkOut) {
      setIsModalOpen("calendarModal");
    }
  };

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      {isModalOpen === null ? (
        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.top}>
              <p className={styles.textTop}>
                {t("searchFilterMobileModal.findTheBest")}
              </p>
              <img
                src={iconButtonCLose}
                alt="icon button close"
                className={styles.buttonClose}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            </div>
            <button
              className={styles.destinationButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen("suggestionsModal");
              }}
            >
              <img
                className={styles.iconButton}
                src={destinationIcon}
                alt="destination icon"
              />
              <p className={styles.textInButton}>
                {location ? location : t("searchFilterMobileModal.destination")}
              </p>
            </button>
            <button
              className={styles.calendarButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen("calendarModal");
              }}
            >
              <img
                className={styles.iconButton}
                src={calendarIcon}
                alt="calendar icon"
              />
              <p className={styles.textInButton}>
                {checkInDate && checkOutDate
                  ? `${format(checkInDate, "d MMM", {
                      locale: currentLocale,
                    })} - 
                       ${format(checkOutDate, "d MMM", {
                         locale: currentLocale,
                       })}`
                  : t("searchFilterMobileModal.checkInCheckOut")}
              </p>
            </button>
            <div className={styles.buttons}>
              <button
                className={styles.radiusButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen("radiusModal");
                }}
              >
                <div className={styles.texts}>
                  <p className={styles.textTopInButton}>
                    {t("searchFilterMobileModal.radius")}
                  </p>
                  <p className={styles.textBottomInButton}>
                    {state.radius}
                    {t("searchFilterMobileModal.km")}
                  </p>
                </div>
                <img
                  src={arrowDownIcon}
                  alt="arrow down icon"
                  className={styles.arrowDownIcon}
                />
              </button>
              <button
                className={styles.guestsButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen("guestsModal");
                }}
              >
                <div className={styles.texts}>
                  <p className={styles.textTopInButton}>
                    {t("searchFilterMobileModal.guests")}
                  </p>
                  <p className={styles.textBottomInButton}>
                    {state.guestCount.adults + state.guestCount.children}
                  </p>
                </div>
                <img
                  src={arrowDownIcon}
                  alt="arrow down icon"
                  className={styles.arrowDownIcon}
                />
              </button>
            </div>
            <button
              className={styles.findButton}
              disabled={isDisableSearchButton}
              onClick={handleSearch}
            >
              {t("searchFilterMobileModal.findHome")}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {isModalOpen === "suggestionsModal" ? (
        <SuggestionsModal
          locationSuggestions={locationSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          onClose={handleClose}
          location={location}
          onLocationChange={onLocationChange}
        />
      ) : (
        ""
      )}
      {isModalOpen === "calendarModal" ? (
        <div
          className={styles["date-picker"]}
          onClick={(e) => e.stopPropagation()}
        >
          <MobileCalendar
            allowAllDates={allowAllDates}
            availableDatesSet={availableDatesSet}
            onClose={handleClose}
          />
        </div>
      ) : (
        ""
      )}
      {isModalOpen === "radiusModal" ? (
        <RadiusModal onClose={handleClose} />
      ) : (
        ""
      )}
      {isModalOpen === "guestsModal" ? (
        <GuestModal onClose={handleClose} />
      ) : (
        ""
      )}
    </div>
  );
};
export default SearchFilterMobileModal;
