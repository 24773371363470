import { useMemo, FC, useState, useEffect } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import { enUS, Locale, ru } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import styles from "./mobileCalendar.module.scss";
import iconButtonCLose from "../../assets/images/x.png";
import { format } from "date-fns";
import {
  calculateNights,
  formatDate,
  updateUrlDates,
} from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { updateDates } from "../../store/querySlice";
import {
  selectCheckIn,
  selectCheckOut,
} from "../../store/selectors/querySelectors";
import { useLocation, useNavigate } from "react-router-dom";

const locales: Record<string, Locale> = {
  en: enUS,
  ru: ru,
};

interface IMobileCalendar {
  onClose: () => void;
  allowAllDates: boolean;
  availableDatesSet: Set<string>;
}

export const MobileCalendar: FC<IMobileCalendar> = ({
  onClose,
  allowAllDates,
  availableDatesSet,
}) => {
  const { t, i18n } = useTranslation();
  const checkIn = useSelector(selectCheckIn);
  const checkOut = useSelector(selectCheckOut);
  const dispatch = useDispatch();
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(locationUrl.search);
  const [isBookingPage, setIsBookingPage] = useState(false);

  useEffect(() => {
    const testIsBookingPage = /^\/booking(-rent)?\/[^/]+$/.test(
      locationUrl.pathname
    );
    setIsBookingPage(testIsBookingPage);
  }, [locationUrl.pathname]);

  const customLocale = useMemo(() => {
    const baseLocale = locales[i18n.language as keyof typeof locales] || enUS;
    return {
      ...baseLocale,
      options: {
        ...baseLocale.options,
        weekStartsOn: 1, // Неделя начинается с понедельника
      },
    } as Partial<Locale>;
  }, [i18n.language]);

  const months = Array.from(
    { length: 16 },
    (_, i) => new Date(new Date().getFullYear(), new Date().getMonth() + i, 1)
  );

  // Обработчик выбора дат (диапазона)
  const handleSelect = (range: DateRange | undefined) => {
    if (!range) {
      dispatch(updateDates({ checkIn: undefined, checkOut: undefined }));
      if (isBookingPage) {
        updateUrlDates(
          navigate,
          locationUrl.pathname,
          searchParams,
          undefined,
          undefined
        );
      }
      return;
    }
    // Если уже выбраны checkIn и checkOut, сбрасываем и начинаем с новой даты
    if (checkIn && checkOut && checkIn.getTime() !== checkOut.getTime()) {
      if (range.from !== checkIn) {
        if (isBookingPage) {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            range.from,
            range.from
          );
        }
        dispatch(
          updateDates({
            checkIn: formatDate(range.from),
            checkOut: formatDate(range.from),
          })
        );
      } else {
        if (isBookingPage) {
          updateUrlDates(
            navigate,
            locationUrl.pathname,
            searchParams,
            range.to,
            range.to
          );
        }
        dispatch(
          updateDates({
            checkIn: formatDate(range.to),
            checkOut: formatDate(range.to),
          })
        );
      }
    } else {
      if (isBookingPage) {
        updateUrlDates(
          navigate,
          locationUrl.pathname,
          searchParams,
          range.from,
          range.to
        );
      }
      dispatch(
        updateDates({
          checkIn: formatDate(range.from),
          checkOut: formatDate(range.to),
        })
      );
    }
  };
  const currentLocale = i18n.language === "ru" ? ru : enUS;

  return (
    <div
      className={styles.contentMobileCalendar}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.top}>
        <p className={styles.textTop}>{t("MobileCalendar.calendar")}</p>
        <img
          src={iconButtonCLose}
          alt="icon button close"
          className={styles.buttonClose}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      </div>
      {/* Фиксированный заголовок дней недели */}
      <div className={styles.weekDaysBlock}>
        <table className={styles.weekdaysHeader}>
          <thead>
            <tr>
              {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                (dayKey) => (
                  <th key={dayKey} className={styles.day}>
                    {t(`MobileCalendar.weekdays.${dayKey}`)}
                  </th>
                )
              )}
            </tr>
          </thead>
        </table>
      </div>
      <div className={styles.calendarContainer}>
        {/* Список месяцев */}
        <div className={styles.monthsContainer}>
          {months.map((month, index) => (
            <div className={styles.monthContainer} key={index}>
              <DayPicker
                mode="range"
                month={month}
                numberOfMonths={1}
                locale={customLocale}
                showOutsideDays={false} // Убираем дни из других месяцев
                selected={{ from: checkIn, to: checkOut }}
                onSelect={handleSelect}
                disabled={[
                  {
                    before: new Date(),
                  },
                  (date) => {
                    if (allowAllDates) return false; // Если все даты разрешены, не блокируем
                    const formattedDate = format(date, "yyyyMMdd");
                    return !availableDatesSet.has(formattedDate);
                  },
                ]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        <p className={styles.textBottom}>
          {checkIn && checkOut
            ? `${format(checkIn, "d MMM", {
                locale: currentLocale,
              })} - ${format(checkOut, "d MMM", {
                locale: currentLocale,
              })}`
            : ""}{" "}
          {checkIn && checkOut ? "( " : ""}
          {checkIn && checkOut
            ? t("MobileCalendar.nights", {
                count: calculateNights(checkIn, checkOut),
              })
            : ""}
          {checkIn && checkOut ? " )" : ""}
        </p>
        <button
          className={styles.buttonDone}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          {t("MobileCalendar.done")}
        </button>
      </div>
    </div>
  );
};
