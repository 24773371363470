import { FC, useEffect, useState } from "react";
import styles from "./burgerMenu.module.scss";
import logoIcon from "../../assets/images/logo/logoBlue.png";
import iconButtonCLose from "../../assets/images/x.png";
import userIcon from "../../assets/images/user.png";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../loginPage/loginModal";
import { useAuth } from "../../context/authContext";
import avatarIcon from "../../assets/images/avatarInBurgerMenu.png";
import iconChevronRight from "../../assets/images/chevron-right.png";
import RegistrationModal from "../registrationModal/registrationModal";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";

interface IBurgerMenu {
  onClose: () => void;
}

const BurgerMenu: FC<IBurgerMenu> = ({ onClose }) => {
  const getUrlWithParams = usePreserveQueryParams();
  const [openLogin, setOpenLogin] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.top}>
        <img
          src={logoIcon}
          alt="logo icon"
          className={styles.logo}
          onClick={() => navigate(getUrlWithParams("/"))}
        />
        <img
          src={iconButtonCLose}
          alt="icon button close"
          className={styles.buttonClose}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      </div>
      {isAuthenticated ? (
        <div
          className={styles.profileBlock}
          onClick={() => navigate(getUrlWithParams("/profile"))}
        >
          <div className={styles.profileInfo}>
            <img
              src={avatarIcon}
              alt={"avatar icon"}
              className={styles.imageAvatar}
            />
            <div className={styles.profileInfoBlock}>
              <p className={styles.profileName}>Maria Ivanova</p>
              <p className={styles.profileEmail}>lvanova@gmail.com</p>
            </div>
          </div>
          <button className={styles.buttonGoToProfile}>
            <img
              src={iconChevronRight}
              alt="icon chevron right"
              className={styles.iconChevron}
            />
          </button>
        </div>
      ) : (
        <div className={styles.loginBlock} onClick={() => setOpenLogin(true)}>
          <p className={styles.text}>{t("burgerMenu.login")}</p>
          <img src={userIcon} alt="user icon" className={styles.userIcon} />
        </div>
      )}
      <LanguageSwitcher isBurgerMenu={true} />
      <div className={styles.links}>
        <Link to="/help" className={styles.link}>
          {t("burgerMenu.help")}
        </Link>
        <Link to="/terms" className={styles.link}>
          {t("burgerMenu.terms")}
        </Link>
        <Link to="/sitemap" className={styles.link}>
          {t("burgerMenu.sitemap")}
        </Link>
      </div>
      {openLogin ? (
        <LoginModal
          onClose={handleCloseLogin}
          isOpen={openLogin}
          onSwitchToRegister={() => {
            setOpenLogin(false);
            setIsRegisterOpen(true);
          }}
        />
      ) : (
        ""
      )}
      {isRegisterOpen ? (
        <RegistrationModal
          isOpen={isRegisterOpen}
          onClose={() => {
            setIsRegisterOpen(false);
          }}
          onSwitchToLogin={() => {
            setIsRegisterOpen(false);
            setOpenLogin(true);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BurgerMenu;
