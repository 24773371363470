import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardList from "../../components/cardList/cardList";
import styles from "./searchResult.module.scss";
import { Booking } from "../../types/booking";
import { fetchSearchResults } from "../../api/api";
import Header from "../../components/header/header";
import Pagination from "../../components/pagination/pagination";
import { useTranslation } from "react-i18next";
import sortingIcon from "../../assets/images/icon-for-search/sort-descending.png";
import arrowUpIcon from "../../assets/images/icon-for-search/sort-ascending.png";
import arrowDownIcon from "../../assets/images/icon-for-search/sort-descending.png";
import FooterBottom from "../../components/footerBottom/footerBottom";
import { useQueryParams } from "../../hooks/useQueryParams";
import MapWithPrices from "../../components/mapWithPrices/mapWithPrices";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import SortingModal from "../../components/sortingModal/soritingModal";
import listIcon from "../../assets/images/view-grid.png";
import listActiveIcon from "../../assets/images/view-grid-active.png";
import listAndMapIcon from "../../assets/images/view-list-map.png";
import listAndMapActiveIcon from "../../assets/images/view-list-map-active.png";
import mapIcon from "../../assets/images/map.png";
import mapActiveIcon from "../../assets/images/map-active.png";
import iconFilters from "../../assets/images/adjustments-black.png";
import FilterModal from "../../components/filterModal/filterModal";
import { buildSearchQueryString } from "../../utils/helpers";
import mapWhiteIcon from "../../assets/images/map-white.png";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
  updateRating,
  updateRentalCost,
} from "../../store/querySlice";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const SearchResult: React.FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { queryParams } = useQueryParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(Number(queryParams.page) || 1);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const itemsPerPage = 30; // Данный параметр задан на бэке
  const locationUrl = useLocation();
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  // Состояние для определения ввода лоакации - ручной или автоматический
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  // Состояние для автоподсказок
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });
  const [shouldSearch, setShouldSearch] = useState(false);
  const [viewMode, setViewMode] = useState<
    "listOnly" | "listAndMap" | "mapOnly"
  >("listOnly");
  const [isOpenSortingModal, setIsOpenSortingModal] = useState(false);
  const sortingModalRef = useRef<HTMLDivElement | null>(null);
  const sortingMobileModalRef = useRef<HTMLDivElement | null>(null);
  const sortingOptions = [
    "Price (lowest first)",
    "Price (highest first)",
    "Best reviewed & lowest price",
    "Rating (high to low)",
    "Rating (low to high)",
    "Top reviewed",
  ];
  const [selectedSortingOption, setSelectedSortingOption] = useState<
    string | null
  >(null);

  const getSortingIcon = (option: string | null) => {
    if (!option) return sortingIcon; // Значок по умолчанию, если сортировка не выбрана
    return option.includes("lowest") || option.includes("low to high")
      ? arrowDownIcon
      : arrowUpIcon;
  };

  const getMobileSortingText = (option: string | null) => {
    if (!option) return t("searchResult.sort"); // Показываем "Сортировать по" если не выбрано
    if (option.includes("Price")) return t("searchResult.price");
    if (option.includes("Rating")) return t("searchResult.rating");
    // TODO есть еще две опции - нужно подумать как их обозвать
    return t("searchResult.sort");
  };
  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;

    if (
      isOpenSortingModal &&
      sortingModalRef.current &&
      !sortingModalRef.current.contains(target) &&
      isOpenSortingModal &&
      sortingMobileModalRef.current &&
      !sortingMobileModalRef.current.contains(target)
    ) {
      setIsOpenSortingModal(false);
    }
  };

  useEffect(() => {
    if (isOpenSortingModal) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isOpenSortingModal]);

  const handleCloseSortingModal = () => {
    setIsOpenSortingModal(false);
  };

  const handleChangeSorting = (option: string) => {
    //TODO будем фильтровать по значению
    setSelectedSortingOption(option);
    handleCloseSortingModal();
    if (option === "Price (lowest first)") {
      dispatch(updateRentalCost("asc"));
      dispatch(updateRating(undefined));
    }
    if (option === "Price (highest first)") {
      dispatch(updateRentalCost("desc"));
      dispatch(updateRating(undefined));
    }
    if (option === "Best reviewed & lowest price") {
      dispatch(updateRentalCost("asc"));
      dispatch(updateRating(undefined)); // TODO надо будет еще один параметр добавить на счет ревью - когда он будет готов на бэке
    }
    if (option === "Rating (high to low)") {
      dispatch(updateRentalCost(undefined));
      dispatch(updateRating("desc"));
    }
    if (option === "Rating (low to high)") {
      dispatch(updateRentalCost(undefined));
      dispatch(updateRating("asc"));
    }
    if (option === "Top reviewed") {
      dispatch(updateRentalCost(undefined));
      dispatch(updateRating(undefined));
    }
    setShouldSearch(true);
  };

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };

  const handlePageChange = (page: number) => {
    const updatedParams = { ...queryParams, page: page.toString() };
    const searchParams = new URLSearchParams(
      updatedParams as Record<string, string>
    );
    navigate(`?${searchParams.toString()}`);
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      setError(null);

      try {
        const kindArray = queryParams["kind[]"]?.split(",");
        const data = await fetchSearchResults({
          lat: queryParams["geoPoint[lat]"],
          lon: queryParams["geoPoint[lon]"],
          distance: queryParams["geoPoint[distance]"],
          page: queryParams.page,
          from: queryParams["book[from]"],
          nights: queryParams["book[nights]"],
          kind: kindArray,
          numberBedrooms: queryParams["numBedrooms[gte]"],
          numberBathrooms: queryParams["numBathrooms[gte]"],
          numberBeds: queryParams["numBeds[gte]"],
          rentalCost: queryParams["rentalCost"],
        });
        setBookings(data["hydra:member"]);
        setTotalOffers(data["hydra:totalItems"]);
      } catch (err) {
        setError("Error fetching data from the server.");
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUrl.search]);

  const handleFilterClick = () => {
    setFilterModalOpen(true);
  };

  const handleCloseModal = () => {
    setFilterModalOpen(false);
  };

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  useEffect(() => {
    const handleScroll = () => {
      if (!paginationRef.current) return;

      const paginationTop = paginationRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY; // Получаем текущую прокрутку

      // Минимальная прокрутка для появления кнопки
      const minScrollThreshold = window.innerWidth <= 1020 ? 116 : 146;
      // Обновляем, показывать ли кнопку
      setShowFloatingButton(scrollY >= minScrollThreshold);
      // Обновляем, прижата ли кнопка к низу
      setIsAtBottom(paginationTop <= windowHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (loading) {
    return <div className={styles.loading}>{t("searchResult.loading")}</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.searchResult}>
      <div className={styles.section}>
        <Header
          location={location}
          onLocationChange={handleLocationChange}
          availableDates={[]}
          allowAllDates={true}
          locationSuggestions={locationSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          setShouldSearch={setShouldSearch}
          handleSearch={handleSearch}
        />
        {/* Фильтры для десктопа */}
        <div className={`${styles.filters} ${styles.desktop}`}>
          <div className={styles.filterActions}>
            <p className={styles.textOffers}>
              {t("searchResult.moreThan")}{" "}
              <span className={styles.bold}>
                {t("searchResult.offers", { count: totalOffers })}
              </span>
            </p>
            <div
              className={styles.sortingButton}
              onClick={() => setIsOpenSortingModal(true)}
            >
              <img
                src={getSortingIcon(selectedSortingOption)}
                alt="sorting icon"
                className={styles.icon}
              />
              {window.innerWidth <= 1020
                ? getMobileSortingText(selectedSortingOption)
                : selectedSortingOption
                ? t(`SortingModal.${selectedSortingOption}`)
                : t("SortingModal.sortBy")}
              {isOpenSortingModal && (
                <div ref={sortingModalRef}>
                  <SortingModal
                    onClose={handleCloseSortingModal}
                    onSelect={handleChangeSorting}
                    sortingOptions={sortingOptions}
                    selectedSortingOption={selectedSortingOption}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.viewButtons}>
            <button
              className={
                viewMode === "listOnly"
                  ? `${styles.buttonList} ${styles.activeButton}`
                  : styles.buttonList
              }
              onClick={() => setViewMode("listOnly")}
            >
              <img
                src={viewMode === "listOnly" ? listActiveIcon : listIcon}
                alt="list icon"
                className={styles.iconButton}
              />
              <p
                className={
                  viewMode === "listOnly"
                    ? `${styles.textInButton} ${styles.activeColorText}`
                    : styles.textInButton
                }
              >
                {t("searchResult.list")}
              </p>
            </button>
            <button
              className={
                viewMode === "listAndMap"
                  ? `${styles.buttonList} ${styles.activeButton}`
                  : styles.buttonList
              }
              onClick={() => setViewMode("listAndMap")}
            >
              <img
                src={
                  viewMode === "listAndMap"
                    ? listAndMapActiveIcon
                    : listAndMapIcon
                }
                alt="listAndMap icon"
                className={styles.iconButton}
              />
              <p
                className={
                  viewMode === "listAndMap"
                    ? `${styles.textInButton} ${styles.activeColorText}`
                    : styles.textInButton
                }
              >
                {t("searchResult.listAndMap")}
              </p>
            </button>
            <button
              className={
                viewMode === "mapOnly"
                  ? `${styles.buttonList} ${styles.activeButton}`
                  : styles.buttonList
              }
              onClick={() => setViewMode("mapOnly")}
            >
              <img
                src={viewMode === "mapOnly" ? mapActiveIcon : mapIcon}
                alt="map icon"
                className={styles.iconButton}
              />
              <p
                className={
                  viewMode === "mapOnly"
                    ? `${styles.textInButton} ${styles.activeColorText}`
                    : styles.textInButton
                }
              >
                {t("searchResult.mapOnly")}
              </p>
            </button>
          </div>
        </div>
        {/* Фильтры для мобильного разрешения */}
        <div className={styles.mobile}>
          <div className={styles.filtersMobile}>
            <div
              className={styles.sortingButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenSortingModal(true);
              }}
            >
              <img
                src={getSortingIcon(selectedSortingOption)}
                alt="sorting icon"
                className={styles.icon}
              />
              {window.innerWidth <= 1020
                ? getMobileSortingText(selectedSortingOption)
                : selectedSortingOption
                ? t(`SortingModal.${selectedSortingOption}`)
                : t("SortingModal.sortBy")}
              {isOpenSortingModal && (
                <div ref={sortingMobileModalRef}>
                  <SortingModal
                    onClose={handleCloseSortingModal}
                    onSelect={handleChangeSorting}
                    sortingOptions={sortingOptions}
                    selectedSortingOption={selectedSortingOption}
                  />
                </div>
              )}
            </div>
            <button
              className={styles.mobileButtonFilters}
              onClick={handleFilterClick}
            >
              <img
                src={iconFilters}
                alt="icon filters"
                className={styles.icon}
              />
              {t("searchResult.filter")}
            </button>
            {viewMode === "listOnly" ? (
              <button
                className={styles.buttonList}
                onClick={() => setViewMode("mapOnly")}
              >
                <img
                  src={mapIcon}
                  alt="map icon"
                  className={styles.iconButton}
                />
                <p className={styles.textInButton}>
                  {t("searchResult.mapOnly")}
                </p>
              </button>
            ) : (
              <button
                className={styles.buttonList}
                onClick={() => setViewMode("listOnly")}
              >
                <img
                  src={listIcon}
                  alt="list icon"
                  className={styles.iconButton}
                />
                <p className={styles.textInButton}>{t("searchResult.list")}</p>
              </button>
            )}
          </div>
        </div>
        {bookings.length > 0 ? (
          <section
            className={
              viewMode === "mapOnly"
                ? `${styles.content} ${styles.mobileContentForMap}`
                : styles.content
            }
          >
            {viewMode === "listOnly" && (
              <div className={styles["mb-60"]}>
                <CardList bookings={bookings} />
              </div>
            )}
            {viewMode === "listAndMap" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <CardList bookings={bookings} />
                <MapWithPrices
                  bookings={bookings}
                  lat={Number(queryParams["geoPoint[lat]"] || 0)}
                  lon={Number(queryParams["geoPoint[lon]"] || 0)}
                  onClose={() => setViewMode("mapOnly")}
                  viewMode={viewMode}
                />
              </div>
            )}
            {viewMode === "mapOnly" && (
              <MapWithPrices
                bookings={bookings}
                lat={Number(queryParams["geoPoint[lat]"] || 0)}
                lon={Number(queryParams["geoPoint[lon]"] || 0)}
                onClose={() => setViewMode("listOnly")}
                viewMode={viewMode}
              />
            )}
            {viewMode !== "mapOnly" && (
              <div ref={paginationRef} className={styles.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalItems={totalOffers}
                  itemsPerPage={itemsPerPage}
                  onPageChange={handlePageChange}
                />
                {showFloatingButton && viewMode === "listOnly" && (
                  <button
                    className={`${styles.floatingButton} ${
                      isAtBottom ? styles.atBottom : ""
                    }`}
                    onClick={() => {
                      setViewMode("mapOnly");
                    }}
                  >
                    <img
                      src={mapWhiteIcon}
                      alt="map icon"
                      className={styles.imageMapIcon}
                    />
                    <p className={styles.textShowmap}>
                      {" "}
                      {t("searchResult.showOnMap")}
                    </p>
                  </button>
                )}
              </div>
            )}
          </section>
        ) : (
          <p className={styles.noResults}>{t("searchResult.noResults")}</p>
        )}
      </div>
      <FooterBottom />
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={handleCloseModal}
        setShouldSearch={setShouldSearch}
      />
    </div>
  );
};

export default SearchResult;
