import busStopIcon from "../assets/images/icon-for-infrastructure/bus-stop.png";
import schoolIcon from "../assets/images/icon-for-infrastructure/school.png";
import restaurantIcon from "../assets/images/icon-for-infrastructure/restaurant.png";
import universityIcon from "../assets/images/icon-for-infrastructure/university.png";
import bankIcon from "../assets/images/icon-for-infrastructure/bank.png";
import libraryIcon from "../assets/images/icon-for-infrastructure/library.png";
import shopIcon from "../assets/images/icon-for-infrastructure/shop.png";
import sportsIcon from "../assets/images/icon-for-infrastructure/sports.png";
import parkingIcon from "../assets/images/icon-for-infrastructure/parking.png";
import pharmacyIcon from "../assets/images/icon-for-infrastructure/pharmacy.png";
import gasStationIcon from "../assets/images/icon-for-infrastructure/gas-station.png";
import hospitalIcon from "../assets/images/icon-for-infrastructure/hospital.png";
import { InfrastructureType } from "../types/infrastructureTypes";


export const infrastructureIcons: Record<InfrastructureType, string> = {
  [InfrastructureType.BUS_STOP]: busStopIcon,
  [InfrastructureType.SCHOOL]: schoolIcon,
  [InfrastructureType.RESTAURANT]: restaurantIcon,
  [InfrastructureType.UNIVERSITY]: universityIcon,
  [InfrastructureType.BANK]: bankIcon,
  [InfrastructureType.LIBRARY]: libraryIcon,
  [InfrastructureType.SHOP]: shopIcon,
  [InfrastructureType.SPORTS]: sportsIcon,
  [InfrastructureType.PARKING]: parkingIcon,
  [InfrastructureType.PHARMACY]: pharmacyIcon,
  [InfrastructureType.GAS_STATION]: gasStationIcon,
  [InfrastructureType.HOSPITAL]: hospitalIcon,
};
