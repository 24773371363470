import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./searchBlock.module.scss";
import locationIcon from "../../assets/images/icons-for-mainPage/location-marker.png";
import calendarIcon from "../../assets/images/icons-for-mainPage/calendar.png";
import radiusIcon from "../../assets/images/icons-for-mainPage/radius.png";
import usersIcon from "../../assets/images/icons-for-mainPage/users.png";
import roomsIcon from "../../assets/images/icons-for-mainPage/ion_bed.png";
import bedsIcon from "../../assets/images/icons-for-mainPage/beds.png";
import { useTranslation } from "react-i18next";
import adjustmentIcon from "../../assets/images/icons-for-mainPage/adjustments.png";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import RoomModal from "../roomModal/roomModal";
import BedsModal from "../bedsModal/bedsModal";
import RadiusModal from "../radiusModal/radiusModal";
import GuestModal from "../guestModal/guestModal";
import { TwoCalendarsRangePicker } from "../twoCalendarsRangePicker/twoCalendarsRangePicker";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import SuggestionsModal from "../suggestionsModal/suggestionsModal";
import chevronDownIcon from "../../assets/images/icons-for-mainPage/chevron-down.png";
import RoomsAndBedsModal from "../roomsAndBedsModal/roomsAndBedsModal";
import { MobileCalendar } from "../mobileCalendar/mobileCalendar";
import { useSelector } from "react-redux";
import { QueryState } from "../../store/querySlice";
import { parseDate } from "../../utils/helpers";
import clearButtonIcon from "../../assets/images/clearDestinationOnMainPage.png";

interface ISearchBlock {
  handleSearch: () => void;
  location: string | undefined;
  onLocationChange: (value: string) => void;
  availableDates: string[];
  allowAllDates: boolean;
  locationSuggestions: ISearchSuggestionItem[];
  onSelectSuggestion: (suggestion: ISearchSuggestionItem) => void;
}

const SearchBlock: React.FC<ISearchBlock> = ({
  handleSearch,
  location,
  onLocationChange,
  availableDates,
  allowAllDates,
  locationSuggestions,
  onSelectSuggestion,
}) => {
  const state = useSelector((state: { query: QueryState }) => state.query);
  const checkInDate = state.checkIn ? parseDate(state.checkIn) : undefined;
  const checkOutDate = state.checkOut ? parseDate(state.checkOut) : undefined;
  const isMobile = window.innerWidth <= 768;
  const { t, i18n } = useTranslation();
  const [isDisableSearchButton, setIsDisableSearchButton] = useState(true);

  useEffect(() => {
    if (state.lat && state.lon) {
      setIsDisableSearchButton(false);
    }
  }, [state.lat, state.lon]);
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const radiusRef = useRef<HTMLDivElement | null>(null);
  const guestsRef = useRef<HTMLDivElement | null>(null);
  const roomsRef = useRef<HTMLDivElement | null>(null);
  const bedsRef = useRef<HTMLDivElement | null>(null);
  const radiusRefMobile = useRef<HTMLDivElement | null>(null);
  const guestsRefMobile = useRef<HTMLDivElement | null>(null);
  const roomsAndBedsMobile = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
  const isDesktop = window.innerWidth >= 1440;

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    onSelectSuggestion(suggestion);
    setTimeout(() => {
      handleClose(); // Закрываем модалку с небольшой задержкой
    }, 0);
  };
  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;

    if (
      (isModalOpen === "suggestions" &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(target)) ||
      (isModalOpen === "calendar" &&
        calendarRef.current &&
        !calendarRef.current.contains(target)) ||
      (isModalOpen === "radius" &&
        radiusRef.current &&
        !radiusRef.current.contains(target)) ||
      (isModalOpen === "guests" &&
        guestsRef.current &&
        !guestsRef.current.contains(target)) ||
      (isModalOpen === "rooms" &&
        roomsRef.current &&
        !roomsRef.current.contains(target)) ||
      (isModalOpen === "beds" &&
        bedsRef.current &&
        !bedsRef.current.contains(target)) ||
      (isModalOpen === "radiusMobile" &&
        radiusRefMobile.current &&
        !radiusRefMobile.current.contains(target)) ||
      (isModalOpen === "guestsMobile" &&
        guestsRefMobile.current &&
        !guestsRefMobile.current.contains(target)) ||
      (isModalOpen === "roomsAndBedsMobile" &&
        roomsAndBedsMobile.current &&
        !roomsAndBedsMobile.current.contains(target))
    ) {
      setIsModalOpen(null);
    }
  };

  const handleClose = useCallback(() => {
    setIsModalOpen(null);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isModalOpen]);

  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  const handleClick = () => {
    handleSearch();
  };

  const handleFocus = () => {
    setIsModalOpen("suggestions");
  };

  const handleSuggestionPointerDown = (event: React.PointerEvent) => {
    // Предотвращаем потерю фокуса
    event.preventDefault();
  };

  const toggleModal = (modalName: string) => {
    setIsModalOpen((prev) => (prev === modalName ? null : modalName));
  };

  return (
    <div className={styles.searchBlock}>
      <div className={styles.inputs}>
        <div
          className={styles.inputDestinationBlock}
          onClick={() => {
            if (isMobile) {
              setIsModalOpen("suggestions");
            }
          }}
        >
          <div className={styles.imageDestinationBlock}>
            <img
              src={locationIcon}
              alt="location icon"
              className={styles.inputDestinationImage}
            />
          </div>
          <input
            value={location}
            onChange={(e) => onLocationChange(e.target.value)}
            className={styles.inputDestination}
            placeholder={t("searchBlock.placeholder")}
            onFocus={isMobile ? undefined : handleFocus}
            readOnly={isMobile ? true : false}
          />
          {!isMobile && location && location.length > 2 ? (
            <div className={styles.clearButtonBlock}>
              <button
                className={styles.clearButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onLocationChange("");
                }}
              >
                <img
                  src={clearButtonIcon}
                  alt="clear button icon"
                  className={styles.clearButtonIcon}
                />
              </button>
            </div>
          ) : (
            ""
          )}
          {isModalOpen === "suggestions" &&
            (isMobile ? (
              <div
                ref={suggestionsRef}
                onPointerDown={handleSuggestionPointerDown}
              >
                <SuggestionsModal
                  locationSuggestions={locationSuggestions}
                  onSelectSuggestion={handleSelectSuggestion}
                  onClose={handleClose}
                  location={location}
                  onLocationChange={onLocationChange}
                />
              </div>
            ) : (
              locationSuggestions.length > 0 && (
                <div
                  ref={suggestionsRef}
                  onPointerDown={handleSuggestionPointerDown}
                >
                  <SuggestionsModal
                    locationSuggestions={locationSuggestions}
                    onSelectSuggestion={handleSelectSuggestion}
                    onClose={handleClose}
                    location={location}
                    onLocationChange={onLocationChange}
                  />
                </div>
              )
            ))}
        </div>
        <div className={styles.row}>
          <div
            className={styles.inputCalendarBlock}
            onClick={() => toggleModal("calendar")}
            ref={calendarRef}
          >
            <div className={styles.imageCalendarBlock}>
              <img
                src={calendarIcon}
                alt="calendar icon"
                className={styles.inputCalendarIcon}
              />
            </div>
            <div className={styles.textInCalendarBlock}>
              <div
                className={
                  state.checkIn && state.checkOut
                    ? styles.inputCalendar
                    : `${styles.inputCalendar} ${styles.halfOpacity}`
                }
              >
                {checkInDate && checkOutDate
                  ? `${format(checkInDate, "d MMM", {
                      locale: currentLocale,
                    })} - 
                       ${format(checkOutDate, "d MMM", {
                         locale: currentLocale,
                       })}`
                  : t("searchBlock.calendarCheckIn")}
              </div>
              {state.checkIn && state.checkOut ? (
                ""
              ) : (
                <div className={styles.separator}>|</div>
              )}
              <div
                className={
                  state.checkIn && state.checkOut
                    ? styles.inputCalendar
                    : `${styles.inputCalendar} ${styles.halfOpacity}`
                }
              >
                {state.checkIn && state.checkOut
                  ? ""
                  : t("searchBlock.calendarCheckOut")}
              </div>
            </div>
            {/* Date Picker */}
            {isModalOpen === "calendar" &&
              (isDesktop ? (
                <div
                  className={styles["date-picker"]}
                  onClick={(e) => e.stopPropagation()}
                >
                  <TwoCalendarsRangePicker
                    currentLocale={currentLocale}
                    allowAllDates={allowAllDates}
                    availableDatesSet={availableDatesSet}
                  />
                </div>
              ) : (
                <MobileCalendar
                  onClose={handleClose}
                  allowAllDates={allowAllDates}
                  availableDatesSet={availableDatesSet}
                />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.mobileRow}>
        <div
          className={styles.optionMobileRadius}
          onClick={() => toggleModal("radiusMobile")}
        >
          <div className={styles.textsInButton}>
            <p className={styles.smallTextInMobileButton}>
              {t("searchBlock.radius")}
            </p>
            <p className={styles.textInMobileButton}>
              {state.radius}
              {t("searchBlock.radiusResult")}
            </p>
          </div>
          <img
            src={chevronDownIcon}
            alt="chevron down icon"
            className={styles.chevronIcon}
          />
          {isModalOpen === "radiusMobile" && (
            <div ref={radiusRefMobile}>
              <RadiusModal onClose={handleClose} />
            </div>
          )}
        </div>
        <div
          className={styles.optionMobileGuest}
          onClick={() => toggleModal("guestsMobile")}
        >
          <div className={styles.textsInButton}>
            <p className={styles.smallTextInMobileButton}>
              {t("searchBlock.guest")}
            </p>
            <p className={styles.textInMobileButton}>
              {state.guestCount.adults + state.guestCount.children}
            </p>
          </div>
          <img
            src={chevronDownIcon}
            alt="chevron down icon"
            className={styles.chevronIcon}
          />
          {isModalOpen === "guestsMobile" && (
            <div ref={guestsRefMobile}>
              <GuestModal onClose={handleClose} />
            </div>
          )}
        </div>
        <button
          className={styles.adjustmentButton}
          onClick={() => toggleModal("roomsAndBedsMobile")}
        >
          <img
            src={adjustmentIcon}
            alt="adjustment icon"
            className={styles.adjustmentIcon}
          />
        </button>
        {isModalOpen === "roomsAndBedsMobile" && (
          <div ref={roomsAndBedsMobile}>
            <RoomsAndBedsModal onClose={handleClose} />
          </div>
        )}
      </div>
      <div className={styles.options}>
        <div
          className={`${styles.option} ${styles.flex1}`}
          onClick={() => toggleModal("radius")}
          ref={radiusRef}
        >
          <img
            src={radiusIcon}
            alt="radius icon"
            className={styles.radiusIcon}
          />
          <p className={styles.optionText}>{t("searchBlock.radius")}</p>
          <p className={styles.optionResult}>
            {state.radius}
            {t("searchBlock.radiusResult")}
          </p>
          {isModalOpen === "radius" && <RadiusModal onClose={handleClose} />}
        </div>
        <div
          className={styles.option}
          ref={guestsRef}
          onClick={() => toggleModal("guests")}
        >
          <img src={usersIcon} alt="users icon" className={styles.usersIcon} />
          <p className={styles.optionText}>{t("searchBlock.guest")}</p>
          <p className={styles.optionResult}>
            {state.guestCount.adults + state.guestCount.children}
          </p>
          {isModalOpen === "guests" && <GuestModal onClose={handleClose} />}
        </div>
        <div
          className={styles.option}
          ref={roomsRef}
          onClick={() => toggleModal("rooms")}
        >
          <img src={roomsIcon} alt="rooms icon" className={styles.radiusIcon} />
          <p className={styles.optionText}>{t("searchBlock.rooms")}</p>
          <p className={styles.optionResult}>{state.filters.rooms}</p>
          {isModalOpen === "rooms" && <RoomModal />}
        </div>
        <div
          className={styles.option}
          ref={bedsRef}
          onClick={() => toggleModal("beds")}
        >
          <img src={bedsIcon} alt="beds icon" className={styles.radiusIcon} />
          <p className={styles.optionText}>{t("searchBlock.beds")}</p>
          <p className={styles.optionResult}>{state.filters.beds}</p>
          {isModalOpen === "beds" && <BedsModal />}
        </div>
        <button
          className={styles.findButton}
          onClick={handleClick}
          disabled={isDisableSearchButton}
        >
          {t("searchBlock.findButton")}
        </button>
      </div>
    </div>
  );
};

export default SearchBlock;
