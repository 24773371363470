import React from "react";
import styles from "./articleNavigation.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface IArticleInfo {
  id: string;
  title: {
    ru: string;
    en: string;
  };
}

interface IArticleNavigationProps {
  previous?: IArticleInfo;
  next?: IArticleInfo;
}

const ArticleNavigation: React.FC<IArticleNavigationProps> = ({ previous, next }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as "ru" | "en";

  return (
    <div className={styles.wrapper}>
      {previous && (
        <div className={styles.column}>
          <p className={styles.label}>{t("articleNavigation.previous")}</p>
          <Link to={`/post/${previous.id}`} className={styles.link}>
            {previous.title[lang]}
          </Link>
        </div>
      )}
      {next && (
        <div className={styles.column}>
          <p className={styles.label}>{t("articleNavigation.next")}</p>
          <Link to={`/post/${next.id}`} className={styles.link}>
            {next.title[lang]}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ArticleNavigation;
