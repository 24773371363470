import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./shareFavoriteBar.module.scss";
import shareIcon from "../../assets/images/share_icon.png";
import favoriteIcon from "../../assets/images/favorite_icon.png";
import { addToFavorites } from "../../utils/helpers";
import iconHeartFavorite from "../../assets/images/heart_blue.png";
import { useNavigate } from "react-router-dom";
import iconButtonGoBack from "../../assets/images/chevron-left.png";

interface IShareFavoriteBar {
  title: string;
  bookingId: string;
}
const ShareFavoriteBar: FC<IShareFavoriteBar> = ({ bookingId, title }) => {
  const { t } = useTranslation();
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();

  // При монтировании проверяем, есть ли карточка в избранных
  useEffect(() => {
    const favoriteBookings = JSON.parse(
      localStorage.getItem("favoriteBookings") || "[]"
    );
    const isCardFavorite = favoriteBookings.some(
      (item: { id: string }) => item.id === bookingId
    );
    setIsFavorite(isCardFavorite);
  }, [bookingId]);

  const handleAddToFavorites = () => {
    addToFavorites(bookingId);
    setIsFavorite(true);
  };

  return (
    <div className={styles["title-block"]}>
      <div>
      <h2 className={styles["title-booking"]}>{title}</h2>
      <button className={styles.buttonGoBack} onClick={() => navigate(-1)}>
          <img
            src={iconButtonGoBack}
            alt="icon button go back"
            className={styles.iconButtonGoBack}
          />
          <p className={styles.textInButtonGoBack}>
            {t("FavoritesPage.goBack")}
          </p>
        </button>
      </div>
      <div className={styles["title-block-right"]}>
        <div className={styles["block-share"]}>
          <img
            src={shareIcon}
            alt={t("shareFavoriteBar.shareIconAlt")}
            className={styles["share-icon"]}
          />
          <span className={styles["share-text"]}>
            {t("shareFavoriteBar.share")}
          </span>
        </div>
        <div
          className={styles["favorite-block"]}
          onClick={handleAddToFavorites}
        >
          <img
            src={isFavorite ? iconHeartFavorite : favoriteIcon}
            alt={t("shareFavoriteBar.favoriteIconAlt")}
            className={styles["favorite-icon"]}
          />
          <span className={styles["favorite-text"]}>
            {t("shareFavoriteBar.toFavorites")}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ShareFavoriteBar;
