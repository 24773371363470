import { FC, useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import styles from "./bookingDetailsModal.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/images/icon-for-search/x.png";
import { Booking } from "../../types/booking";
import PhotoGallery from "../photoGallery/photoGallery";
import hostIcon from "../../assets/images/host.png";
import reviewsIcon from "../../assets/images/reviews_icon.png";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import calendarIcon from "../../assets/images/calendar.png";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import guestsIcon from "../../assets/images/users.png";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import GuestModal from "../guestModal/guestModal";
import { TwoCalendarsRangePicker } from "../twoCalendarsRangePicker/twoCalendarsRangePicker";
import { checkAvailability } from "../../api/api";
import {
  calculateNights,
  formatDate,
  formatPrice,
  getImageUrl,
  parseDate,
} from "../../utils/helpers";
import { useNotification } from "../../context/notificationContext";
import { CURRENCYSYMBOL } from "../../utils/const";
import { useDispatch } from "react-redux";
import { updateDates, updateGuestCount } from "../../store/querySlice";
import { useQueryParams } from "../../hooks/useQueryParams";
import logoIcon from "../../assets/images/logo/logo-grey.png";

interface IBookingDeatilsModal {
  isOpen: boolean;
  onClose: () => void;
  booking: Booking;
  nights: number | undefined;
}

const BookingDetailsModal: FC<IBookingDeatilsModal> = ({
  isOpen,
  onClose,
  booking,
  nights,
}) => {
  const dispatch = useDispatch();
  const { queryParams } = useQueryParams();
  const [isOpenPhotoGallery, setIsOpenPhotoGallery] = useState(false);
  const [isGuestModal, setIsGuestModal] = useState(false);
  const guestsRefModal = useRef<HTMLDivElement | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const { showNotification } = useNotification();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const getUrlWithParams = usePreserveQueryParams();
  const [localGuestCount, setLocalGuestCount] = useState({
    adults: queryParams["adults"] ? Number(queryParams["adults"]) : 1,
    children: queryParams["children"] ? Number(queryParams["children"]) : 0,
    infants: queryParams["infants"] ? Number(queryParams["infants"]) : 0,
    pets: queryParams["pets"] ? Number(queryParams["pets"]) : 0,
  });
  const [localDates, setLocalDates] = useState({
    checkIn: parseDate(queryParams["book[from]"]),
    checkOut: parseDate(queryParams["book[to]"]),
  });
  const handleChangeDates = (
    checkIn: Date | undefined,
    checkOut: Date | undefined
  ) => {
    setLocalDates({ checkIn, checkOut });
  };
  const handleGuestLimitExceeded = () => {
    showNotification(t("bookingDetailsModal.guestLimitExceeded"), "warning");
  };

  const handleChangeGuests = (
    type: "adults" | "children" | "infants" | "pets",
    change: number
  ) => {
    setLocalGuestCount((prev) => {
      const newCount = prev[type] + change;

      if (newCount < 0) return prev;

      // Проверяем, что сумма взрослых и детей не превышает максимальное кол-во гостей
      if (
        (type === "adults" || type === "children") &&
        prev.adults + prev.children + change > booking.numGuests
      ) {
        handleGuestLimitExceeded();
        return prev;
      }

      return { ...prev, [type]: newCount };
    });
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Node;
    if (
      (isDatePickerOpen &&
        datePickerRef.current &&
        !datePickerRef.current.contains(target)) ||
      (isGuestModal &&
        guestsRefModal.current &&
        !guestsRefModal.current.contains(target))
    ) {
      setIsGuestModal(false);
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen || isGuestModal) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuestModal, isDatePickerOpen]);

  useEffect(() => {
    if (isOpen) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, [isOpen]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderPhotoBlock = (index: number, width: number, height: number) => {
    const photo = booking.objectPhotos[index];

    return (
      <>
        {photo?.storageUrl ? (
          <img
            src={getImageUrl(photo.storageUrl, width, height)}
            alt={photo.description || "Photo"}
            className={styles.image}
          />
        ) : (
          <div className={styles.noImage}>
            <img src={logoIcon} alt="logo" className={styles.iconLogo} />
            <p className={styles.textNoPhoto}>
              {t("bookingDetailsModal.noPhotos")}
            </p>
          </div>
        )}
      </>
    );
  };

  const name = "Ann"; //TODO захардкодил, так как пока инфа не приходит с бэкенда
  const reviewsCount = 3; //TODO захардкодил, так как пока инфа не приходит с бэкенда
  // Стили для кастомного маркера
  const customMarkerStyle = `
   width: 14px;
   height: 14px;
   border-radius: 33px;
   background: #7623FF;
   border: 2px solid #FFFFFF66;
   box-shadow: 0px 4px 4px 0px #00000094;
 `;

  const customIcon = L.divIcon({
    className: "custom-marker",
    html: `<div style="${customMarkerStyle}"></div>`,
  });
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const allowAllDates = false;

  const fetchAvailabilityData = async () => {
    try {
      const response = await checkAvailability(booking.id);
      const dates = response.data["hydra:member"];
      if (Array.isArray(dates)) {
        setAvailableDates(dates);
      } else {
        setAvailableDates([]);
      }
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };
  // Преобразуем доступные даты в Set для быстрой проверки
  const availableDatesSet = new Set(
    availableDates.map((date) => date.toString())
  );

  useEffect(() => {
    if (booking.id) {
      fetchAvailabilityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.id]);

  const updateStoreParams = (url: string) => {
    const additionalParams: Record<string, string | number | undefined> = {
      "book[from]": formatDate(localDates.checkIn),
      "book[to]": formatDate(localDates.checkOut),
      adults: localGuestCount.adults,
      children: localGuestCount.children,
      infants: localGuestCount.infants,
      pets: localGuestCount.pets,
    };
    if (localDates.checkIn && localDates.checkOut) {
      additionalParams["book[nights]"] = calculateNights(
        localDates.checkIn,
        localDates.checkOut
      ).toString();
    }
    dispatch(
      updateGuestCount({
        type: "adults",
        value: localGuestCount.adults,
      })
    );
    dispatch(
      updateGuestCount({
        type: "children",
        value: localGuestCount.children,
      })
    );
    dispatch(
      updateGuestCount({
        type: "infants",
        value: localGuestCount.infants,
      })
    );
    dispatch(
      updateGuestCount({
        type: "pets",
        value: localGuestCount.pets,
      })
    );
    dispatch(
      updateDates({
        checkIn: formatDate(localDates.checkIn),
        checkOut: formatDate(localDates.checkOut),
      })
    );
    const updatedUrl = getUrlWithParams(
      `/${url}/${booking.id}`,
      additionalParams
    );
    return updatedUrl;
  };

  const handleClickBooking = () => {
    if (!localDates.checkIn || !localDates.checkOut) {
      showNotification(t("bookingDetailsModal.selectDates"), "warning");
      return;
    }
    const updatedUrl = updateStoreParams("booking-rent");
    navigate(updatedUrl);
  };

  const handleClickDetails = () => {
    const updatedUrl = updateStoreParams("booking");
    navigate(updatedUrl);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <img src={closeIcon} alt="icon close button" />
        </button>
        <div className={styles.modalContent}>
          <div className={styles.top}>
            <div className={styles.firstColumn}>
              {renderPhotoBlock(0, 444.5, 496)}
            </div>
            <div className={styles.secondColumn}>
              <div className={styles.secondImageBlock}>
                {renderPhotoBlock(1, 218.25, 244)}
              </div>
              <div className={styles.secondImageBlock}>
                {renderPhotoBlock(2, 218.25, 244)}
              </div>
            </div>
            <div className={styles.threeColumn}>
              <div className={styles.secondImageBlock}>
                {renderPhotoBlock(3, 218.25, 244)}
              </div>
              <div className={`${styles.secondImageBlock} ${styles.relative}`}>
                {renderPhotoBlock(4, 218.25, 244)}
                {booking.objectPhotos.length > 5 ? (
                  <div
                    className={styles.showAllPhotosBlock}
                    onClick={() => setIsOpenPhotoGallery(true)}
                  >
                    <p className={styles.quantityPhotos}>
                      +{booking.objectPhotos.length - 5}
                    </p>
                    <p className={styles.textShowPhotos}>
                      {t("bookingDetailsModal.showAllPhotos")}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={styles.middle}>
            <div className={styles.middleLeftBlock}>
              <h3 className={styles.title}>{booking.name}</h3>
              <p className={styles["parameters-block"]}>
                {`${t("bookingDetailsModal.guests", {
                  count: booking.numGuests,
                })} | 
    ${t("bookingDetailsModal.bedrooms", { count: booking.numBedrooms })} | 
    ${t("bookingDetailsModal.beds", { count: booking.numBeds })} | 
    ${t("bookingDetailsModal.baths", { count: booking.numBathrooms })}`}
              </p>
              <div className={styles.hostIfno}>
                <img
                  className={styles.imageHost}
                  src={hostIcon}
                  alt="host icon"
                />
                <p className={styles.hostName}>
                  {t("bookingDetailsModal.hostedBy", { name })}
                </p>
                <img
                  className={styles.reviewImage}
                  src={reviewsIcon}
                  alt="reviews icon"
                />
                <p className={styles.textReviews}>
                  {t("bookingDetailsModal.reviews", { count: reviewsCount })}
                </p>
              </div>
            </div>
            <div className={styles.middleRightBlock}>
              <MapContainer
                center={[booking.geoPoint.lat, booking.geoPoint.lon]}
                zoom={13}
                scrollWheelZoom={false}
                className={styles["leaflet-map"]}
                zoomControl={false}
              >
                <TileLayer
                  url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${i18n.language}`}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                <Marker
                  position={[booking.geoPoint.lat, booking.geoPoint.lon]}
                  icon={customIcon}
                ></Marker>
              </MapContainer>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottomLeftBlock}>
              <div className={styles.dateBlock} onClick={toggleDatePicker}>
                <img
                  className={styles.iconDate}
                  src={calendarIcon}
                  alt="calendar icon"
                />
                <p className={styles.textDate}>
                  {localDates.checkIn && localDates.checkOut
                    ? `${format(localDates.checkIn, "d MMM", {
                        locale: currentLocale,
                      })} - 
                         ${format(localDates.checkOut, "d MMM", {
                           locale: currentLocale,
                         })}`
                    : t("bookingDetailsModal.dates")}
                </p>
                {/* Date Picker */}
                {isDatePickerOpen && (
                  <div
                    className={styles["date-picker"]}
                    ref={datePickerRef}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <TwoCalendarsRangePicker
                      currentLocale={currentLocale}
                      allowAllDates={allowAllDates}
                      availableDatesSet={availableDatesSet}
                      useCustomData={true}
                      handleChangeDates={handleChangeDates}
                      checkInLocal={localDates.checkIn}
                      checkOutLocal={localDates.checkOut}
                    />
                  </div>
                )}
              </div>
              <div
                className={styles.guestBlock}
                onClick={() => setIsGuestModal(true)}
              >
                <img
                  src={guestsIcon}
                  alt="guests icon"
                  className={styles.guestsIcon}
                />
                <p className={styles.textGuests}>
                  {" "}
                  {localGuestCount.adults + (localGuestCount.children || 0)}
                </p>
                {isGuestModal && (
                  <div ref={guestsRefModal}>
                    <GuestModal
                      onClose={() => setIsGuestModal(false)}
                      guestCount={localGuestCount}
                      handleChangeGuests={handleChangeGuests}
                      useCustomData={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.bottomRightBlock}>
              <p className={styles.totalCostText}>
                {" "}
                {nights ? (
                  booking.rentalCost ? (
                    `${CURRENCYSYMBOL} ${formatPrice(booking.rentalCost)}`
                  ) : (
                    ""
                  )
                ) : booking.pricePerNight ? (
                  ""
                ) : (
                  <span
                    className={styles.textShowCost}
                    onClick={(e) => {
                      e.stopPropagation();
                      showNotification(
                        t("bookingDetailsModal.showNotification"),
                        "warning"
                      );
                    }}
                  >
                    {t("bookingDetailsModal.showCost")}
                  </span>
                )}
              </p>
              <p className={styles.costForNightText}>
                {nights && booking.rentalCost
                  ? `${CURRENCYSYMBOL} ${formatPrice(
                      booking.rentalCost / nights
                    )}/${t("card.night")}`
                  : booking.pricePerNight
                  ? `${CURRENCYSYMBOL} ${formatPrice(
                      booking.pricePerNight
                    )}/${t("card.night")}`
                  : ""}
              </p>
              <button
                className={styles.buttonBooking}
                onClick={handleClickBooking}
              >
                <p className={styles.paddingInButton}>
                  {t("bookingDetailsModal.booking")}
                </p>
              </button>
              <button
                className={styles.buttonDetails}
                onClick={handleClickDetails}
              >
                <p className={styles.paddingInButton}>
                  {t("bookingDetailsModal.details")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpenPhotoGallery && (
        <PhotoGallery
          photos={booking.objectPhotos}
          onClose={() => setIsOpenPhotoGallery(false)}
        />
      )}
    </div>
  );
};
export default BookingDetailsModal;
