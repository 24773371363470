import React, { useState } from "react";
import { Booking } from "../../types/booking";
import PhotoGallery from "../photoGallery/photoGallery";
import BookingDetails from "../bookingDetails/bookingDetails";
import PhotoPreview from "../photoPreview/photoPreview";
import RoomGallery from "../roomGallery/roomGallery";
import PlaceFeatures from "../placeFeatures/placeFeatures";
import HostInfo from "../hostIfno/hostInfo";
import hostAvatar from "../../assets/images/host.png";
import ListingDescription from "../listingDescription/listingDescription";
import homeIcon from "../../assets/images/icons-for-listing-description/home.png";
import poolIcon from "../../assets/images/icons-for-listing-description/pool.png";
import starIcon from "../../assets/images/icons-for-listing-description/star.png";
import styles from "./leftContent.module.scss";

interface LeftContentProps {
  booking: Booking;
}

const LeftContent: React.FC<LeftContentProps> = ({ booking }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activeIndexPhoto, setActiveIndexPhoto] = useState<undefined | number>(
    undefined
  );
  const [galleryPhotos, setGalleryPhotos] = useState(booking.objectPhotos);
  // 🔹 Отфильтрованные фото для комнаты (например, bathroom)
  const bathroomPhotos = booking.objectPhotos.filter(
    (photo) => photo //.semanticId === "bathroom"
  );

  const handleOpenGallery = (
    indexPhoto?: number,
    photos = booking.objectPhotos
  ) => {
    setGalleryPhotos(photos); // ← сохраняем фото, которые будем показывать в модалке
    setActiveIndexPhoto(indexPhoto);
    setIsGalleryOpen(true);
  };

  const handleCloseGallery = () => {
    setIsGalleryOpen(false);
    setActiveIndexPhoto(undefined);
  };

  return (
    <div className="left-content">
      {/*фотогалерея */}
      <PhotoPreview
        photos={booking.objectPhotos}
        onShowAll={handleOpenGallery}
      />
      {/* Модальная фотогалерея */}
      {isGalleryOpen && (
        <PhotoGallery
          photos={galleryPhotos}
          onClose={handleCloseGallery}
          activeIndexPhoto={activeIndexPhoto}
        />
      )}

      {/* Остальное содержимое */}
      <h1 className={styles.mobileTitle}>{booking.name}</h1>
      <BookingDetails booking={booking} />
      <HostInfo avatarUrl={hostAvatar} name="Ann" hostingYears={4} />
      <ListingDescription
        features={[
          {
            id: "self_check_in",
            icon: homeIcon,
            name: "Self check-in",
            description: "Check yourself in with the lockbox.",
          },
          {
            id: "pool",
            icon: poolIcon,
            name: "Dive right in",
            description:
              "This is one of the few places in the area with a pool.",
          },
          {
            id: "experienced_host",
            icon: starIcon,
            name: "Experienced host",
            description: "Galina has 61 reviews for other places.",
          },
        ]}
        translatedInfo="Some info has been automatically translated."
        originalInfo="The spacious apartment of 45 meters consists of a room combined with a kitchen! The apartment is complemented by a large balcony with a gorgeous view of the mountains and a little sea, the balcony is equipped with summer furniture. Cafes and restaurants are 100 meters from the complex, and Karon Beach is a 10-15-minute walk away. Suitable for family holidays and for young people."
      />

      <RoomGallery
        photos={bathroomPhotos}
        onShowAll={(index) => handleOpenGallery(index, bathroomPhotos)}
      />
      <PlaceFeatures features={booking.features} />
    </div>
  );
};

export default LeftContent;
