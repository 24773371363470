import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotificationProvider from "./context/notificationContext";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";
import BookingDetailsPage from "./pages/bookingDetailsPage/bookingDetailsPage";
import "./App.css";
import SearchResult from "./pages/searchResult/searchResult";
import MainPage from "./pages/mainPage/mainPage";
import { AuthProvider } from "./context/authContext";
import ProfilePage from "./pages/profilePage/profilePage";
import FavoritesPage from "./pages/favoritesPage/favoritesPage";
import AppInitializer from "./components/appInitializer/appInitializer";
import BookingObjectPage from "./pages/bookingObjectPage/bookingObjectPage";
import ArticlePage from "./pages/articlePage/articlePage";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/booking/:id",
      element: <BookingDetailsPage />,
    },
    {
      path: "/post/:id",
      element: <ArticlePage />,
    },
    { path: "/booking-rent/:id", element: <BookingObjectPage /> },
    {
      path: "/search",
      element: <SearchResult />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      path: "/favorites",
      element: <FavoritesPage />,
    },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ],
  {
    future: {
      v7_startTransition: true,
    },
  }
);

const App: React.FC = () => {
  return (
    <div className="app-container">
      <AuthProvider>
        <NotificationProvider>
          <AppInitializer />
          <RouterProvider router={router}></RouterProvider>
        </NotificationProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
