import { createSelector } from 'reselect';
import { QueryState } from '../querySlice';
import { parseDate } from '../../utils/helpers';

export const selectCheckIn = createSelector(
  [(state: { query: QueryState }) => state.query.checkIn],
  (checkIn) => parseDate(checkIn) || undefined
);

export const selectCheckOut = createSelector(
  [(state: { query: QueryState }) => state.query.checkOut],
  (checkOut) => parseDate(checkOut) || undefined
);
