import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Booking } from "../../types/booking";
import { fetchBooking } from "../../api/api";
import { enUS, ru } from "date-fns/locale";
import { buildSearchQueryString } from "../../utils/helpers";
import CardList from "../../components/cardList/cardList";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import Header from "../../components/header/header";
import styles from "./favoritesPage.module.scss";
import iconButtonGoBack from "../../assets/images/chevron-left.png";
import iconEdit from "../../assets/images/cog.png";
import Footer from "../../components/footer/footer";
import checkIcon from "../../assets/images/check_black.png";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

interface FavoriteBooking extends Booking {
  addedAt: string;
}

interface IFavoritesBookingLocalStorage {
  addedAt: string;
  id: string;
}

// Группировка по дате
const groupByDate = (bookings: FavoriteBooking[]) => {
  const grouped: { [key: string]: FavoriteBooking[] } = {};

  bookings.forEach((item) => {
    const date = format(new Date(item.addedAt), "yyyy-MM-dd");
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(item);
  });

  return grouped;
};

const FavoritesPage = () => {
  const state = useSelector((state: { query: QueryState }) => state.query);
  const [favoriteBookings, setFavoriteBookings] = useState<FavoriteBooking[]>(
    []
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const loadFavorites = async () => {
      const favoriteItems = JSON.parse(
        localStorage.getItem("favoriteBookings") || "[]"
      );
      const sortedFavorites = favoriteItems.sort((a: any, b: any) => {
        return new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime();
      });

      const bookings = await Promise.all(
        sortedFavorites.map(async (item: IFavoritesBookingLocalStorage) => {
          const booking = await fetchBooking(item.id);
          const idFromApi = booking.data["@id"];
          const extractedId = idFromApi.split("/").pop();
          return { ...booking.data, addedAt: item.addedAt, id: extractedId };
        })
      );

      setFavoriteBookings(bookings);
      setLoading(false);
    };

    loadFavorites();
  }, []);

  // Форматируем дату для вывода (например, "Сегодня", "Вчера" и т.д.)
  const formatDateForDisplay = (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Форматируем даты с использованием date-fns
    if (date.toDateString() === today.toDateString()) {
      return t("FavoritesPage.today");
    } else if (date.toDateString() === yesterday.toDateString()) {
      return t("FavoritesPage.yesterday");
    } else {
      const locale = i18n.language === "ru" ? ru : enUS;
      return format(date, "eeee, dd MMMM", { locale });
    }
  };

  const groupedBookings = groupByDate(favoriteBookings);

  const { queryParams } = useQueryParams();
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );

  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [shouldSearch, setShouldSearch] = useState(false);

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  const deleteFromFavorites = (id: string) => {
    // Удаляем из localStorage
    const favoriteBookings = JSON.parse(
      localStorage.getItem("favoriteBookings") || "[]"
    ).filter((item: { id: string }) => item.id !== id);

    localStorage.setItem("favoriteBookings", JSON.stringify(favoriteBookings));

    // Удаляем из state
    setFavoriteBookings((prevBookings) =>
      prevBookings.filter((booking) => booking.id !== id)
    );
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const dispatch = useDispatch();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  return (
    <div className={styles.page}>
      <div>
        <Header
          location={location}
          onLocationChange={handleLocationChange}
          availableDates={[]}
          setShouldSearch={setShouldSearch}
          handleSearch={handleSearch}
          locationSuggestions={locationSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          allowAllDates={true}
        />
        <div className={styles.top}>
          <button className={styles.buttonGoBack} onClick={() => navigate(-1)}>
            <img
              src={iconButtonGoBack}
              alt="icon button go back"
              className={styles.iconButtonGoBack}
            />
            <p className={styles.textInButtonGoBack}>
              {t("FavoritesPage.goBack")}
            </p>
          </button>
        </div>
        <div className={styles.blockTitle}>
          <h1 className={styles.textTitle}>{t("FavoritesPage.title")}</h1>
          {favoriteBookings.length > 0 && (
            <div
              className={styles.editBlock}
              onClick={() => setIsEdit(!isEdit)}
            >
              <img
                src={isEdit ? checkIcon : iconEdit}
                alt="icon"
                className={styles.iconEdit}
              />
              <p className={styles.editText}>
                {isEdit ? t("FavoritesPage.done") : t("FavoritesPage.edit")}
              </p>
            </div>
          )}
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className={styles.blockContent}>
            {Object.keys(groupedBookings).map((date, index) => {
              const dateLabel = formatDateForDisplay(new Date(date));

              return (
                <div key={`group-${date}`}>
                  <h3 key={`date-${index}`} className={styles.subtitleDate}>
                    {dateLabel}
                  </h3>
                  <div className={styles.blockCards}>
                    <CardList
                      key={`date-${index}`}
                      bookings={groupedBookings[date]}
                      isEdit={isEdit}
                      onDelete={deleteFromFavorites}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FavoritesPage;
