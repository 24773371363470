import { FC, SetStateAction } from "react";
import styles from "./dropDownPaymentMethods.module.scss";
import iconCard from "../../assets/images/credit-card.png";
import checkIcon from "../../assets/images/check-gray.png";
import { useTranslation } from "react-i18next";
import { IPaymentMethod } from "../../types/paymentMethod";

interface IDropDownPaymentMethods {
    addedPaymentMethods: IPaymentMethod[];
    selectedCard: { id: string; last4: string } | null;
    setSelectedPayment: (value: SetStateAction<IPaymentMethod>) => void;
    setSelectedCard: (value: SetStateAction<{ id: string; last4: string } | null>) => void;
    setIsModalOpen: (value: SetStateAction<string | null>) => void;
    selectedPayment: IPaymentMethod;
    availableMethodsToAdd: IPaymentMethod[];
    addPaymentMethod: (methodType: string) => void;
}

const DropDownPaymentMethods: FC<IDropDownPaymentMethods> = ({
  addedPaymentMethods,
  selectedCard,
  setSelectedPayment,
  setSelectedCard,
  setIsModalOpen,
  selectedPayment,
  availableMethodsToAdd,
  addPaymentMethod,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentList}>
      {/* 🔹 Добавленные методы (Visa, Mastercard, American Express с привязанными картами) */}
      {addedPaymentMethods.map((method) => (
        <div key={method.type} className={styles.paymentGroup}>
          {/* Для методов с картами показываем сами карты */}
          {method.cards && method.cards.length > 0 ? (
            method.cards.map((card) => (
              <div
                key={card.id}
                className={`${styles.cardOption} ${
                  selectedCard?.id === card.id ? styles.selected : ""
                }`}
                onClick={() => {
                  setSelectedPayment(method);
                  setSelectedCard(card);
                  setIsModalOpen(null);
                }}
              >
                <div className={styles.paymentIconBlock}>
                  <img
                    src={method.icon}
                    alt={method.name}
                    className={styles[`paymentIcon${method.type}`]}
                  />
                </div>
                <div className={styles.blockWithNumberCard}>
                  <p className={styles.methodName}>{method.name}</p>
                  <p className={styles.textNumberCard}>•••• {card.last4}</p>
                </div>
                {selectedCard?.id === card.id ? (
                  <img
                    src={checkIcon}
                    alt="check icon"
                    className={styles.checkIcon}
                  />
                ) : (
                  ""
                )}
              </div>
            ))
          ) : (
            // Если у метода нет карт, показываем его просто как способ оплаты (Apple Pay, Google Pay и т.д.)
            <div
              className={`${styles.paymentOption} ${
                selectedPayment.type === method.type ? styles.selected : ""
              }`}
              onClick={() => {
                setSelectedPayment(method);
                setSelectedCard(null);
                setIsModalOpen(null);
              }}
            >
              <div className={styles.paymentIconBlock}>
                <img
                  src={method.icon}
                  alt={method.name}
                  className={styles[`paymentIcon${method.type}`]}
                />
              </div>
              <p className={styles.methodName}>{method.name}</p>
              {selectedPayment.type === method.type ? (
                <img
                  src={checkIcon}
                  alt="check icon"
                  className={styles.checkIcon}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ))}

      {/* 🔹 Блок "Добавить способ оплаты" */}
      <div className={styles.addPaymentMethod}>
        <p className={styles.textAddPayment}>
          {" "}
          {t("dropDownPaymentMetgods.addPaymentmethod")}
        </p>
        <div className={styles.addOption}>
          <div className={styles.paymentIconBlock}>
            <img
              src={iconCard}
              alt="card icon"
              className={styles.paymentIconDebet}
            />
          </div>
          <p className={styles.methodName}>
            {t("dropDownPaymentMetgods.credirOrDebetCard")}
          </p>
        </div>
        {availableMethodsToAdd.map((method) => (
          <div
            key={method.type}
            className={styles.addOption}
            onClick={() => {
              addPaymentMethod(method.type);
              // TODO: Реализовать логику добавления способа оплаты
              console.log(`Добавить метод: ${method.name}`);
            }}
          >
            <div className={styles.paymentIconBlock}>
              <img
                src={method.icon}
                alt={method.name}
                className={styles[`paymentIcon${method.type}`]}
              />
            </div>
            <p className={styles.methodName}>{method.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DropDownPaymentMethods;
