import React from "react";
import styles from "./dropdownMenu.module.scss";
import { useTranslation } from "react-i18next";
import emailIcon from "../../assets/images/mail.png";
import locationMarkerIcon from "../../assets/images/location-marker-blue.png";
import heartIcon from "../../assets/images/heart.png";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";

interface DropdownMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const getUrlWithParams = usePreserveQueryParams();

  if (!isOpen) return null;

  return (
    <div className={styles.dropdown} onClick={onClose}>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <img src={emailIcon} alt="email icon" className={styles.icon} />
          {t("dropdownMenu.messages")}
        </li>
        <li className={styles.menuItem}>
          <img
            src={locationMarkerIcon}
            alt="location marker icon"
            className={styles.icon}
          />
          {t("dropdownMenu.trips")}
        </li>
        <li
          className={styles.menuItem}
          onClick={() => navigate(getUrlWithParams("/favorites"))}
        >
          <img src={heartIcon} alt="heart icon" className={styles.icon} />
          {t("dropdownMenu.favorites")}
        </li>
        <li className={`${styles.menuItem} ${styles.brTop} ${styles["pl-20"]}`}>
          {t("dropdownMenu.account")}
        </li>
        <li className={`${styles.menuItem} ${styles["pl-20"]}`}>
          {t("dropdownMenu.helpCenter")}
        </li>
        <li
          className={`${styles.menuItem} ${styles.brTop} ${styles["pl-20"]}`}
          onClick={() => {
            logout();
            onClose();
          }}
        >
          {t("dropdownMenu.logout")}
        </li>
      </ul>
    </div>
  );
};

export default DropdownMenu;
