import { FC, useEffect, useState } from "react";
import Header from "../../components/header/header";
import ArticleCard from "../../components/articleCard/articleCard";
import Footer from "../../components/footer/footer";
import { usePreserveQueryParams } from "../../hooks/usePreserveQueryParams";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  QueryState,
  updateCoordinates,
  updateRadius,
} from "../../store/querySlice";
import { buildSearchQueryString } from "../../utils/helpers";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ISearchSuggestionItem } from "../../types/searchSuggestionItem";
import { useLocationSuggestions } from "../../hooks/useLocationSuggestions";

const ArticlePage: FC = () => {
  const state = useSelector((state: { query: QueryState }) => state.query);
  const { queryParams } = useQueryParams();
  const [location, setLocation] = useState(
    queryParams["nameLocation"] ? queryParams["nameLocation"] : ""
  );
  const handleLocationChange = (value: string) => {
    setShouldFetchSuggestions(true);
    setLocation(value);
  };
  const [shouldSearch, setShouldSearch] = useState(false);
  const getUrlWithParams = usePreserveQueryParams();

  const navigate = useNavigate();

  const handleSearch = () => {
    const queryString = buildSearchQueryString(state, location);
    navigate(`/search?${queryString}`);
  };

  useEffect(() => {
    if (shouldSearch) {
      handleSearch();
      setShouldSearch(false);
    }
  }, [shouldSearch]);

  const dispatch = useDispatch();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const { locationSuggestions, clearSuggestions } = useLocationSuggestions({
    location,
    enabled: shouldFetchSuggestions,
  });

  const handleSelectSuggestion = (suggestion: ISearchSuggestionItem) => {
    setShouldFetchSuggestions(false);
    setLocation(suggestion.name);
    clearSuggestions();
    // TODO возможно логику извлечения радиусов придется поменять - зависит от бэка
    const numericPart = suggestion.radius.replace(/\D+/g, "");
    const numberRadius = Number(numericPart);
    if (numberRadius && numberRadius >= 5 && numberRadius <= 100) {
      dispatch(updateRadius(numberRadius));
    }
    dispatch(
      updateCoordinates({
        lat: suggestion.latLon.lat,
        lon: suggestion.latLon.lon,
      })
    );
  };

  return (
    <div>
      <Header
        location={location}
        onLocationChange={handleLocationChange}
        availableDates={[]}
        setShouldSearch={setShouldSearch}
        handleSearch={handleSearch}
        locationSuggestions={locationSuggestions}
        onSelectSuggestion={handleSelectSuggestion}
        allowAllDates={true}
      />
      <ArticleCard />
      <Footer />
    </div>
  );
};
export default ArticlePage;
